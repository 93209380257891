import { ActionType } from './../actions/Helpers';
import { User } from '../state/user.state';

export const AuthenticationReducer = (state: User | null = null, action): User => {
  switch (action.type) {
    case ActionType.LOGIN_REQUEST: {
      const authConfigs = {
        cognitoUserId: action.payload.tokens.idToken.payload.sub,
        accessToken: action.payload.tokens.accessToken.toString(),
        isSocial: !!action.payload.tokens.idToken.payload.identities,
      };
      return {
        ...state,
        authConfigs,
        email: action.payload.username,
      };
    }
    case ActionType.FETCH_USER_DETAILS:
      return {
        ...state,
        firstName: action.payload.first_name || null,
        lastName: action.payload.last_name || null,
        email: action.payload.email || null,
        is_profile_verified:
          action.payload.is_profile_verified !== undefined ? action.payload.is_profile_verified : true,
        has_saved_terms: action.payload.has_saved_terms !== undefined ? action.payload.has_saved_terms : true,
        terms_and_condition_required:
          action.payload.terms_and_condition_required !== undefined
            ? action.payload.terms_and_condition_required
            : true,
        status: action.payload.status || null,
        payment_type: action.payload.payment_type || null,
        copyright_text_driver: action.payload.copyright_text_driver || null,
        version_number_driver: action.payload.version_number_driver || null,
        driver_id: action.payload.id || null,
        language_locale: action.payload.language_locale || null,
      };
    case ActionType.USER_DATA: {
      const configData = {
        cognitoUserId: action.payload.authSession.tokens.idToken.payload.sub,
        accessToken: action.payload.authSession.tokens.accessToken.toString(),
        isSocial: !!action.payload.authSession.tokens.idToken.payload.identities,
      };
      return {
        ...state,
        authConfigs: configData,
        firstName: action.payload.first_name || null,
        lastName: action.payload.last_name || null,
        email: action.payload.email || null,
        is_profile_verified:
          action.payload.is_profile_verified !== undefined ? action.payload.is_profile_verified : true,
        has_saved_terms: action.payload.has_saved_terms !== undefined ? action.payload.has_saved_terms : true,
        terms_and_condition_required:
          action.payload.terms_and_condition_required !== undefined
            ? action.payload.terms_and_condition_required
            : true,
        status: action.payload.status || null,
        payment_type: action.payload.payment_type || null,
        copyright_text_driver: action.payload.copyright_text_driver || null,
        version_number_driver: action.payload.version_number_driver || null,
        driver_id: action.payload.id || null,
        language_locale: action.payload.language_locale || null,
      };
    }
    case ActionType.LOGOUT_REQUEST:
      return null;
    case ActionType.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};
