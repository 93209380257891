import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { cognitoSignup, checkEmailAlreadyExists } from './../../services/login/loginApi-service';
import { Common } from '../../utils/constants';
import { companyNameReplace } from '../../utils';
import { store } from '../../store';
import { ISignUpProps } from '../../types/propTypes';
import { ISignUpFormField, ISignUpFormValues } from '../../models/login';
import { IModelState } from '../../types/stateTypes';

export const SignUpFormHook = ({ setEmail, setPassword, setEmailPattern, onChangeStage }: ISignUpProps) => {
  const { t } = useTranslation();
  const parameters = new URLSearchParams(window.location.search);
  const initialState: ISignUpFormField = {
    email: parameters?.get('email') && parameters?.get('email') !== 'undefined' ? `${parameters?.get('email')}` : '',
    password: '',
    firstName:
      parameters?.get('firstName') && parameters?.get('firstName') !== 'undefined'
        ? `${parameters?.get('firstName')}`
        : '',
    lastName:
      parameters?.get('lastName') && parameters?.get('lastName') !== 'undefined'
        ? `${parameters?.get('lastName')}`
        : '',
    recaptchaCode: '',
  };

  const [modal, setModal] = useState<IModelState>({
    showModel: false,
    header: '',
    body: '',
    callBack: null,
  });

  const handleModal = (body: string, header: string, callBack: () => void) => {
    setModal({
      body: body,
      header: header,
      callBack: callBack,
      showModel: !modal.showModel,
    });
  };

  const closeModal = () => {
    setModal({
      body: '',
      header: '',
      callBack: null,
      showModel: false,
    });
  };

  const handleSubmitAfterEmailCheck = async (value: ISignUpFormField) => {
    const userLang = store.getState()?.UserData?.language
      ? store.getState()?.UserData?.language
      : store.getState()?.LanguageInfo?.defaultLanguage;
    return cognitoSignup({
      ...value,
      language_preference_id: userLang?.id,
      language_preference_name: userLang?.code,
    })
      .then(async (res) => {
        setEmail(value.email);
        setPassword(value.password);
        setEmailPattern(res && res.codeDeliveryDetails && res.codeDeliveryDetails.Destination);
        if (!res.userConfirmed) {
          onChangeStage(Common.AuthStage.SIGNUP_OTP);
        }
      })
      .catch((err) => {
        if (err && err.name === Common.Authentication.UsernameExistsException) {
          toast.error(t('signup.toast.alreadyExist'));
        } else if (err && err.name === Common.Authentication.referaalCodeMisMatch) {
          toast.error(t('signup.toast.referralCodeInvalid'));
        } else {
          toast.error(t('signup.toast.error'));
        }
        closeModal();
      })
      .finally();
  };

  const handleSubmit = async (value: ISignUpFormValues) => {
    const input = {
      email: value.email,
      federated_account_type: null,
    };
    await checkEmailAlreadyExists(input)
      .then(async (res) => {
        const existingEmails = res.Data;
        if (!_.isEmpty(existingEmails)) {
          let modalMessage = '';
          modalMessage = companyNameReplace(t('signup.label.modalMessage'));
          const emailString = `<span class='themeTextColor'>${value.email.toString()}</span>`;
          modalMessage = modalMessage.replace('##EMAIL##', emailString);
          let accounts = '';
          const existingEmailsLength = existingEmails.length;
          existingEmails.forEach((account, index: number) => {
            if (account.federated_account_type !== null) {
              if (existingEmailsLength === 1 || existingEmailsLength === index + 1) {
                accounts = `${accounts}<span class='themeTextColor'>${account.federated_account_type.toString()}</span>`;
              } else {
                accounts = `${accounts}<span class='themeTextColor'>${account.federated_account_type.toString()}</span>, `;
              }
            } else {
              if (existingEmailsLength === 1 || existingEmailsLength === index + 1) {
                accounts = `${accounts}<span class='themeTextColor'>${companyNameReplace(t('signup.label.instavoltUserAccount'))}</span>`;
              } else {
                accounts = `${accounts}<span class='themeTextColor'>${companyNameReplace(t('signup.label.instavoltUserAccount'))}</span>, `;
              }
            }
          });
          modalMessage = modalMessage.replace('##FEDERATEDACCOUNT##', accounts);
          handleModal(modalMessage, companyNameReplace(t('signup.label.instavolt')), () =>
            handleSubmitAfterEmailCheck(value),
          );
        } else {
          handleSubmitAfterEmailCheck(value);
        }
      })
      .catch((m) => {
        if (
          m.response &&
          m.response.status &&
          m.response.status === 409 &&
          m.response.data &&
          typeof m.response.data === 'object'
        ) {
          if (Object.keys(m.response.data).length === 0) {
            let modalMessage = companyNameReplace(t('signup.label.alreadyRegisteredWithNormalAcc'));
            const emailString = `<span class='themeTextColor'>${value.email.toString()}</span>`;
            modalMessage = modalMessage.replace('##EMAIL##', emailString);
            handleModal(modalMessage, companyNameReplace(t('signup.label.instavolt')), () =>
              handleSubmitAfterEmailCheck(value),
            );
          } else {
            const existingEmails = m.response.data.data ? m.response.data.data : null;
            if (!_.isEmpty(existingEmails)) {
              let modalMessage = '';
              modalMessage = companyNameReplace(t('signup.label.modalMessage'));
              const emailString = `<span class='themeTextColor'>${value.email.toString()}</span>`;
              modalMessage = modalMessage.replace('##EMAIL##', emailString);
              let accounts = '';
              const existingEmailsLength = existingEmails.length;
              existingEmails.forEach((account, index: number) => {
                if (account.federated_account_type !== null) {
                  if (existingEmailsLength === 1 || existingEmailsLength === index + 1) {
                    accounts = `${accounts}<span class='themeTextColor'>${account.federated_account_type.toString()}</span>`;
                  } else {
                    accounts = `${accounts}<span class='themeTextColor'>${account.federated_account_type.toString()}</span>, `;
                  }
                } else {
                  if (existingEmailsLength === 1 || existingEmailsLength === index + 1) {
                    accounts = `${accounts}<span class='themeTextColor'>${companyNameReplace(t('signup.label.instavoltUserAccount'))}</span>`;
                  } else {
                    accounts = `${accounts}<span class='themeTextColor'>${companyNameReplace(t('signup.label.instavoltUserAccount'))}</span>, `;
                  }
                }
              });
              modalMessage = modalMessage.replace('##FEDERATEDACCOUNT##', accounts);
              handleModal(modalMessage, companyNameReplace(t('signup.label.instavolt')), () =>
                handleSubmitAfterEmailCheck(value),
              );
            } else {
              toast.error(t('login.toast.error'));
            }
          }
        } else if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
      })
      .finally();
  };

  return {
    initialState,
    modal,
    handleSubmitAfterEmailCheck,
    handleModal,
    closeModal,
    handleSubmit,
  };
};
