import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

const Spinner = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    <div id="loading" className={promiseInProgress ? 'loading' : 'loading spinner-disable'}>
      <div className="battery" id="battery">
        <div className="liquid" id="liquid"></div>
      </div>
    </div>
  );
};

export default Spinner;
