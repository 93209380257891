export default {
  route: {
    dashboard: 'Dashboard',
    article: 'Articles',
    stationTitle: 'Station Details : ##COMPANY_BRAND_NAME## - Rapid EV Charging UK',
    or: 'OR',
  },
  month: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'May',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',
  },
  error: {
    accessDenied: 'Access Denied',
    somethingWrong: 'Something Went Wrong!',
    notFound: 'Page Not Found',
  },
  navbar: {
    title: '##COMPANY_BRAND_NAME##',
    logOut: 'Log Out',
    myProfile: 'My Profile',
    termsConditions: 'Terms & Conditions',
    menu: {
      dashboard: 'Dashboard',
      paymentConfiguration: 'Payment Configuration',
      chargingPaymentHistory: 'Charging & Payment History',
      rewards: 'Rewards',
      rfidCards: 'RFID Cards',
    },
  },
  login: {
    title: 'Login - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
    header: 'Welcome to',
    form: {
      email: 'Email ID',
      password: 'Password',
    },
    placeholder: {
      region: 'Select Region',
      language: 'Select Language',
    },
    button: {
      submit: 'Login',
      forgotPassword: 'Forgot Password?',
      signup: 'Sign up',
      signin: 'Sign in',
    },
    label: {
      logo: '##COMPANY_BRAND_NAME##',
      thirdPartyLogin: 'Or login with',
      rememberMe: 'Remember Me',
      newAccount: "Don't have an account?",
      facebook: 'Sign in with Facebook',
      google: 'Sign in with Google',
      apple: 'Sign in with Apple',
      copyright: 'Copyright ##COMPANY_BRAND_NAME## Ltd',
    },
    toast: {
      success: 'Driver login successfully.',
      inValid: 'Please enter correct email and password.',
      error: 'There was an error processing your request, Please try again later.',
      cancelled: 'Your account cancellation is in process so you will not be able to perform any action(s).',
      maximumAttempt: 'You have reached the maximum number of attempts. Please try again later.',
      reminderMessage:
        'Please add a payment card in your credit account or add money to your prepayment account to activate the payment associated with this promotion code.',
    },
    errors: {
      inValidEmail: 'Email address is invalid.',
    },
  },
  forgot: {
    title: 'Forgot Password - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
    resetTitle: 'Reset Your Password - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
    header: 'Forgot your password?',
    subHeader: 'Enter your email below and we will send you a message to reset your password',
    resetHeader: 'Reset your password.',
    resetSubHeader: 'Please enter the code we emailed you and reset your password.',
    supportNumber: 'Our support number',
    button: {
      back: 'Back',
      submit: 'Reset My Password',
      save: 'Save New Password',
    },
    form: {
      email: 'Email',
      code: 'Code',
      password: 'Password',
      confirmPassword: 'Re-confirm Password',
      message:
        'If you have a valid account you should receive a code via email. Please note that if you signed up for an ##COMPANY_BRAND_NAME## account with a federate account e.g. Apple, Google or Facebook, you will not be able to reset your password as this is managed by the third party service.  If you need further assistance please contact us by',
    },
    toast: {
      success: 'Your password has been changed successfully.',
      error: 'There was an error processing your request, Please try again later.',
      inValidEmailAddress: 'Email address is invalid.',
      invalidCode: 'Oops! You have an invalid code.',
    },
    errors: {
      passwordFormat:
        'Password must be 10+ characters, with at least one uppercase letter, lowercase letter, special character and a number.',
      confirmPasswordMatch: 'the confirmation password must match with a new password you have entered.',
      verificationCodeFormat: 'Please enter 6 digit verification code.',
    },
  },
  dashboard: {
    title: 'DashBoard - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
    header: 'DashBoard',
    premiumSubscription:
      '##COMPANY_BRAND_NAME## provides premium service. Select subscribe to enjoy premium service features free for 3 months.',
  },
  permission: {
    createArticle: 'Add Article',
    editArticle: 'Edit Article',
    save: 'Save',
    confirm: 'Are You Sure?',
    cancel: 'Cancel',
    update: 'Update',
  },
  profile: {
    title: 'Profile - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
    header: 'Profile',
    addresses: 'Addresses',
    personalDetails: {
      header: 'Personal Details',
      label: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email ID',
        address: 'Address',
        billingAddress: 'Billing Address',
        postcode: 'Postcode',
        samePersonalAddress: 'Same as personal address',
      },
      placeholder: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email ID',
        address: 'Enter your address',
        billingAddress: 'Billing Address',
        postcode: 'Postcode',
        searchAddress: 'Start typing your address to search',
      },
      button: {
        save: 'Save',
        cancel: 'Cancel',
        yes: 'Yes',
        no: 'No',
      },
      errors: {
        email: {
          email: 'Email address is invalid.',
        },
        first_name: {
          matches: 'First name is invalid.',
        },
        last_name: {
          matches: 'Last name is invalid.',
        },
        address: {
          address: 'Minimum ##MIN_LENGTH## character required',
          invalid: 'Address is invalid',
          minLine: "The address you entered doesn't meet the minimum address lines to be valid.",
        },
        billing_Address: {
          billing_Address: 'Minimum ##MIN_LENGTH## character required',
          invalid: 'Billing Address is invalid',
          minLine: "The address you entered doesn't meet the minimum address lines to be valid.",
        },
      },
      model: {
        header: 'Find Address',
        address: 'Type Address',
        suggested_address: 'Suggested Addresses',
        no_data_found: 'No addresses found',
        no_address_found: 'No address found.',
        button: {
          ok: 'OK',
          cancel: 'Cancel',
          close: 'Close',
        },
        toast: {
          invalidAddress: 'An invalid Postcode or Address has been entered.',
        },
      },
    },
    addressDetails: {
      header: 'Address',
      label: {
        address1: 'Address',
        address2: 'Address Line 2',
        city: 'City',
        county: 'County',
        paymentPostcode: 'Postal Code',
      },
      placeholder: {
        address1: 'Address',
        address2: 'Address Line 2',
        city: 'City',
        county: 'County',
      },
    },
    billingAddressDetails: {
      header: 'Billing Address',
      label: {
        billingaddress1: 'Address',
        billingaddress2: 'Address Line 2',
        billingcity: 'City',
        billingcounty: 'County',
        samePersonalAddress: 'Same as personal address',
      },
      placeholder: {
        billingaddress1: 'Address',
        billingaddress2: 'Address Line 2',
        billingcity: 'City',
        billingcounty: 'County',
      },
    },
    marketingPreferenceDetails: {
      header: 'Marketing Preferences',
      label: {
        receivePreference: 'Receive marketing communication from ##COMPANY_BRAND_NAME##',
        yes: 'Yes',
        no: 'No',
      },
      message: 'Would you like to receive marketing communication from ##COMPANY_BRAND_NAME##?',
    },
    langPreference: 'Language Preference',
    changePassword: {
      header: 'Password',
      toast: {
        success: 'Your password has been changed successfully.',
        error: 'Oops! Your current password is incorrect.',
        commonError: 'There was an error processing your request, Please try again later.',
      },
      button: {
        update: 'Update',
      },
      label: {
        current_password: 'Current Password',
        new_password: 'New Password',
        confirm_password: 'Re-Confirm Password',
      },
      placeholder: {
        current_password: 'Current Password',
        new_password: 'New Password',
        confirm_password: 'Re-Confirm Password',
      },
      errors: {
        passwordFormat:
          'Password must be 10+ characters, with at least one uppercase letter, lowercase letter, special character and number.',
        confirmPasswordMatch: 'Confirm password must match with a new password you have entered.',
      },
    },
    cancelAccount: {
      header: 'Delete Account',
      note1:
        'You can delete your account any time. ##COMPANY_BRAND_NAME## will remove all your all data from our system.',
      note2: 'To delete your account.',
      clickHere: 'Click here',
      toast: {
        success:
          'Sorry to see you go. We have started the process of deleting your account and we will notify you via email once the process has completed.',
        failed: 'There was an error processing your request, Please try again later.',
      },
      model: {
        title: 'Delete Account',
        body: 'Deleting your account will permanently remove all of your data from our system. Do you still want to continue ?',
        button: {
          yes: 'Yes',
          no: 'No',
        },
      },
    },
    limitExceedAddressError: 'Unable to find the address at this moment',
  },
  signup: {
    header: 'Create your account with',
    title: 'Signup - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
    form: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      password: 'Password',
      referralCode: 'Referral code',
      code: 'Code',
      terms: 'I agree to',
      condition: 'Terms & Conditions.',
    },
    button: {
      submit: 'Sign up',
      signin: 'Sign in',
      login: 'Login',
      back: 'Back',
      submitOtp: 'Submit',
      resend: 'Resend Code?',
    },
    label: {
      continueWith: 'Or continue with',
      accountNew: 'Already have an account?',
      otpConfirmationTitle1: 'We just sent you an email',
      otpConfirmationTitle2: 'To login please enter the code sent to',
      otpNonVerifiedEmailNote1: 'Your account is not verified yet. Please enter the code we sent to',
      otpNonVerifiedEmailNote2: 'to proceed further.',
      resendTitle: "Didn't receive email?",
      resend: 'Resend',
      email: 'Email',
      instavolt: '##COMPANY_BRAND_NAME##',
      modalMessage:
        '##EMAIL## has already registered an ##COMPANY_BRAND_NAME## account via ##FEDERATEDACCOUNT##. Do you wish to continue?',
      instavoltUserAccount: '##COMPANY_BRAND_NAME## User Account',
      alreadyRegisteredWithNormalAcc:
        '##EMAIL## has already registered an ##COMPANY_BRAND_NAME## account via ##COMPANY_BRAND_NAME## User Account. Do you wish to continue?',
    },
    toast: {
      success: 'Driver account created successfully.',
      alreadyExist: 'Email address which you have entered is already exists.',
      error: 'There was an error processing your request, Please try again later.',
      confirmationSuccess: 'Account verification successfully.',
      invalidCode: 'Oops! You have entered invalid code.',
      resendSentOTP: 'OTP code sent successfully.',
      resendOTPErr: 'OTP code not sent successfully. Please try again later.',
      referralCodeInvalid: 'Referral code is invalid.',
      resendSignupCode: 'Hey, We have just sent you another code, Please check your email.',
      resendLimitExceeded: 'You have reached maximum attempt. Please try again after some time.',
    },
    errors: {
      recaptchaCode: 'Verify recaptcha code.',
      passwordFormat:
        'Password must be 10+ characters, with at least one uppercase letter, lowercase letter, special character and number.',
      securityCode: 'Security code must be at least 6 digit long',
      firstName: 'First name is invalid.',
      lastName: 'Last name is invalid.',
      termsAndCondition: 'You must read and agree to the Terms & Conditions in order to proceed',
    },
  },
  Payment: {
    Title: 'Payment Configuration - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
    ChargeUnit: '(kWh)',
    description: 'Payment to ##COMPANY_BRAND_NAME##',
    PaymentType: {
      Title: 'Payment Configuration',
      SubTitle: 'Please select payment option',
      Message: {
        selectPaymentOption: 'Please select payment option.',
      },
      Inputs: {
        creditAccount: {
          label: 'Credit Account',
          label1: 'Pay as you charge',
        },
        paymentAccount: {
          label: 'Prepayment Account',
          label1: 'Pay up front and use account credit to charge',
        },
      },
      Buttons: {
        Next: {
          text: 'Next',
        },
      },
    },
    CreditPayment: {
      Title: 'Payment Configuration',
      SubTitle: 'Credit Account',
      note: 'Please note when adding a payment card your account will be debited by ##CURRENCY## which will be credited to your ##COMPANY_BRAND_NAME## wallet balance.',
      donateMessage: {
        first: 'Yes, donate',
        second: 'To round up payment to',
      },
      donationNote:
        "Donation 'Round Up' to the nearest 50 pence or 'Top Up' with a fixed amount for each payment transaction.",
      Inputs: {
        CardTitle: {
          label: 'Name On Card',
          placeHolder: 'Name on card',
          required: 'Card title is required',
        },
        CardNumber: {
          label: 'Card Number',
          placeHolder: '1234  1234  1234  1234',
          invalid: 'Invalid card number',
          required: 'Card number is required',
          maxlimit: 'Card Number must be at most 20 digits',
        },
        Expiry: {
          label: 'Expiry',
          required: 'Expiry month is required',
        },
        Year: {
          label: 'Year',
          required: 'Expiry year is required',
        },
        CSC: {
          label: 'CSC',
          label1: '(Card Security Code)',
          required: 'Card Security Code is required',
          placeHolder: 'CSC',
          maxlimit: 'Please enter minimum 3 digit code.',
        },
        Donation: {
          label: 'Yes, I want to donate!',
        },
        giftaid: {
          label: 'Enable Gift Aid Donation?',
        },
        RoundUp: {
          label: 'Round Up',
        },
        TopUp: {
          label: 'Top Up',
        },
      },
      Buttons: {
        Save: {
          text: 'Save',
        },
        Back: {
          text: 'Switch To Prepayment Account',
        },
        editBack: {
          text: 'Back',
        },
      },
      Card: {
        expire: 'Expires',
        add: 'Add Card',
        charity: 'Charity Donation',
        edit: 'Edit Donation Settings',
        editCard: 'Edit Card',
        delete: 'Delete',
        replace: 'Replace',
        balance: 'Current Balance:',
        Outbalance: 'Outstanding Balance:',
        mmYY: 'MM/YY',
        mm: 'MM',
        yyyy: 'YYYY',
        Pay: {
          text: 'Pay Now',
        },
        Withdraw: {
          text: 'Withdraw Funds',
        },
        toast: {
          delete: {
            success: 'Card deleted successfully.',
            error: 'There is an error in processing your request. Please try again later.',
          },
          save: {
            success: 'Card details save successfully.',
            error: 'There was an error in processing your request. Please try again later.',
          },
          paynow: {
            success: 'Payment Successful.',
            error: 'There was an error in processing your request. Please try again later.',
          },
          edit: {
            success: 'Card details upadated successfully.',
            error: 'There was an error in processing your request. Please try again later.',
          },
          replace: {
            success: 'Card details replaced successfully.',
            error: 'There was an error in processing your request. Please try again later.',
          },
          common: {
            error: 'There was an error in processing your request. Please try again later.',
          },
          amount: 'There is no outstanding balance to charge.',
          noSwitch: 'Please clear your outstanding balance before switching to a prepayment account',
        },
      },
      label: {
        accountFunds: 'Account Funds',
        rewards: 'Rewards',
        referralBonus: 'Referral Bonus',
        currentBalance: 'Current Balance',
        seeMore: 'See more',
        message: 'Message',
        charityDonation: 'Charity Donation',
        topupAmount: 'Yes, donate',
        note: 'Note:',
        charity: 'charity',
        card: 'card',
        donation: 'donation',
        giftaid: 'giftaid',
        noThanks: 'No, thank you',
        cancel: 'Cancel',
      },
      error: 'Your payment transaction is below the minimum transaction value ##AMOUNT',
    },
    PrePaymentDetail: {
      Title: 'Payment Configuration',
      SubTitle: 'Prepayment Account',
      Inputs: {
        Amount: {
          label: 'Amount',
          placeHolder: 'Amount',
          required: 'Amount is required',
          invalid: 'Amount must be greater than or equal to',
          maxLimit: '',
          number: 'Amount must be a number',
        },
        CardNumber: {
          label: 'Card Number',
          placeHolder: '1234  1234  1234  1234',
          required: 'Card number is required',
          invalid: 'Invalid card number',
        },
        Expiry: {
          label: 'Expiry',
          required: 'Expiry month is required',
        },
        Year: {
          label: 'Year',
          required: 'Expiry year is required',
        },
        CSC: {
          label: 'CSC',
          label1: '(Card Security Code)',
          required: 'Card Security Code is required',
          placeHolder: 'CSC',
          maxlimit: 'Please enter minimum 3 digit code.',
        },
        Donation: {
          label: 'Yes, I want to donate!',
        },
        RoundUp: {
          label: 'Round Up',
        },
        TopUp: {
          label: 'Top Up',
        },
      },
      Buttons: {
        Save: {
          text: 'Add Money',
        },
        Back: {
          text: 'Switch To Credit Account',
          text1: 'Back',
        },
        FailBack: {
          text: 'Back to Account',
        },
      },
      PrePayemnt: {
        text: {
          currentBalance: 'Current Balance',
          OutBalance: 'Outstanding Balance',
        },
        button: {
          add: 'Add Money',
          Withdraw: 'Withdraw Funds',
          withdrawAccountFunds: 'Withdraw Account Funds',
          viewYourRefundRequests: 'View Withdrawal Requests',
        },
      },
      toast: {
        canNotAddMoney:
          "You cannot add more funds as You've reached to a maximum limit for the funds one can keep at their ##COMPANY_BRAND_NAME## wallet.",
        common: {
          error: 'There is an error in processing your request. Please try again later.',
        },
        maxLimitForPrePayment: 'Hey! you can only load your wallet balance up to ##MAXAMOUNT.',
        maxLimitReached:
          'Hey! you can only load your wallet balance up to ##MAXAMOUNT. You can add maximum ##ALLOWEDAMOUNT now.',
      },
      label: {
        accountFunds: 'Account Funds',
        rewards: 'Rewards',
        referralBonus: 'Referral Bonus',
        currentBalance: 'Current Balance',
        seeMore: 'See more',
        billingAddress: 'Billing Address',
        charityDonation: 'Charity Donation',
        note: 'Note:',
      },
      donationNote: "Donation 'Top Up' with a fixed amount for each payment transaction.",
    },
    PrePaymentWithdraw: {
      Title: 'Payment Configuration',
      SubTitle: 'Prepayment Account',
      Inputs: {
        Amount: {
          label: 'Amount',
          placeHolder: 'Amount',
          required: 'Amount is required',
          invalid: 'Amount is more than current balance',
        },
        CardNumber: {
          label: 'Card Number',
          placeHolder: '1234  1234  1234  1234',
          required: 'Card number is required',
          invalid: 'Invalid card number',
          maxlimit: 'Card Number must be at most 20 digits',
        },
        Expiry: {
          label: 'Expiry',
          required: 'Expiry month is required',
        },
        Year: {
          label: 'Year',
          required: 'Expiry year is required',
        },
        CSC: {
          label: 'CSC',
          label1: '(Card Security Code)',
          required: 'Card Security Code is required',
          placeHolder: 'CSC',
          maxlimit: 'Please enter miminum 3 digit code.',
        },
      },
      Buttons: {
        Withdraw: {
          text: 'Withdraw Funds',
        },
        Back: {
          text: 'Switch To Credit Account',
          text1: 'Back',
        },
        FailBack: {
          text: 'Back to Account',
        },
      },
      PrePayemnt: {
        text: {
          currentBalance: 'Current Balance',
          OutBalance: 'Outstanding Balance',
        },
        button: {
          add: 'Add Money',
          Withdraw: 'Withdraw Funds',
        },
      },
    },
    PaymentSuccess: {
      label: 'Success!',
      Successlabel: 'Transaction Successful',
      transactionId: 'Transaction Id:',
      amount: 'Amount:',
      warningLabel: 'Warning',
      common: {
        error: 'There is an error in processing your request. Please try again later.',
      },
    },
    PaymentFail: {
      label: 'Transaction Failed',
      failureLabel: 'Your transaction has failed. Please try again.',
    },
    PaymentProcess: {
      label: 'Please wait while your transaction is being processed',
    },
    Model: {
      header: 'Switch Account',
      DeleteBoxHeader: 'Delete Card',
      WithdrawBoxHeader: 'Withdraw Funds',
      button: {
        yes: 'Yes',
        no: 'No',
        existingCard: 'Existing Card',
        newCard: 'New Card',
      },
      messagePrePayment: 'Are you sure you want to switch to prepayment account?',
      messageCreditPayment: 'Are you sure you want to switch to credit account?',
      msgPrePaymentSaveCard:
        'By switching to prepayment account, your card details will be deleted. Are you sure you want to switch to prepayment account?',
      DeleteCardMsg: 'Are you sure you want to delete card details?',
      WithdrawMoneyMsg: 'Please select card option to withdraw money.',
    },
    charityModal: {
      yesDonate: 'Yes, donate',
    },
    Withdraw: {
      CanNotWithdrawMoneyWhileSessionIsActive:
        'You cannot withdraw money at this moment. Please wait for your running charging session to gets complete',
    },
  },
  termsAndCondition: {
    title: 'Terms & Conditions',
    note: 'Page Under Construction.',
  },
  table: {
    data: {
      noData: 'No Data Found.',
    },
    pagination: {
      prev: 'Prev',
      nrev: 'Next',
      showing: 'Showing',
      pageSize: {
        ten: '10',
        fifty: '50',
        hundred: '100',
      },
    },
  },
  history: {
    headerTitle: 'Charging & Payment History - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
    title: 'Charging & Payment History',
    columns: {
      chargingStation: 'Charging Station',
      date: 'Date',
      address: 'Address',
      milesAdded: 'Miles Added',
      time: 'Time',
      cost: 'Cost',
      energy: 'Energy',
      action: 'Action',
      remark: 'Remarks',
      credit: 'Credit',
      debit: 'Debit',
      closing: 'Closing',
      transaction: 'Transaction ID',
      credit_debit: 'Credit/Debit',
    },
    form: {
      charge: 'Charging',
      payment: 'Payment',
      month: 'Month',
    },
    label: {
      exportAll: 'Generate Receipt',
    },
    button: {
      search: 'Search',
      reset: 'Reset',
    },
    message: {
      selectRecordToDownload: 'Please select any record to generate a receipt.',
      noChargingHistoryMessage: 'When you charge your EV your charging history will appear here.',
      noPaymentHistoryMessage: 'When you have completed a payment transaction it will show here.',
    },
    receipt: {
      title: 'RECEIPT',
      header: {
        date: 'Date',
        billing_addresss: 'Billing Address',
        note: 'Your ##COMPANY_BRAND_NAME## EV Charging Receipt',
        vat_number: 'VAT number',
      },
      content: {
        summary: 'A summary of your charging transactions is shown below',
        table: {
          header: {
            station_name: 'Station Name',
            start: 'Start',
            end: 'End',
            usage: 'Usage',
            unit: 'Unit',
            value: 'Value',
            transaction_id: 'Transaction ID',
            vatRate: 'VAT Rate',
            vat: 'VAT',
          },
        },
        total_before_vat: 'Total before VAT',
        total_vat: 'Total VAT',
        vat: 'VAT at',
        total_fee: 'Total Fee',
        thanks_message: 'Thank you for charging on the ##COMPANY_BRAND_NAME## EV Charging Network',
        vatReceiptNotGenerated:
          'VAT receipt has not been generated yet. Please try again 24 hours after the charging session ended. If still unsuccessful then contact our customer support.',
        vatReceiptNotAssociatedWithAcc:
          'VAT receipt has not been associated to your account. Please ensure you are using the correct credentials to view the VAT receipt.',
      },
      footer: {
        title: '##COMPANY_BRAND_NAME## Limited is a company registered in England and Wales, company number 10484882',
        address: '6 Cedarwood, Crockford Lane, Chineham Park, Basingstoke, Hampshire, RG24 8WD',
        telephone: 'T',
        email: 'E',
        note: 'Please quote date of transaction and transaction ID in all correspondence.',
      },
    },
  },
  loyaltyPoint: {
    header: 'Rewards - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
    title: 'Rewards',
    note: '*You can only redeem volts via our app.',
    availableVolts: 'Available volts:',
    noDataMessage: 'When you have completed a charging session, your volt transactions will be displayed here.',
    label: {
      chargingStation: 'Charging Station',
      date: 'Date',
      address: 'Address',
      credit: 'Credit',
      debit: 'Debit',
      closing: 'Closing',
      redeemed: 'Redeemed',
      charged: 'Charged',
      remark: 'Remarks',
      credit_debit: 'Credit/Debit',
      voltsRedeemed: 'Volts Redeemed',
    },
  },
  withdraw: {
    subTitle: 'Prepayment Account',
    label: {
      maxWithdrawalBalance: 'Maximum Withdrawal Balance',
      note: 'Note:',
      enterAmount: 'Enter an Amount',
      withdrawAmount: 'Withdraw Amount',
      expires: 'Expires',
      rewardHeading: 'Please enter a value you wish to withdraw from your account',
      selectCardToWithdrawAmount: 'Select card to withdraw the amount',
      remainingAmount: 'Remaining Amount',
      accountName: 'Account Name',
      sortCode: 'Sort Code',
      accountNumber: 'Account Number',
      bankDetail: 'Bank Details',
      withdrawAmountNote:
        'You will only be able to transfer the funds from your account into the payment card that was used to fund the account.',
      withdrawAmountNote1: 'Please note that the withdrawal will be processed within 14 days.',
      withdrawAmountNote2: 'Please note that the withdrawal will be processed within 3 to 5 working days.',
      accountNameNote:
        ' Please ensure the bank account name matches your ##COMPANY_BRAND_NAME## account name, Otherwise, the withdrawal will be rejected.',
    },
    placeholder: {
      amount: 'Amount',
      accountName: 'Account Name',
      sortCode: 'Sort Code',
      accountNumber: 'Account Number',
    },
    button: {
      back: 'Back',
      next: 'Next',
      raisedRequest: 'Raise withdrawal request',
      withdrawMoney: 'Withdraw Funds',
      proceed: 'Proceed',
    },
    errors: {
      amount: {
        typeError: 'Enter valid amount',
        moreThan: 'Amount must be greater than 0',
      },
      AmountIsGreaterThanwithdrawableAmount: 'Amount you entered is greater then withdrawable amount',
      selectCardToWithdrawMoney: 'Select any card to withdraw money.',
      canNotSelectCard:
        'The withdrawal value exceeds the maximum withdrawal fund available for selected payment card. Please choose an other card.',
    },
  },
  termsAndConditionListing: {
    title: 'Terms & Conditions - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
    noteDetails: '*Mandatory must be accepted in order to use/sign up an account.',
    label: {
      referralCode: 'Referral Code (if you have one)',
      promotioncode: 'Promotion Code (if you have one)',
      termsAndConditions: 'Terms & Conditions.',
      welcome: 'Welcome',
      apply: 'Apply',
      note: 'Note:',
      invalidCode: 'Oops! Invalid Code!',
      usedCode: 'Code Already Used',
      codeApplied: 'Code Applied',
      iAggreeToTermsCondition: 'I agree to',
      reviewUpdatedCondition: 'Please review and accept the updated terms & conditions listed below.',
      name: 'Name',
      accepted: 'Accepted',
    },
    button: {
      next: 'Next',
      cancel: 'Cancel',
      accept: 'Accept',
    },
    errors: {
      acceptTermCondition: 'Please accept * mandatory Terms & Conditions to proceed further.',
      invalidReferralCode:
        'The Referral Code ##REFERRAL_CODE## entered is not valid. Please either correct (text value is case sensitive ) or remove the Referral Code to continue.',
      invalidPromotionCode:
        'The Promotion Code ##PROMO_CODE## entered is not valid.Please either correct(text value is case sensitive ) or remove the Promotion Code to continue.',
      referralNotAllowed: 'Referral codes can not be used against this promotion',
    },
    message: {
      refferalCode: {
        first: 'The Referral Code',
        second:
          'entered is not valid. Please either correct (text value is case sensitive ) or remove the Referral Code to continue.',
      },
      promotionCode: {
        first: 'The Promotion Code',
        second:
          'entered is not valid.Please either correct(text value is case sensitive ) or remove the Promotion Code to continue.',
      },
    },
  },
  cookiePolicy: {
    text: 'This website uses cookies to enhance the user experience. By using our site, you acknowledge that you have read and understand our',
    action: 'Cookie Policy',
    iAccept: 'I Accept',
  },
  openSource: {
    title: 'Credits',
  },
  refundRequest: {
    title: 'Withdrawal Requests - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
    header: 'Withdrawal Requests',
    label: {
      name: 'Name',
      requestedDate: 'Requested Date',
      refundRequested: 'Withdrawal Requested',
      account: 'Account (For Credit Withdrawal)',
      remarks: 'Remarks',
      transactionID: 'Request ID',
      processing: 'Processing',
      cancelled: 'Cancelled',
      declined: 'Declined',
      complete: 'Complete',
      date: 'Date',
      status: 'Status',
      cancelRequest: 'Cancel Request',
      action: 'Action',
    },
    button: {
      search: 'Search',
      reset: 'Reset',
      yes: 'Yes',
      no: 'No',
    },
    placeholder: {
      selectStatus: 'Select Status',
    },
    message: {
      confirmCancelRequest: 'Are you sure you want to cancel your withdrawal request?',
    },
    errors: {
      sortCode: {
        min: 'Please enter minimum 6 digit sort code.',
        valid: 'Please enter valid sort code.',
      },
      accountNumber: {
        min: 'Please enter minimum 8 digit account number.',
      },
    },
  },
  footer: {
    version: 'Version',
    copyRightText: 'All rights reserved',
  },
  rfidCards: {
    headerTitle: 'RFID Cards - ##COMPANY_BRAND_NAME## : Rapid EV Charging UK',
    title: 'RFID Cards',
    label: {
      public_card_id: 'Card Number(s)',
      quantity: 'Enter a quantity',
      action: 'Action',
      delete: 'Delete',
      cardNumber: 'Enter Card Number',
      rfid_card_unit_price: 'Unit price of one RFID card is',
      rfid_card_unit_price_label: 'Per RFID card',
    },
    placeholder: {
      quantity: 'Enter a quantity',
      public_card_id: 'Enter Card Number',
    },
    noDataMessage: 'No active RFID card at this moment',
    requestProcessDaysMessage: 'Your RFID card request will be processed within ##DAYS## working days.',
    modal: {
      header: 'RFID Card Request',
      body: 'Please Enter the Number of RFID Cards Required. The cards will be sent to the address that you have setup on your account. On receipt of cards you will need to activate them against your account.',
    },
    addCardModal: {
      header: 'Add RFID Card',
    },
    button: {
      proceed: 'Proceed',
      cancel: 'Cancel',
      addNewCard: 'Add RFID Card',
      requestCard: 'Request New RFID Card',
      delete: 'Delete',
      action: 'Action',
    },
    errors: {
      quantity: {
        min: 'Quantity must be greater than zero',
      },
      public_card_id: {
        valid:
          'The card ID entered has not been recognised. Please ensure that spaces/special characters have not been included in ID.',
      },
    },
    message: {
      here: 'here',
      deleteConfirmMessage:
        'By deleting this card, it will be permanently disabled and can not be re-registered. Are you sure you want to continue?',
    },
  },
  giftAidmessage: 'gift aid message',
  creditAccountDonationNote:
    'By selecting Yes, a donation of ##AMOUNT## will be applied to all future payments. You can change your donation preference at any time by clicking on “Edit Donation Settings” button.',
  PrepaymentAccountDonationNote:
    'By selecting Yes, a donation of ##AMOUNT## will be applied to this payments transaction. You can change your donation preference at any time by clicking on “Edit Donation Settings” button.',
  aboutUs: {
    listTitle: {
      openSourcelicenses: 'Open-Source Licenses',
    },
  },
  price_breakdown_details: {
    detailed_breakup: 'Detailed Breakup',
    total_cost: 'Total Cost',
    conversion_rate: 'Conversion Rate',
  },
};
