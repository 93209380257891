import BaseService from '../base-service';
import { StandardResponse, createStandardResponse } from '../../models/standard-response';
import { IUserData, IUserProfileForm, IPartnerPromotion } from 'models/profile';
import Axios from 'axios';
import { ISubscriptionImage, ISubscriptionImageResponse } from 'models/login';

export const getProfile = async (): Promise<StandardResponse<IUserData>> => {
  try {
    const data = {
      platform_type: 'Web',
    };
    const res = await BaseService({
      url: `/driver/detail`,
      method: 'POST',
      data,
    });
    return createStandardResponse<IUserData>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateProfile = async (data: IUserProfileForm): Promise<StandardResponse<Array<number>>> => {
  try {
    const res = await BaseService({
      url: `/driver`,
      method: 'PUT',
      data,
    });
    return createStandardResponse<Array<number>>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteAccount = async (): Promise<StandardResponse<null>> => {
  try {
    const res = await BaseService({
      url: `/driver/cancel`,
      method: 'DELETE',
    });
    return createStandardResponse<null>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getParentPromotionById = async (
  id: number,
  driver_id: number,
  session_id: string,
): Promise<StandardResponse<IPartnerPromotion>> => {
  try {
    const res = await BaseService({
      url: session_id
        ? `/driver/partner-promotion/${id}/${driver_id}/${session_id}`
        : `/driver/partner-promotion/${id}/${driver_id}`,
      method: 'get',
      data: { byPassAuth: true },
    });
    return createStandardResponse<IPartnerPromotion>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getConfigurationDetail = async (data: { code: string }) => {
  try {
    const res = await Axios.post(
      `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/driver/public/configuration/search`,
      data,
    );
    return Promise.resolve(res.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getSubscriptionImage = async (
  data: ISubscriptionImage,
): Promise<StandardResponse<ISubscriptionImageResponse>> => {
  try {
    const res = await BaseService({
      url: `driver/premium-subscriptions/image`,
      method: 'POST',
      data: { byPassAuth: true, ...data },
    });

    return createStandardResponse<ISubscriptionImageResponse>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
