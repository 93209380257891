import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Form, Button } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormFeedback } from 'reactstrap';
import { Formik, Field } from 'formik';
import { store } from '../../store';
import { companyNameReplace } from '../../utils';
import { Common } from '../../utils/constants';
import images from '../../assets/images';
import { ISignUpProps } from '../../types/propTypes';
import { ReactstrapInput, ReactstrapFormikPasswordInput } from '../../components/reactstrap-formik';
import { SignUpValidationSchema } from '../../validation/login/login-validation';
import SocialMediaLoginForm from './SocialMediaLoginForm';
import { ConfirmationBox } from '../../components/common/ConfirmationBox';
import { SignUpFormHook } from './SignUpFormHook';
import DOMPurify from 'isomorphic-dompurify';

const SignUpForm = ({
  handleChangeFormValues,
  setEmail,
  setPassword,
  setEmailPattern,
  onChangeStage,
}: ISignUpProps) => {
  const { t } = useTranslation();

  const { initialState, modal, handleSubmit, closeModal } = SignUpFormHook({
    handleChangeFormValues,
    setEmail,
    setPassword,
    setEmailPattern,
    onChangeStage,
  });

  return (
    <>
      <Helmet>
        <title>{companyNameReplace(t('signup.title'))} </title>
      </Helmet>
      <button
        type="button"
        className="btn btn-icon btn-link pr-0 btn-back"
        onClick={() => onChangeStage(Common.AuthStage.SIGNIN)}
        title={t<string>('signup.button.back')}
      >
        <img className="icon" src={images.backArrowRSVG} alt={t<string>('forgot.button.back')} />
      </button>
      <h1 className="title">
        <img src={images.logoIconPNG} alt={t<string>('navbar.title')} />
        {t('signup.header')}
      </h1>
      <Formik initialValues={initialState} onSubmit={handleSubmit} validationSchema={SignUpValidationSchema(t)}>
        {(props) => {
          const { handleSubmit, values } = props;
          return (
            <form className="form-secondary" onSubmit={handleSubmit} autoComplete="off" noValidate>
              <div className="form-row">
                <Field
                  name="firstName"
                  id="FirstName"
                  label={t('signup.form.firstName')}
                  component={ReactstrapInput}
                  className="col-sm"
                  maxLength={20}
                  inputclassname="capitalize"
                  onKeyUp={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeFormValues(e);
                  }}
                />
                <Field
                  name="lastName"
                  id="LastName"
                  label={t('signup.form.lastName')}
                  component={ReactstrapInput}
                  className="col-sm"
                  maxLength={20}
                  inputclassname="capitalize"
                  onKeyUp={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeFormValues(e);
                  }}
                />
              </div>

              <Field
                type="text"
                name="email"
                id="Email"
                label={t('signup.form.email')}
                component={ReactstrapInput}
                maxLength={50}
                onKeyUp={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeFormValues(e);
                }}
              />
              <Field
                type="password"
                name="password"
                id="Password"
                label={t('signup.form.password')}
                autoComplete="off"
                component={ReactstrapFormikPasswordInput}
              />
              <input type="hidden" value={values.recaptchaCode} name="recaptchaCode" />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(value) => {
                    props.setFieldValue('recaptchaCode', value);
                    props.setFieldTouched('recaptchaCode', true);
                  }}
                  hl={
                    store?.getState()?.UserData?.language?.code
                      ? store?.getState()?.UserData?.language?.code
                      : store?.getState()?.LanguageInfo?.defaultLanguage?.code
                  }
                  key={
                    store?.getState()?.UserData?.language?.code
                      ? store?.getState()?.UserData?.language?.code
                      : store?.getState()?.LanguageInfo?.defaultLanguage?.code
                  }
                />
                <div className="text-left codeFixWidth">
                  {props.touched.recaptchaCode &&
                    props.errors.recaptchaCode !== '' &&
                    props.values.recaptchaCode == '' && (
                      <FormFeedback className="pb-0 error invalid-feedback d-block" type="invalid">
                        {props.errors.recaptchaCode}
                      </FormFeedback>
                    )}
                </div>
              </div>

              <Form.Group className="pt-3 form-group">
                <Button type="submit" className="btn btn-primary w-100">
                  {t('signup.button.submit')}
                </Button>
              </Form.Group>
              <SocialMediaLoginForm />
              <div className="clearfix border-top-1 pt-4">
                <p className="sign-up">
                  {t('signup.label.accountNew')}{' '}
                  <a
                    href="#"
                    title={t<string>('signup.button.signin')}
                    onClick={(e) => {
                      e.preventDefault();
                      onChangeStage(Common.AuthStage.SIGNIN);
                    }}
                    className="login-btn"
                  >
                    {t('signup.button.signin')}
                  </a>
                </p>
              </div>
            </form>
          );
        }}
      </Formik>
      <ConfirmationBox
        onCancel={closeModal}
        onConfirm={modal.callBack}
        header={modal.header}
        bodyMessage={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(modal.body) }}></div>}
        Message={modal.body}
        body={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(modal.body) }}></div>}
        showModel={modal.showModel}
        closeModel={closeModal}
      />
    </>
  );
};
export default SignUpForm;
