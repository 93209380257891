import { useEffect, useState } from 'react';
import { getConfigurationDetail } from '../../services/user/user-service';
import images from '../../assets/images';
import { store } from '../../store';
import { IAboutUs } from '../../models/about-us';
import { ISupportedLanguage } from '../../store/state/language.state';

export const useAboutUsHook = () => {
  const [aboutUsArray, setAboutUsArray] = useState<Array<IAboutUs>>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { optomanyUrl, CPOSoftwareServiceUrl } = await getImageUrl();

    const queryStringLang = new URLSearchParams(window.location.search);

    const languageInfo = store?.getState()?.LanguageInfo?.supportedLanguages;
    const changelanguage = languageInfo
      ? languageInfo?.filter(
          (item: ISupportedLanguage) =>
            (item && item.code === queryStringLang.get('langCode')) ||
            (item && item.code === queryStringLang.get('language')),
        )[0]
      : {
          code: queryStringLang.get('langCode') || queryStringLang.get('language'),
        };

    const regionCode = process.env.REACT_APP_PORTAL_REGION?.toLocaleLowerCase();

    let languageCode: string | undefined;

    if (queryStringLang.get('langCode') || queryStringLang.get('language')) {
      languageCode = changelanguage?.code;
    } else {
      if (store.getState()?.UserData) {
        languageCode = store.getState()?.UserData?.language?.code;
      } else if (store.getState()?.LanguageInfo) {
        languageCode = store.getState()?.LanguageInfo?.defaultLanguage?.code;
      }
    }

    let jsonFile = require(`../../assets/aboutUs/uk/en.json`);
    if (regionCode && languageCode) {
      jsonFile = require(`../../assets/aboutUs/${regionCode}/${languageCode}.json`);
    }

    let aboutUs = JSON.stringify(jsonFile?.aboutus);

    aboutUs = aboutUs
      .replace('##IMAGE_URL##', CPOSoftwareServiceUrl)
      .replace('##IMAGE_URL##', optomanyUrl)
      .replace('##IMAGE_URL##', images.rightArrowDarkSVG);

    setAboutUsArray(JSON.parse(aboutUs));
  };

  const getImageUrl = async () => {
    try {
      const optomany = getConfigurationDetail({
        code: 'PAYMENT_PROVIDER_LOGO',
      });
      const CPOSoftwareService = getConfigurationDetail({
        code: 'CHARGING_PROVIDER_LOGO',
      });

      const [optomanyData, CPOSoftwareServiceData] = await Promise.all([optomany, CPOSoftwareService]);
      const optomanyUrl = optomanyData?.data[0]?.value;
      const CPOSoftwareServiceUrl = CPOSoftwareServiceData?.data[0]?.value;

      return { optomanyUrl, CPOSoftwareServiceUrl };
    } catch {
      return { optomanyUrl: '', CPOSoftwareServiceUrl: '' };
    }
  };

  const handleClick = () => {
    window.location.href = `${process.env.REACT_APP_REDIRECT_URL}/open-source`;
  };
  return {
    handleClick,
    aboutUsArray,
    fetchData,
  };
};
