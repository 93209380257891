import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

// Function to remove Invalid Characters from a Password
const removeInvalidCharacters = (text: string) => {
  return text.replace(/[^\p{L}\d!@#$%^&*()\-_=+{};:,<.>]+/gu, '');
};

const ReactstrapFormikPasswordInput = ({
  field: { name, ...fields },
  form: { touched, errors, setFieldTouched, setFieldValue },
  ...props
}) => {
  const [isFocused, setFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { id, className, ...rest } = props;
  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
    setFieldTouched(name, true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = removeInvalidCharacters(event.target.value);
    setFieldValue(name, newValue);
  };

  let groupClassName = '';
  if (isFocused || fields.value) {
    groupClassName = 'value-exists';
  }
  if (className) {
    groupClassName = `${groupClassName} password-block ${className}`;
  }
  if (!showPassword) {
    groupClassName = `${groupClassName} password-block`;
  } else {
    groupClassName = `${groupClassName} password-block show`;
  }

  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Form.Group
      controlId={id}
      className={
        touched[fields.name] && errors[fields.name]
          ? `${groupClassName} error form-group`
          : `${groupClassName} form-group`
      }
    >
      {props.label && (
        <Form.Label className={'float-label'} htmlFor={fields.id}>
          {props.label}
        </Form.Label>
      )}
      <Form.Control
        {...rest}
        {...fields}
        name={name}
        isInvalid={Boolean(touched[name] && errors[name])}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleInputChange}
        className={Boolean(touched[name] && errors[name]) && 'error img-remove'}
        type={showPassword ? 'text' : 'password'}
      />
      <span className="password-icon" onClick={onShowPassword}></span>
      {touched[name] && errors[name] && errors[name] !== 'required' && (
        <Form.Control.Feedback className="pb-0 error invalid-feedback d-block" type="invalid">
          {errors[name]}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};
export default ReactstrapFormikPasswordInput;
