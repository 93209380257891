import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Form, Button, Modal } from 'react-bootstrap';
import _ from 'lodash';
import { ITermsConditionData } from '../../models/login';
import { AppState } from '../../store/state/app.state';
import images from '../../assets/images';
import { TermsAndConditionListingHook } from './TermsAndConditionListingHook';
import { companyNameReplace } from '../../utils/index';
import DOMPurify from 'isomorphic-dompurify';

const TermsAndConditionListing = () => {
  const { t } = useTranslation();
  const userData = useSelector((state: AppState) => state.UserData);
  const {
    fetchData,
    handleFocus,
    handleBlur,
    checkReferralCodeValid,
    handleCheckChange,
    handleModel,
    handleSubmit,
    checkPromotionCodeValid,
    isReferralBonusOff,
    referralCodeClass,
    referralCode,
    isValidReferralCode,
    promotionCodeClass,
    promotionCode,
    isValidPromotionCode,
    isUsedPromotionCode,
    termsAndConditionList,
    selectedTermsAndCondition,
    model,
    acceptTermsCondition,
    setPromotionCode,
    setReferralCode,
  } = TermsAndConditionListingHook();

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{companyNameReplace(t('termsAndConditionListing.title'))} </title>
      </Helmet>
      <div className="login-page container-fluid p-0">
        <div className="row h-100 no-gutters">
          <div className="col-md login-background-block">
            <a href="#" title={t<string>('login.label.logo')} className="instavolt-logo">
              <img src={images.logoPNG} alt={t<string>('login.label.logo')} />
            </a>
          </div>
          <div className="col-md-auto login-section">
            <div className="login-outer">
              <div className="login-contain">
                <div className="title-outer">
                  <h1 className="title">
                    <img src={images.logoIconPNG} alt={t<string>('login.label.logo')} />
                    {userData && userData.has_saved_terms
                      ? t('termsAndConditionListing.label.termsAndConditions')
                      : t('termsAndConditionListing.label.welcome')}
                  </h1>
                </div>

                <form className="form-secondary" autoComplete="off">
                  {userData && !userData.has_saved_terms && (
                    <>
                      {isReferralBonusOff === false && (
                        <div className={`form-group ${referralCodeClass}`}>
                          <label className="float-label" htmlFor="referralCode">
                            {referralCode !== '' ? '' : t('termsAndConditionListing.label.referralCode')}
                          </label>
                          <div className="apply-text-outer success">
                            <input
                              className="form-control"
                              name="referralCode"
                              id="referralCode"
                              type="text"
                              value={referralCode}
                              onChange={(e) => setReferralCode(e.target.value)}
                              onFocus={(e) => handleFocus(e)}
                              onBlur={(e) => {
                                handleBlur(e);
                                checkReferralCodeValid();
                              }}
                              data-testid="referralCode"
                            />

                            {isValidReferralCode === null && (
                              <a href="#" className="apply-text" onClick={checkReferralCodeValid}>
                                {t('termsAndConditionListing.label.apply')}
                              </a>
                            )}
                            {isValidReferralCode !== null && !isValidReferralCode && (
                              <span className="apply-text">{t('termsAndConditionListing.label.invalidCode')}</span>
                            )}
                            {isValidReferralCode !== null && isValidReferralCode && (
                              <span className="applied">
                                {t('termsAndConditionListing.label.codeApplied')}
                                <img className="icon" src={images.appliedSVG} alt="" />
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      <div className={`form-group ${promotionCodeClass}`}>
                        <label className="float-label" htmlFor="promotionCode">
                          {t('termsAndConditionListing.label.promotioncode')}
                        </label>
                        <div className="apply-text-outer success">
                          <input
                            className="form-control"
                            name="promotionCode"
                            id="promotionCode"
                            type="text"
                            value={promotionCode}
                            onChange={(e) => setPromotionCode(e.target.value)}
                            onFocus={(e) => handleFocus(e)}
                            onBlur={(e) => {
                              handleBlur(e);
                              checkPromotionCodeValid();
                            }}
                            data-testid="promotionCode"
                          />

                          {isValidPromotionCode === null && (
                            <a href="#" className="apply-text" onClick={checkPromotionCodeValid}>
                              {t('termsAndConditionListing.label.apply')}
                            </a>
                          )}
                          {isValidPromotionCode !== null && !isValidPromotionCode && (
                            <span className="apply-text">
                              {isUsedPromotionCode
                                ? t('termsAndConditionListing.label.usedCode')
                                : t('termsAndConditionListing.label.invalidCode')}
                            </span>
                          )}
                          {isValidPromotionCode !== null && isValidPromotionCode && (
                            <span className="applied">
                              {t('termsAndConditionListing.label.codeApplied')}
                              <img className="icon" src={images.appliedSVG} alt="" />
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {userData && userData.has_saved_terms ? (
                    <>
                      <p>{t('termsAndConditionListing.label.reviewUpdatedCondition')}</p>
                    </>
                  ) : (
                    <>
                      <p className="text-center">{t('termsAndConditionListing.label.termsAndConditions')}</p>
                    </>
                  )}

                  {termsAndConditionList.length > 0 &&
                    termsAndConditionList.map((data: ITermsConditionData, index: number) => {
                      return (
                        <Form.Group key={index} className="form-group">
                          <div className="custom-control custom-checkbox custom-control-inline">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`tc${data.id}`}
                              name="termsCondition"
                              checked={selectedTermsAndCondition.includes(data.id)}
                              onChange={() => handleCheckChange(data)}
                            />
                            <label className="custom-control-label pb-0" htmlFor={`tc${data.id}`}>
                              {t('termsAndConditionListing.label.iAggreeToTermsCondition')}
                              <span
                                onClick={() => handleModel(data.content, data.name, true, data.id)}
                                className="term-and-condition"
                              >
                                {`${data.name} ${data.mandatory ? '*' : ''}`}
                              </span>
                            </label>
                          </div>
                        </Form.Group>
                      );
                    })}
                  <p>
                    <span className="themeTextColor">{t('termsAndConditionListing.label.note')}</span>
                    {t('termsAndConditionListing.noteDetails')}
                  </p>
                  <Form.Group className="form-group">
                    <Button onClick={handleSubmit} className="btn btn-primary w-100">
                      {t('termsAndConditionListing.button.next')}
                    </Button>
                  </Form.Group>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={Boolean(model.showModal)} onHide={() => handleModel('', '', null, null)}>
        <Modal.Header>
          <Modal.Title>{model.header}</Modal.Title>
          <button type="button" className="close" onClick={() => handleModel('', '', null, null)}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">{t('Payment.Model.button.close')}</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div
            id="terms_condition fixModalSize"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(model.body),
            }}
          ></div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary"
            onClick={() => {
              acceptTermsCondition(Number(model.id));
            }}
            title={t<string>('termsAndConditionListing.button.accept')}
          >
            <span>{t('termsAndConditionListing.button.accept')}</span>
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={() => handleModel('', '', false, null)}
            title={t<string>('termsAndConditionListing.button.cancel')}
          >
            <span>{t('termsAndConditionListing.button.cancel')}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default TermsAndConditionListing;
