import { IAppState } from '../store/state/app.state';

export const loadStorage = (): IAppState => {
  const serializedStore = localStorage.getItem('state');
  try {
    if (serializedStore === null) {
      return undefined;
    }
  } catch (_error) {
    console.error('Error While Getting LocalStorage State!!!');
  }
  return JSON.parse(serializedStore);
};

export const saveStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    return localStorage.setItem('state', serializedState);
  } catch (_error) {
    console.error('Error While Saving LocalStorage State!!!');
  }
};
