import React, { useEffect, useState } from 'react';
import { getParentPromotionById } from '../../services/user/user-service';
import { StandardResponse } from '../../models/standard-response';
import { IPartnerPromotion } from '../../models/profile';
import PreviewPromotion from '../../components/common/PreviewPromotion';
import { useParams } from 'react-router-dom';

const PartnerPromotionPage = () => {
  const { id, driver_id, session_id } = useParams();

  const [partnerPromotion, setPartnerPromotion] = useState<IPartnerPromotion | null>(null);
  const [viewPromotionKey, setViewPromotionKey] = useState<number>(0);

  useEffect(() => {
    getPartnerPromotionById();
  }, []);

  const getPartnerPromotionById = () => {
    getParentPromotionById(Number(id), Number(driver_id), String(session_id))
      .then((res: StandardResponse<IPartnerPromotion>) => {
        setPartnerPromotion(res.Data);
        setViewPromotionKey(Math.random() * 10);
      })
      .catch(() => {});
  };

  return (
    <>
      <div id="faq-wrapper">
        {partnerPromotion && (
          <PreviewPromotion
            layout={partnerPromotion.layout}
            image={partnerPromotion.image}
            qrCode={partnerPromotion.qr_code_details}
            init={viewPromotionKey}
            additionalImages={partnerPromotion.additional_images}
          />
        )}
      </div>
    </>
  );
};
export default PartnerPromotionPage;
