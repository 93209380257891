import React, { useEffect } from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { IFaqType } from '../../models/profile';
import { FleetFAQPageHook } from './FleetFAQPageHook';

const FleetFAQPage = () => {
  const { fetchData, faqArray, CustomToggle } = FleetFAQPageHook();
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid" id="faq-wrapper">
        <Accordion defaultActiveKey="0" className="faqs-outer p-3">
          {faqArray &&
            faqArray.map((faq: IFaqType, index) => {
              const answerList = faq.answer.split('\n');
              return (
                <Card key={index}>
                  <Card.Header>
                    <CustomToggle eventKey={index.toString()}>{faq.question}</CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={index.toString()}>
                    <Card.Body>
                      {answerList.map((data, index) => {
                        return (
                          <div key={index}>
                            {data}{' '}
                            {index === answerList.length - 1 ? (
                              <></>
                            ) : (
                              <>
                                <br />
                                <br />
                              </>
                            )}
                          </div>
                        );
                      })}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
        </Accordion>
      </div>
    </>
  );
};

export default FleetFAQPage;
