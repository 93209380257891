import {
  signOut,
  signIn,
  confirmSignUp,
  resendSignUpCode,
  resetPassword,
  confirmResetPassword,
  updatePassword,
  fetchAuthSession,
  AuthSession,
} from 'aws-amplify/auth';
import {
  ISignUpOTPFormField,
  ILoginForm,
  IForgotPasswordFormField,
  IAuthResponse,
  IForgotPasswordChangeField,
  ISignUpConfirmResponse,
  ILoginResponse,
  IResendOtpResponse,
  IAPIKeyResponse,
} from '../../models/login';
import { Common } from 'utils/constants';
import { trackPromise } from 'react-promise-tracker';
import { IGetConfigurationData } from 'models/profile';
import BaseService from 'services/base-service';

export const login = async (data: ILoginForm): Promise<ILoginResponse & AuthSession> => {
  try {
    const res = await trackPromise(signIn({ username: data.email.toLowerCase(), password: data.password }));
    const authSession = await fetchAuthSession();
    return Promise.resolve({ ...res, authSession });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const logout = async () => {
  try {
    const data = await trackPromise(signOut());
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const signupConfirm = async (data: ISignUpOTPFormField): Promise<ISignUpConfirmResponse> => {
  try {
    const res = await trackPromise(
      confirmSignUp({
        username: data.username.toLowerCase(),
        confirmationCode: data.code.toString(),
      }),
    );
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resendOtp = async (username: string): Promise<IResendOtpResponse> => {
  try {
    const res = await trackPromise(resendSignUpCode({ username: username.toLowerCase() }));
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const forgotPassword = async (data: IForgotPasswordFormField): Promise<IAuthResponse> => {
  try {
    await trackPromise(resetPassword({ username: data.email.toLowerCase() }));
    return Promise.resolve({
      status: Common.Authentication.SUCCESS,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const forgotPasswordChange = async (data: IForgotPasswordChangeField): Promise<IAuthResponse> => {
  try {
    await trackPromise(
      confirmResetPassword({
        username: data.email.toLowerCase(),
        confirmationCode: data.code,
        newPassword: data.password,
      }),
    );
    return Promise.resolve({ status: Common.Authentication.SUCCESS });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changePassword = async (oldPassword: string, newPassword: string): Promise<IAuthResponse> => {
  try {
    await trackPromise(updatePassword({ oldPassword, newPassword }));
    return Promise.resolve({ status: Common.Authentication.SUCCESS });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAPIKey = async (data: IAPIKeyResponse): Promise<IGetConfigurationData> => {
  try {
    const res = await BaseService({
      url: '/driver/public/configuration/search',
      method: 'POST',
      data: { ...data, byPassAuth: true },
    });
    return Promise.resolve(res.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
