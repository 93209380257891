import { signUp } from 'aws-amplify/auth';
import BaseService from 'services/base-service';
import { trackPromise } from 'react-promise-tracker';
import {
  ISignUpFormField,
  ITermsConditionData,
  saveTermsConditionRequestData,
  IReferralDetail,
  IRegionConfigurations,
  ICognitoSignUpResponse,
  ISignUpResponse,
  IObjectRequest,
  IDriverVerified,
  IDriverVerifiedData,
  ICheckEmailExists,
  ICheckEmailExistsResponse,
  IPromotionDetail,
} from 'models/login';
import { StandardResponse, createStandardResponse } from 'models/standard-response';
import { IRegionData } from 'models/profile';
import { ISupportedLanguage } from 'store/state/language.state';
import axios from 'axios';
import { store } from 'store';

export const cognitoSignup = async (data: ISignUpFormField): Promise<ICognitoSignUpResponse> => {
  try {
    const res = await trackPromise(
      signUp({
        username: data.email.toLowerCase(),
        password: data.password,
        options: {
          userAttributes: {
            'custom:language': data?.language_preference_name?.toLowerCase(),
          },
        },
      }),
    );

    const objRequest = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email.toLowerCase(),
      provider_key: res.userId,
      captcha_code: data.recaptchaCode,
      platform_type: 'WEB',
      language_preference_id: data.language_preference_id,
    };
    signup(objRequest);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const signup = async (data: IObjectRequest): Promise<ISignUpResponse> => {
  try {
    const res = await BaseService({
      url: `/driver/signup`,
      method: 'POST',
      data: { ...data, byPassAuth: true },
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const emailNotification = async (email: string): Promise<boolean> => {
  const data = { email: email.toLowerCase(), byPassAuth: true };
  try {
    await BaseService({
      url: `/driver/notify-password-change`,
      method: 'PUT',
      data: { ...data, byPassAuth: true },
    });
    return Promise.resolve(true);
  } catch (_e) {
    return Promise.resolve(false);
  }
};

export const verifyReferaalCode = async (code: string): Promise<boolean> => {
  try {
    await BaseService({
      url: `/driver/check-referral-code/${code}`,
      method: 'GET',
    });
    return true;
  } catch (_e) {
    return false;
  }
};
export const checkReferralCode = async (referral_code: string): Promise<StandardResponse<IReferralDetail>> => {
  try {
    const res = await BaseService({
      url: `/driver/check-referral-code/${referral_code}`,
      method: 'GET',
    });
    return createStandardResponse<IReferralDetail>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const checkPromotionCode = async (promotion_code: string): Promise<StandardResponse<IPromotionDetail>> => {
  try {
    const res = await BaseService({
      url: `/driver/check-promotion-code/${promotion_code}`,
      method: 'GET',
      data: { byPassAuth: true },
    });

    return createStandardResponse<IPromotionDetail>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const saveTermsAndCondition = async (data: saveTermsConditionRequestData): Promise<StandardResponse<number>> => {
  try {
    const res = await BaseService({
      url: `/driver/referral-promotion-terms`,
      method: 'POST',
      data,
    });
    return createStandardResponse<number>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const getTermsAndConditionListing = async (): Promise<StandardResponse<Array<ITermsConditionData>>> => {
  try {
    const res = await BaseService({
      url: `/driver/settings/terms-and-conditions`,
      method: 'get',
    });
    return createStandardResponse<Array<ITermsConditionData>>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const checkDriverIsVerified = async (data: IDriverVerifiedData): Promise<StandardResponse<IDriverVerified>> => {
  try {
    const res = await BaseService({
      url: `/driver/verify`,
      method: 'PUT',
      data: { ...data, byPassAuth: true },
    });
    return createStandardResponse<IDriverVerified>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const checkEmailAlreadyExists = async (
  data: ICheckEmailExists,
): Promise<StandardResponse<ICheckEmailExistsResponse>> => {
  try {
    const res = await BaseService({
      url: `/driver/check-email-exists-with-without-federated-account`,
      method: 'POST',
      data: { ...data, byPassAuth: true },
    });

    return createStandardResponse<ICheckEmailExistsResponse>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getSupportedLanguages = async (): Promise<ISupportedLanguage> => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/driver/languages/search`,
      {},
    );
    return Promise.resolve(res.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getRegions = async (language?: string): Promise<IRegionData> => {
  const languageCode = store?.getState()?.LanguageInfo?.defaultLanguage?.code
    ? store?.getState()?.LanguageInfo?.defaultLanguage?.code
    : 'en';
  const headers = {
    language: language || languageCode,
    Authorization: process.env.REACT_APP_GLOBAL_SERVICE_API_TOKEN,
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_GLOBAL_API_SERVER}/v1.0/global-services/regions/search`,
      {},
      {
        headers: headers,
      },
    );
    return Promise.resolve(res.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getRegionConfigurations = async (): Promise<IRegionConfigurations> => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/driver/get-region-configs`,
    );
    return Promise.resolve(res.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
