export default {
  route: {
    dashboard: 'Tauler',
    article: 'Articles',
    stationTitle: "Detalls de l'estació: ##COMPANY_BRAND_NAME## - Càrrega ràpida de vehicles elèctrics al Regne Unit",
    or: 'O',
  },
  month: {
    jan: 'Gen',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Abr',
    may: 'Mai',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Ago',
    sep: 'Set',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Des',
  },
  error: {
    accessDenied: 'Accés denegat',
    somethingWrong: 'Alguna cosa ha anat malament!',
    notFound: 'Pàgina no trobada',
  },
  navbar: {
    title: '##COMPANY_BRAND_NAME##',
    logOut: 'Tancar sessió',
    myProfile: 'El meu perfil ',
    termsConditions: 'Termes i condicions',
    menu: {
      dashboard: 'Tauler',
      paymentConfiguration: 'Configuració del pagament',
      chargingPaymentHistory: 'Històric de càrregues i pagaments',
      rewards: 'Recompenses',
      rfidCards: 'Targetes RFID',
    },
  },
  login: {
    title: 'Inici de sessió - ##COMPANY_BRAND_NAME##: Càrrega ràpida de vehicles elèctrics al Regne Unit',
    header: 'Benvingut a',
    form: {
      email: 'Identificador de correu electrònic',
      password: 'Contrasenya',
    },
    placeholder: {
      region: 'Selecciona la regió',
      language: "Selecciona l'idioma",
    },
    button: {
      submit: 'Inici de sessió',
      forgotPassword: 'Has oblidat la contrasenya?',
      signup: "Registra't",
      signin: 'Inicia la sessió',
    },
    label: {
      logo: '##COMPANY_BRAND_NAME##',
      thirdPartyLogin: 'O inicia sessió amb',
      rememberMe: "Recorda'm",
      newAccount: 'No tens un compte?',
      facebook: 'Inicia la sessió amb Facebook',
      google: 'Inicia la sessió amb Google',
      apple: 'Inicia la sessió amb Apple',
      copyright: 'Copyright ##COMPANY_BRAND_NAME## Ltd',
    },
    toast: {
      success: 'Inici de sessió del conductor correcte.',
      inValid: 'Introdueix un correu electrònic i contrasenya correctes.',
      error: "S'ha produït un error en processar la teva sol·licitud. Torna a intentar-ho més tard.",
      cancelled: 'La cancel·lació del teu compte està en procés, així que no podràs realitzar cap acció.',
      maximumAttempt: "Has arribat al nombre màxim d'intents. Intenta-ho de nou.",
      reminderMessage:
        'Afegeix una targeta de pagament al teu compte de crèdit o afegeix fons al teu compte de prepagament per activar el pagament associat a aquest codi de promoció.',
    },
    errors: {
      inValidEmail: "L'adreça de correu electrònic no és vàlida.",
    },
  },
  forgot: {
    title: 'Contrasenya oblidada - ##COMPANY_BRAND_NAME##: Càrrega ràpida de vehicles elèctrics al Regne Unit',
    resetTitle:
      'Restableix la teva contrasenya - ##COMPANY_BRAND_NAME##: Càrrega ràpida de vehicles elèctrics al Regne Unit',
    header: 'Has oblidat la teva contrasenya?',
    subHeader:
      "Introdueix el teu correu electrònic a continuació i t'enviarem un missatge per restablir la teva contrasenya",
    resetHeader: 'Restableix la teva contrasenya.',
    resetSubHeader: 'Introdueix el codi que et vam enviar per correu electrònic i restableix la teva contrasenya. ',
    supportNumber: "El nostre número d'assistència",
    button: {
      back: 'Enrere',
      submit: 'Restablir la meva contrasenya',
      save: 'Desa la nova contrasenya',
    },
    form: {
      email: 'Correu electrònic',
      code: 'Codi',
      password: 'Contrasenya',
      confirmPassword: 'Torna a confirmar la contrasenya',
      message:
        "Si tens un compte vàlid, hauries de rebre un codi via correu electrònic. Tingues en compte que si et vas registrar per un compte de ##COMPANY_BRAND_NAME## amb un compte federat, com ara d'Apple, Google o Facebook, no podràs restablir la teva contrasenya, ja que aquesta està gestionada pel servei de tercers.  Si necessites més ajuda, posa't en contacte amb nosaltres mitjançant",
    },
    toast: {
      success: "La teva contrasenya s'ha canviat correctament.",
      error: "S'ha produït un error en processar la teva sol·licitud. Torna a intentar-ho més tard.",
      inValidEmailAddress: "L'adreça de correu electrònic no és vàlida.",
      invalidCode: 'Ups! Has introduït un codi no vàlid.',
    },
    errors: {
      passwordFormat:
        'La contrasenya ha de tenir més de 10 caràcters, amb almenys una lletra majúscula, una lletra minúscula, un caràcter especial i un número.',
      confirmPasswordMatch:
        'la contrasenya de confirmació ha de coincidir amb la nova contrasenya que hagis introduït.',
      verificationCodeFormat: 'Si us plau, introdueix el codi de verificació de 6 dígits.',
    },
  },
  dashboard: {
    title: 'Tauler - ##COMPANY_BRAND_NAME##: Càrrega ràpida de vehicles elèctrics al Regne Unit',
    header: 'Tauler',
    premiumSubscription:
      '##COMPANY_BRAND_NAME## proporciona un servei premium. Selecciona subscriu-te per gaudir de les funcions del servei Premium gratis durant 3 mesos.',
  },
  permission: {
    createArticle: 'Afegeix article',
    editArticle: "Edita l'article",
    save: 'Desa',
    confirm: 'Estàs segur?',
    cancel: 'Cancel·la',
    update: 'Actualitza',
  },
  profile: {
    title: 'Perfil - ##COMPANY_BRAND_NAME##: Càrrega ràpida de vehicles elèctrics al Regne Unit',
    header: 'Perfil',
    addresses: 'Adreces',
    personalDetails: {
      header: 'Dades personals',
      label: {
        firstName: 'Nom',
        lastName: 'Cognom',
        email: 'Identificador de correu electrònic',
        address: 'Adreça',
        billingAddress: 'Adreça de facturació',
        postcode: 'Codi postal',
        samePersonalAddress: "La mateixa que l'adreça personal",
      },
      placeholder: {
        firstName: 'Nom',
        lastName: 'Cognom',
        email: 'Identificador de correu electrònic',
        address: 'Introdueix la teva adreça',
        billingAddress: 'Adreça de facturació',
        postcode: 'Codi postal',
        searchAddress: 'Comença a escriure la teva adreça per a buscar',
      },
      button: {
        save: 'Desa',
        cancel: 'Cancel·la',
        yes: 'Sí',
        no: 'No',
      },
      errors: {
        email: {
          email: "L'adreça de correu electrònic no és vàlida.",
        },
        first_name: {
          matches: 'El nom no és vàlid.',
        },
        last_name: {
          matches: 'El cognom no és vàlid.',
        },
        address: {
          address: 'Mínim ##MIN_LENGTH## caràcter requerit',
          invalid: "L'adreça no és vàlida",
          minLine: "L'adreça que heu introduït no compleix les línies d'adreces mínimes per ser vàlida.",
        },
        billing_Address: {
          billing_Address: 'Mínim ##MIN_LENGTH## caràcter requerit',
          invalid: "L'adreça de facturació no és vàlida",
          minLine: "L'adreça que heu introduït no compleix les línies d'adreces mínimes per ser vàlida.",
        },
      },
      model: {
        header: 'Cerca adreça',
        address: "Escriu l'adreça",
        suggested_address: 'Adreces suggerides',
        no_data_found: "No s'han trobat adreces",
        no_address_found: "No s'ha trobat cap adreça.",
        button: {
          ok: "D'acord",
          cancel: 'Cancel·la',
          close: 'Tanca',
        },
        toast: {
          invalidAddress: "S'ha introduït un codi postal o una adreça no vàlids.",
        },
      },
    },
    addressDetails: {
      header: 'Adreça',
      label: {
        address1: 'Adreça',
        address2: 'Adreça línia 2',
        city: 'Ciutat',
        county: 'Comtat',
        paymentPostcode: 'Codi postal',
      },
      placeholder: {
        address1: 'Adreça',
        address2: 'Adreça línia 2',
        city: 'Ciutat',
        county: 'Comtat',
      },
    },
    billingAddressDetails: {
      header: 'Adreça de facturació',
      label: {
        billingaddress1: 'Adreça',
        billingaddress2: 'Adreça línia 2',
        billingcity: 'Ciutat',
        billingcounty: 'Comtat',
        samePersonalAddress: "La mateixa que l'adreça personal",
      },
      placeholder: {
        billingaddress1: 'Adreça',
        billingaddress2: 'Adreça línia 2',
        billingcity: 'Ciutat',
        billingcounty: 'Comtat',
      },
    },
    marketingPreferenceDetails: {
      header: 'Preferències de màrqueting',
      label: {
        receivePreference: 'Rebre comunicacions de màrqueting de ##COMPANY_BRAND_NAME##',
        yes: 'Sí',
        no: 'No',
      },
      message: 'Vols rebre comunicacions de màrqueting de ##COMPANY_BRAND_NAME##?',
    },
    langPreference: "Preferència d'idioma",
    changePassword: {
      header: 'Contrasenya',
      toast: {
        success: "La teva contrasenya s'ha canviat correctament.",
        error: 'Ups! La teva contrasenya actual és incorrecta.',
        commonError: "S'ha produït un error en processar la teva sol·licitud. Torna a intentar-ho més tard.",
      },
      button: {
        update: 'Actualitza',
      },
      label: {
        current_password: 'Contrasenya actual',
        new_password: 'Nova contrasenya',
        confirm_password: 'Torna a confirmar la contrasenya',
      },
      placeholder: {
        current_password: 'Contrasenya actual',
        new_password: 'Nova contrasenya',
        confirm_password: 'Torna a confirmar la contrasenya',
      },
      errors: {
        passwordFormat:
          'La contrasenya ha de tenir més de 10 caràcters, amb almenys una lletra majúscula, una lletra minúscula, un caràcter especial i un número.',
        confirmPasswordMatch:
          'La contrasenya de confirmació ha de coincidir amb la nova contrasenya que has introduït.',
      },
    },
    cancelAccount: {
      header: 'Elimina el compte',
      note1:
        'Pots eliminar el teu compte en qualsevol moment. ##COMPANY_BRAND_NAME## eliminaràs totes les teves dades del nostre sistema.',
      note2: 'Per a suprimir el teu compte.',
      clickHere: 'Fes clic aquí',
      toast: {
        success:
          "Lamentem veure't marxar. Hem iniciat el procés d'eliminar el teu compte i et notificarem via correu electrònic una vegada completat el procés.",
        failed: "S'ha produït un error en processar la teva sol·licitud. Torna a intentar-ho més tard.",
      },
      model: {
        title: 'Elimina el compte',
        body: 'Eliminar el teu compte suprimirà permanentment totes les teves dades del nostre sistema. Encara vols continuar?',
        button: {
          yes: 'Sí',
          no: 'No',
        },
      },
    },
    limitExceedAddressError: "No es pot trobar l'adreça en aquest moment",
  },
  signup: {
    header: 'Crea el teu compte amb',
    title: 'Registre - ##COMPANY_BRAND_NAME##: Càrrega ràpida de vehicles elèctrics al Regne Unit',
    form: {
      firstName: 'Nom',
      lastName: 'Cognom',
      email: 'Correu electrònic',
      password: 'Contrasenya',
      referralCode: 'Codi de referència',
      code: 'Codi',
      terms: 'Accepto',
      condition: 'Termes i condicions.',
    },
    button: {
      submit: "Registra't",
      signin: 'Inicia la sessió',
      login: 'Inici de sessió',
      back: 'Enrere',
      submitOtp: 'Enviar',
      resend: 'Tornar a enviar el codi?',
    },
    label: {
      continueWith: 'O continuar amb',
      accountNew: 'Ja tens un compte?',
      otpConfirmationTitle1: "Acabem d'enviar-te un correu electrònic",
      otpConfirmationTitle2: 'Per a iniciar sessió, introdueix el codi enviat a',
      otpNonVerifiedEmailNote1: 'El teu compte encara no està verificat. Introdueix el codi que hem enviat a',
      otpNonVerifiedEmailNote2: 'per a seguir endavant.',
      resendTitle: 'No has rebut cap correu electrònic?',
      resend: 'Tornar a enviar',
      email: 'Correu electrònic',
      instavolt: '##COMPANY_BRAND_NAME##',
      modalMessage:
        '##EMAIL## ja ha registrat un compte de ##COMPANY_BRAND_NAME## a través de ##FEDERATEDACCOUNT##. Vols continuar?',
      instavoltUserAccount: "Compte d'usuari de ##COMPANY_BRAND_NAME##",
      alreadyRegisteredWithNormalAcc:
        "##EMAIL## ja ha registrat un compte de ##COMPANY_BRAND_NAME## a través del compte d'usuari de ##COMPANY_BRAND_NAME##. Vols continuar?",
    },
    toast: {
      success: "El compte del conductor s'ha creat correctament.",
      alreadyExist: "L'adreça de correu electrònic que has introduït ja existeix.",
      error: "S'ha produït un error en processar la teva sol·licitud. Torna a intentar-ho més tard.",
      confirmationSuccess: "El compte s'ha verificat correctament.",
      invalidCode: 'Ups! Has introduït un codi no vàlid.',
      resendSentOTP: 'Codi OTP enviat correctament.',
      resendOTPErr: "El codi OTP no s'ha enviat correctament. Intenta-ho de nou.",
      referralCodeInvalid: 'El codi de referència no és vàlid.',
      resendSignupCode: "Hola, acabem d'enviar-te un altre codi, comprova el teu correu electrònic.",
      resendLimitExceeded: "Has arribat al nombre màxim d'intents. Torna-ho a provar després d'una estona.",
    },
    errors: {
      recaptchaCode: 'Verificar codi recaptcha.',
      passwordFormat:
        'La contrasenya ha de tenir més de 10 caràcters, amb almenys una lletra majúscula, una lletra minúscula, un caràcter especial i un número.',
      securityCode: 'El codi de seguretat ha de tenir com a mínim 6 dígits',
      firstName: 'El nom no és vàlid.',
      lastName: 'El cognom no és vàlid.',
      termsAndCondition: 'Has de llegir i acceptar els Termes i Condicions per poder procedir',
    },
  },
  Payment: {
    Title: 'Configuració de pagament - ##COMPANY_BRAND_NAME##: Càrrega ràpida de vehicles elèctrics al Regne Unit',
    ChargeUnit: '(kWh)',
    description: 'Pagament a ##COMPANY_BRAND_NAME##',
    PaymentType: {
      Title: 'Configuració del pagament',
      SubTitle: "Selecciona l'opció de pagament",
      Message: {
        selectPaymentOption: "Selecciona l'opció de pagament.",
      },
      Inputs: {
        creditAccount: {
          label: 'Compte de crèdit',
          label1: 'Paga mentre carregues',
        },
        paymentAccount: {
          label: 'Compte de prepagament',
          label1: 'Paga anticipadament i utilitza el crèdit del teu compte per carregar',
        },
      },
      Buttons: {
        Next: {
          text: 'Següent',
        },
      },
    },
    CreditPayment: {
      Title: 'Configuració del pagament',
      SubTitle: 'Compte de crèdit',
      note: 'Si us plau, tingues en compte que en afegir una targeta de pagament, el teu compte serà debitat amb ##CURRENCY##, que es carregarà al saldo del teu moneder de ##COMPANY_BRAND_NAME##.',
      donateMessage: {
        first: 'Sí, donar',
        second: 'Per arrodonir el pagament a',
      },
      donationNote:
        '“Arrodoniment” del donatiu a la quantitat més propera de 50 penics o “Recarrega” amb una quantitat fixa per cada transacció de pagament.',
      Inputs: {
        CardTitle: {
          label: 'Nom a la targeta',
          placeHolder: 'Nom a la targeta',
          required: 'El títol de la targeta és obligatori',
        },
        CardNumber: {
          label: 'Número de la targeta',
          placeHolder: '1234  1234  1234  1234',
          invalid: 'Número de targeta no vàlid',
          required: 'Es requereix el número de targeta',
          maxlimit: 'El número de targeta ha de tenir com a màxim 20 dígits',
        },
        Expiry: {
          label: 'Caducitat',
          required: 'Es requereix el mes de caducitat',
        },
        Year: {
          label: 'Any',
          required: "Es requereix l'any de caducitat",
        },
        CSC: {
          label: 'CSC',
          label1: '(Codi de seguretat de la targeta)',
          required: 'Es requereix el codi de seguretat de la targeta',
          placeHolder: 'CSC',
          maxlimit: "Introdueix un codi d'almenys 3 dígits.",
        },
        Donation: {
          label: 'Sí, vull fer una donació!',
        },
        giftaid: {
          label: "Vols activar l'ajuda per a donatius?",
        },
        RoundUp: {
          label: 'Arrodonir',
        },
        TopUp: {
          label: 'Recarregar',
        },
      },
      Buttons: {
        Save: {
          text: 'Desa',
        },
        Back: {
          text: 'Canviar al compte de prepagament',
        },
        editBack: {
          text: 'Enrere',
        },
      },
      Card: {
        expire: 'Caduca',
        add: 'Afegir targeta',
        charity: 'Donatiu a caritat',
        edit: 'Edita la configuració de la donació',
        editCard: 'Editar targeta',
        delete: 'Eliminar',
        replace: 'Reemplaçar',
        balance: 'Saldo actual:',
        Outbalance: 'Saldo pendent:',
        mmYY: 'MM/AA',
        mm: 'MM',
        yyyy: 'AAAA',
        Pay: {
          text: 'Pagar ara',
        },
        Withdraw: {
          text: 'Retirar fons',
        },
        toast: {
          delete: {
            success: 'Targeta eliminada correctament.',
            error: 'Hi ha hagut un error processant la teva sol·licitud. Intenta-ho de nou.',
          },
          save: {
            success: 'Detalls de la targeta guardats correctament.',
            error: "S'ha produït un error en processar la teva sol·licitud. Intenta-ho de nou.",
          },
          paynow: {
            success: 'Pagament satisfactori.',
            error: "S'ha produït un error en processar la teva sol·licitud. Intenta-ho de nou.",
          },
          edit: {
            success: 'Detalls de la targeta actualitzats correctament.',
            error: "S'ha produït un error en processar la teva sol·licitud. Intenta-ho de nou.",
          },
          replace: {
            success: 'Detalls de la targeta substituïts correctament.',
            error: "S'ha produït un error en processar la teva sol·licitud. Intenta-ho de nou.",
          },
          common: {
            error: "S'ha produït un error en processar la teva sol·licitud. Intenta-ho de nou.",
          },
          amount: 'No hi ha saldo pendent per cobrar.',
          noSwitch: 'Neteja el saldo pendent abans de canviar a un compte de prepagament',
        },
      },
      label: {
        accountFunds: 'Fons del compte',
        rewards: 'Recompenses',
        referralBonus: 'Bonus de referència',
        currentBalance: 'Saldo actual',
        seeMore: 'Veure més',
        message: 'Missatge',
        charityDonation: 'Donatiu a caritat',
        topupAmount: 'Sí, donar',
        note: 'Nota:',
        charity: 'beneficència',
        card: 'targeta',
        donation: 'donatiu',
        giftaid: 'giftaid',
        noThanks: 'No, gràcies',
        cancel: 'Cancel·la',
      },
      error: 'La teva transacció de pagament està per sota del valor mínim de transacció de ##AMOUNT',
    },
    PrePaymentDetail: {
      Title: 'Configuració del pagament',
      SubTitle: 'Compte de prepagament',
      Inputs: {
        Amount: {
          label: 'Import',
          placeHolder: 'Import',
          required: 'Es requereix un import',
          invalid: "L'import ha de ser superior o igual a",
          maxLimit: '',
          number: "L'import ha de ser un número",
        },
        CardNumber: {
          label: 'Número de la targeta',
          placeHolder: '1234  1234  1234  1234',
          required: 'Es requereix el número de targeta',
          invalid: 'Número de targeta no vàlid',
        },
        Expiry: {
          label: 'Caducitat',
          required: 'Es requereix el mes de caducitat',
        },
        Year: {
          label: 'Any',
          required: "Es requereix l'any de caducitat",
        },
        CSC: {
          label: 'CSC',
          label1: '(Codi de seguretat de la targeta)',
          required: 'Es requereix el codi de seguretat de la targeta',
          placeHolder: 'CSC',
          maxlimit: "Introdueix un codi d'almenys 3 dígits.",
        },
        Donation: {
          label: 'Sí, vull fer una donació!',
        },
        RoundUp: {
          label: 'Arrodonir',
        },
        TopUp: {
          label: 'Recarregar',
        },
      },
      Buttons: {
        Save: {
          text: 'Afegir diners',
        },
        Back: {
          text: 'Canviar al compte de crèdit',
          text1: 'Enrere',
        },
        FailBack: {
          text: 'Tornar al compte',
        },
      },
      PrePayemnt: {
        text: {
          currentBalance: 'Saldo actual',
          OutBalance: 'Saldo pendent',
        },
        button: {
          add: 'Afegir diners',
          Withdraw: 'Retirar fons',
          withdrawAccountFunds: 'Retirar fons del compte',
          viewYourRefundRequests: 'Veure sol·licituds de retirada',
        },
      },
      toast: {
        canNotAddMoney:
          'No pot afegir més fons perquè ha arribat al límit màxim de fons que pot tenir en el seu moneder ##COMPANY_BRAND_NAME##.',
        common: {
          error: 'Hi ha hagut un error processant la teva sol·licitud. Intenta-ho de nou.',
        },
        maxLimitForPrePayment: 'Ei! només pots carregar el saldo de la cartera fins a ##MAXAMOUNT.',
        maxLimitReached:
          'Ei! només pots carregar el saldo de la cartera fins a ##MAXAMOUNT. Ara pots afegir un màxim de ##ALLOWEDAMOUNT.',
      },
      label: {
        accountFunds: 'Fons del compte',
        rewards: 'Recompenses',
        referralBonus: 'Bonus de referència',
        currentBalance: 'Saldo actual',
        seeMore: 'Veure més',
        billingAddress: 'Adreça de facturació',
        charityDonation: 'Donatiu a caritat',
        note: 'Nota:',
      },
      donationNote: '""Recarrega" del donatiu amb una quantitat fixa per cada transacció de pagament."',
    },
    PrePaymentWithdraw: {
      Title: 'Configuració del pagament',
      SubTitle: 'Compte de prepagament',
      Inputs: {
        Amount: {
          label: 'Import',
          placeHolder: 'Import',
          required: 'Es requereix un import',
          invalid: "L'import és superior al saldo actual",
        },
        CardNumber: {
          label: 'Número de la targeta',
          placeHolder: '1234  1234  1234  1234',
          required: 'Es requereix el número de targeta',
          invalid: 'Número de targeta no vàlid',
          maxlimit: 'El número de targeta ha de tenir com a màxim 20 dígits',
        },
        Expiry: {
          label: 'Caducitat',
          required: 'Es requereix el mes de caducitat',
        },
        Year: {
          label: 'Any',
          required: "Es requereix l'any de caducitat",
        },
        CSC: {
          label: 'CSC',
          label1: '(Codi de seguretat de la targeta)',
          required: 'Es requereix el codi de seguretat de la targeta',
          placeHolder: 'CSC',
          maxlimit: 'Introdueix un codi de mínim 3 dígits',
        },
      },
      Buttons: {
        Withdraw: {
          text: 'Retirar fons',
        },
        Back: {
          text: 'Canviar al compte de crèdit',
          text1: 'Enrere',
        },
        FailBack: {
          text: 'Tornar al compte',
        },
      },
      PrePayemnt: {
        text: {
          currentBalance: 'Saldo actual',
          OutBalance: 'Saldo pendent',
        },
        button: {
          add: 'Afegir diners',
          Withdraw: 'Retirar fons',
        },
      },
    },
    PaymentSuccess: {
      label: 'Èxit!',
      Successlabel: 'Transacció correcta',
      transactionId: 'Identificador de transacció:',
      amount: 'Import:',
      warningLabel: 'Advertència',
      common: {
        error: 'Hi ha hagut un error processant la teva sol·licitud. Intenta-ho de nou.',
      },
    },
    PaymentFail: {
      label: 'Transacció fallida',
      failureLabel: 'La teva transacció ha fallat. Intenta-ho de nou.',
    },
    PaymentProcess: {
      label: "Espera mentre la teva transacció s'està processant",
    },
    Model: {
      header: 'Canvia el compte',
      DeleteBoxHeader: 'Eliminar targeta',
      WithdrawBoxHeader: 'Retirar fons',
      button: {
        yes: 'Sí',
        no: 'No',
        existingCard: 'Targeta existent',
        newCard: 'Targeta nova',
      },
      messagePrePayment: 'Estàs segur que vols canviar a un compte de prepagament?',
      messageCreditPayment: 'Estàs segur que vols canviar a un compte de crèdit?',
      msgPrePaymentSaveCard:
        'En canviar al compte de prepagament, se suprimiran les dades de la targeta. Estàs segur que vols canviar a un compte de prepagament?',
      DeleteCardMsg: 'Estàs segur que vols eliminar les dades de la targeta?',
      WithdrawMoneyMsg: "Selecciona l'opció de targeta per a retirar diners.",
    },
    charityModal: {
      yesDonate: 'Sí, donar',
    },
    Withdraw: {
      CanNotWithdrawMoneyWhileSessionIsActive:
        'No podeu retirar diners en aquest moment. Si us plau, espereu que finalitzi la sessió de càrrega corrent',
    },
  },
  termsAndCondition: {
    title: 'Termes i condicions',
    note: 'Pàgina en construcció.',
  },
  table: {
    data: {
      noData: "No s'han trobat dades. ",
    },
    pagination: {
      prev: 'Anterior',
      nrev: 'Següent',
      showing: 'Mostrant',
      pageSize: {
        ten: '10',
        fifty: '50',
        hundred: '100',
      },
    },
  },
  history: {
    headerTitle:
      'Històric de càrregues i pagaments - ##COMPANY_BRAND_NAME##: Càrrega ràpida de vehicles elèctrics al Regne Unit',
    title: 'Històric de càrregues i pagaments',
    columns: {
      chargingStation: 'Estació de càrrega',
      date: 'Data',
      address: 'Adreça',
      milesAdded: 'Milles afegides',
      time: 'Temps',
      cost: 'Cost',
      energy: 'Energia',
      action: 'Acció',
      remark: 'Observacions',
      credit: 'Crèdit',
      debit: 'Dèbit',
      closing: 'Tancament',
      transaction: 'Identificador de transacció',
      credit_debit: 'Crèdit/dèbit',
    },
    form: {
      charge: 'Carregant',
      payment: 'Pagament',
      month: 'Mes',
    },
    label: {
      exportAll: 'Genera Rebut',
    },
    button: {
      search: 'Cerca',
      reset: 'Restableix',
    },
    message: {
      selectRecordToDownload: 'Selecciona qualsevol registre per generar un rebut.',
      noChargingHistoryMessage: 'Quan carreguis el teu vehicle elèctric, la teva història de càrrega apareixerà aquí.',
      noPaymentHistoryMessage: 'Quan hagis completat una transacció de pagament, es mostrarà aquí.',
    },
    receipt: {
      title: 'REBUT',
      header: {
        date: 'Data',
        billing_addresss: 'Adreça de facturació',
        note: 'El teu rebut de recàrrega de vehicle elèctric amb ##COMPANY_BRAND_NAME##',
        vat_number: "Número d'IVA",
      },
      content: {
        summary: 'Es mostra un resum de les teves transaccions de càrrega a continuació',
        table: {
          header: {
            station_name: "Nom de l'estació",
            start: 'Inici',
            end: 'Fi',
            usage: 'Ús',
            unit: 'Unitat',
            value: 'Valor',
            transaction_id: 'Identificador de transacció',
            vatRate: "Taxa de l'IVA",
            vat: 'IVA',
          },
        },
        total_before_vat: "Total abans de l'IVA",
        total_vat: 'IVA total',
        vat: 'IVA a',
        total_fee: 'Tarifa total',
        thanks_message: 'Gràcies per carregar a la Xarxa de Càrrega de Vehicles Elèctrics de ##COMPANY_BRAND_NAME##',
        vatReceiptNotGenerated:
          "Encara no s'ha generat el rebut de l'IVA. Intenta-ho de nou 24 hores després que la sessió de càrrega hagi acabat. Si segueixes sense aconseguir-ho, posa't en contacte amb el nostre servei d'atenció al client.",
        vatReceiptNotAssociatedWithAcc:
          "El rebut de l'IVA no s'ha associat al teu compte. Assegura't que utilitzes les credencials correctes per a veure el rebut de l'IVA.",
      },
      footer: {
        title:
          "##COMPANY_BRAND_*NAME## Limited és una empresa registrada a Anglaterra i Gal·les, amb número d'empresa 10484882",
        address: '6 Cedarwood, Crockford Lane, Chineham Park, Basingstoke, Hampshire, RG24 8WD',
        telephone: 'T',
        email: 'E',
        note: "Cita la data de la transacció i l'identificador de transacció en tota la correspondència.",
      },
    },
  },
  loyaltyPoint: {
    header: 'Recompenses - ##COMPANY_BRAND_NAME##: Càrrega ràpida de vehicles elèctrics al Regne Unit',
    title: 'Recompenses',
    note: '*Només pots canviar volts a través de la nostra app.',
    availableVolts: 'Volts disponibles:',
    noDataMessage: 'Quan hagis completat una sessió de càrrega, les transaccions dels teus volts es mostraran aquí.',
    label: {
      chargingStation: 'Estació de càrrega',
      date: 'Data',
      address: 'Adreça',
      credit: 'Crèdit',
      debit: 'Dèbit',
      closing: 'Tancament',
      redeemed: 'Redimits',
      charged: 'Carregat',
      remark: 'Observacions',
      credit_debit: 'Crèdit/dèbit',
      voltsRedeemed: 'Volts redimits',
    },
  },
  withdraw: {
    subTitle: 'Compte de prepagament',
    label: {
      maxWithdrawalBalance: 'Saldo màxim de retirada',
      note: 'Nota:',
      enterAmount: 'Introdueix un import',
      withdrawAmount: 'Import per retirar',
      expires: 'Caduca',
      rewardHeading: 'Introdueix un import que desitgis retirar del teu compte.',
      selectCardToWithdrawAmount: "Selecciona la targeta per a retirar l'import",
      remainingAmount: 'Crèdit restant',
      accountName: 'Nom del compte',
      sortCode: 'Codi de sucursal',
      accountNumber: 'Número de compte',
      bankDetail: 'Dades del banc',
      withdrawAmountNote:
        'Només podràs transferir els fons del teu compte a la targeta de pagament que es va utilitzar per a ingressar fons en el compte.',
      withdrawAmountNote1: 'Tingues en compte que el reemborsament es processarà en un termini de 14 dies.',
      withdrawAmountNote2: 'Cal tenir en compte que la retirada es processarà en un termini de 3 a 5 dies laborables.',
      accountNameNote:
        " Assegura't que el nom del compte bancari coincideix amb el nom del teu compte ##COMPANY_BRAND_NAME##. En cas contrari, la retirada serà rebutjada.",
    },
    placeholder: {
      amount: 'Import',
      accountName: 'Nom del compte',
      sortCode: 'Codi de sucursal',
      accountNumber: 'Número de compte',
    },
    button: {
      back: 'Enrere',
      next: 'Següent',
      raisedRequest: 'Crear una sol·licitud de retirada',
      withdrawMoney: 'Retirar fons',
      proceed: 'Procedir',
    },
    errors: {
      amount: {
        typeError: 'Introdueix un import vàlid',
        moreThan: "L'import ha de ser superior a 0",
      },
      AmountIsGreaterThanwithdrawableAmount: "L'import introduït és superior a l'import que es pot retirar",
      selectCardToWithdrawMoney: 'Seleccioni qualsevol targeta per a retirar diners.',
      canNotSelectCard:
        'El valor de la retirada supera el fons màxim de retirada disponible per a la targeta de pagament seleccionada. Tria una altra targeta.',
    },
  },
  termsAndConditionListing: {
    title: 'Termes i condicions - ##COMPANY_BRAND_NAME##: Càrrega ràpida de vehicles elèctrics al Regne Unit',
    noteDetails: "*L'obligatori ha de ser acceptat per poder utilitzar o registrar un compte.",
    label: {
      referralCode: 'Codi de referència (si en tens un)',
      promotioncode: 'Codi de promoció (si en tens un)',
      termsAndConditions: 'Termes i condicions.',
      welcome: 'Benvingut',
      apply: 'Aplicar',
      note: 'Nota:',
      invalidCode: 'Ups! Codi no vàlid!',
      usedCode: 'Codi ja utilitzat.',
      codeApplied: 'Codi aplicat',
      iAggreeToTermsCondition: 'Accepto',
      reviewUpdatedCondition: "Revisa i accepta els termes i condicions actualitzats que s'indiquen a continuació.",
      name: 'Nom',
      accepted: 'Acceptat',
    },
    button: {
      next: 'Següent',
      cancel: 'Cancel·la',
      accept: 'Accepta',
    },
    errors: {
      acceptTermCondition: 'Accepta els Termes i Condicions *obligatoris per a continuar.',
      invalidReferralCode:
        'El codi de referència ##REFERRAL_CODE## introduït no és vàlid. Corregeix-lo (el text és sensible a majúscules i minúscules) o elimina el codi de referència per continuar.',
      invalidPromotionCode:
        'El codi de promoció ##PROMO_CODE## introduït no és vàlid. Corregeix (el valor del text distingeix majúscules i minúscules) o elimina el Codi de promoció per continuar.',
      referralNotAllowed: 'Els codis de referència no es poden utilitzar amb aquesta promoció',
    },
    message: {
      refferalCode: {
        first: 'El codi de referència',
        second:
          'introduït no és vàlid. Corregeix-lo (el text és sensible a majúscules i minúscules) o elimina el codi de referència per continuar.',
      },
      promotionCode: {
        first: 'El codi de promoció',
        second:
          'introduït no és vàlid. Corregeix (el valor del text distingeix majúscules i minúscules) o elimina el Codi de promoció per continuar.',
      },
    },
  },
  cookiePolicy: {
    text: "Aquest lloc web utilitza cookies per millorar l'experiència de l'usuari. Utilitzant el nostre web, reconeixes que has llegit i entès la nostra",
    action: 'Política de cookies',
    iAccept: 'Accepto',
  },
  openSource: {
    title: 'Crèdits',
  },
  refundRequest: {
    title: 'Sol·licituds de retirada - ##COMPANY_BRAND_NAME##: Càrrega ràpida de vehicles elèctrics al Regne Unit',
    header: 'Sol·licituds de retirada',
    label: {
      name: 'Nom',
      requestedDate: 'Data de sol·licitud',
      refundRequested: 'Retirada sol·licitada',
      account: 'Compte (Per a retirar crèdits)',
      remarks: 'Observacions',
      transactionID: 'Identificador de sol·licitud',
      processing: 'Processant',
      cancelled: 'Cancel·lat',
      declined: 'Rebutjat',
      complete: 'Completar',
      date: 'Data',
      status: 'Estat',
      cancelRequest: 'Sol·licitud de cancel·lació',
      action: 'Acció',
    },
    button: {
      search: 'Cerca',
      reset: 'Restableix',
      yes: 'Sí',
      no: 'No',
    },
    placeholder: {
      selectStatus: "Selecciona l'estat",
    },
    message: {
      confirmCancelRequest: 'Estàs segur que vols cancel·lar la sol·licitud de retirada?',
    },
    errors: {
      sortCode: {
        min: "Introdueix un codi de sucursal d'almenys 6 dígits.",
        valid: 'Introdueix un codi de sucursal vàlid.',
      },
      accountNumber: {
        min: 'Introdueix el número de compte mínim de 8 dígits.',
      },
    },
  },
  footer: {
    version: 'Versió',
    copyRightText: 'Tots els drets reservats',
  },
  rfidCards: {
    headerTitle: 'Targetes RFID - ##COMPANY_BRAND_NAME##: Càrrega ràpida de vehicles elèctrics al Regne Unit',
    title: 'Targetes RFID',
    label: {
      public_card_id: 'Número(s) de targeta',
      quantity: 'Introdueix una quantitat',
      action: 'Acció',
      delete: 'Eliminar',
      cardNumber: 'Introdueix el número de targeta',
      rfid_card_unit_price: "El preu unitari d'una targeta RFID és",
      rfid_card_unit_price_label: 'Per targeta RFID',
    },
    placeholder: {
      quantity: 'Introdueix una quantitat',
      public_card_id: 'Introdueix el número de targeta',
    },
    noDataMessage: 'Cap targeta RFID activa en aquest moment',
    requestProcessDaysMessage: 'La teva sol·licitud de targeta RFID es processarà en ##DAYS## dies laborables.',
    modal: {
      header: 'Sol·licitud de targeta RFID',
      body: "Introdueix el nombre de targetes RFID desitjades. Les targetes s'enviaran a l'adreça que has configurat al teu compte. En rebre les targetes, hauràs d'activar-les al teu compte.",
    },
    addCardModal: {
      header: 'Afegir targeta RFID',
    },
    button: {
      proceed: 'Procedir',
      cancel: 'Cancel·la',
      addNewCard: 'Afegir targeta RFID',
      requestCard: 'Sol·licita una nova targeta RFID',
      delete: 'Eliminar',
      action: 'Acció',
    },
    errors: {
      quantity: {
        min: 'El quantitat ha de ser superior a zero',
      },
      public_card_id: {
        valid:
          "No s'ha reconegut l'identificador de la targeta introduïda. Assegura't que no s'han inclòs espais/caràcters especials a l'identificador.",
      },
    },
    message: {
      here: 'aquí.',
      deleteConfirmMessage:
        'En eliminar aquesta targeta, quedarà permanentment desactivada i no es podrà tornar a registrar. Segur que vols continuar?',
    },
  },
  giftAidmessage: "missatge de l'ajuda per a donatius",
  creditAccountDonationNote:
    'En seleccionar ""Sí"", es farà una donació de ##AMOUNT## a totes les futures transaccions. Pots canviar la teva preferència de donació en qualsevol moment fent clic al botó ""Edita la configuració de la donació"".',
  PrepaymentAccountDonationNote:
    'En seleccionar ""Sí"", es farà una donació de ##AMOUNT## en aquesta transacció. Pots canviar la teva preferència de donació en qualsevol moment fent clic al botó ""Edita la configuració de la donació"".',
  aboutUs: {
    listTitle: {
      openSourcelicenses: 'Llicències de codi obert',
    },
  },
  price_breakdown_details: {
    detailed_breakup: 'Desglossament detallat',
    total_cost: 'Cost total',
    conversion_rate: 'Taxa de conversió',
  },
};
