import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { getAPIKey } from '../../services/login/login-service';
import {
  checkPromotionCode,
  checkReferralCode,
  getTermsAndConditionListing,
  saveTermsAndCondition,
} from '../../services/login/loginApi-service';
import { getProfile } from '../../services/user/user-service';
import { logout, setCurrencyDetails } from '../../store/actions/app.Actions';
import { StandardResponse } from '../../models/standard-response';
import { AppState } from '../../store/state/app.state';
import { IAcceptedTermsAndConditionsModalState } from '../../types/stateTypes';
import { ITermsConditionData, IReferralDetail, ITermsAndConditionsList, IPromotionDetail } from '../../models/login';
import { IConfiguration, IUserData as IUserProfileData } from '../../models/profile';
import { Common } from '../../utils/constants';
import { ActionType } from '../../store/actions/Helpers';
import { AppDispatch } from '../../store';

export const TermsAndConditionListingHook = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userData = useSelector((state: AppState) => state.UserData);

  const [termsAndConditionList, setTermsAndConditionList] = useState<Array<ITermsConditionData>>([]);
  const [promotionCode, setPromotionCode] = useState<string>('');
  const [referralCode, setReferralCode] = useState<string>(
    location && location.state && location.state.referralCode ? location.state.referralCode : '',
  );
  const [isValidPromotionCode, setIsValidPromotionCode] = useState<boolean | null>(null);
  const [isValidReferralCode, setIsValidReferralCode] = useState<boolean | null>(null);
  const [requiredTermsAndCondition, setRequiredTermsAndCondition] = useState<Array<number>>([]);
  const [selectedTermsAndCondition, setSelectedTermsAndCondition] = useState<Array<number>>([]);
  const [isReferralDisabled, setIsReferralDisabled] = useState<boolean>(false);
  const [isPackagePromotion, setIsPackagePromotion] = useState<boolean>(false);
  const [model, setModel] = useState<IAcceptedTermsAndConditionsModalState>({
    body: '',
    header: '',
    showModal: false,
    id: null,
  });
  const [promotionCodeClass, setPromotionCodeClass] = useState<string>('');
  const [referralCodeClass, setReferralCodeClass] = useState<string>('');
  const [referralMessage, setReferralMessage] = useState<string>('');
  const [isReferralBonusOff, setIsReferralBonusOff] = useState<boolean>(false);
  const [isUsedPromotionCode, setIsUsedPromotionCode] = useState<boolean>(false);

  const fetchData = async () => {
    await getTermsConditionListing();
    const isReferralDisabledConfig = await getConfigData(Common.ConfigurationKey.DISABLE_REFERRAL);
    if (isReferralDisabledConfig.length > 0) {
      setIsReferralDisabled(isReferralDisabledConfig[0].value ? Boolean(isReferralDisabledConfig[0].value) : false);
    }
    const referralBonusOffConfig = await getConfigData(Common.ConfigurationKey.REFERRAL_BONUS);
    if (referralBonusOffConfig.length > 0) {
      const referralBonus = referralBonusOffConfig[0].value ? referralBonusOffConfig[0].value : '';

      if (referralBonus.trim().toLowerCase() === 'off') {
        setIsReferralBonusOff(true);
        setReferralCode('');
      } else {
        setIsReferralBonusOff(false);
      }
    }

    if (referralCode !== undefined && referralCode !== '') {
      checkReferralCodeValid();
    }
  };

  const getConfigData = async (code: string) => {
    let configData: Array<IConfiguration> = [];
    await getAPIKey({ code })
      .then(async (res) => {
        configData = res.data;
      })
      .catch((m) => {
        toast.error(m.toString());
      });
    return configData;
  };

  const getTermsConditionListing = async () => {
    const requiredTermsAndConditionIds: Array<number> = [];
    let TermsConditionList: Array<ITermsConditionData> = [];
    await getTermsAndConditionListing()
      .then(async (res: StandardResponse<Array<ITermsConditionData>>) => {
        if (userData && userData.has_saved_terms) {
          if (_.isArray(res.Data)) {
            TermsConditionList = res.Data.filter(
              (x) => x.accepted === null || (x.mandatory === true && x.accepted === false),
            );
          }
        } else {
          TermsConditionList = res.Data;
        }
        if (_.isArray(TermsConditionList)) {
          const requiredTermsCondition = TermsConditionList.filter((x) => x.mandatory === true);
          if (_.isArray(requiredTermsCondition)) {
            for (const data of requiredTermsCondition) {
              requiredTermsAndConditionIds.push(data.id);
            }
          }
        }
        setTermsAndConditionList(TermsConditionList);
        setRequiredTermsAndCondition(requiredTermsAndConditionIds);
      })
      .catch((m) => {
        toast.error(m.toString());
      });
  };

  const checkReferralCodeValid = async () => {
    if (referralCode !== '') {
      await checkReferralCode(referralCode)
        .then(async (res: StandardResponse<IReferralDetail>) => {
          if (res.Data && res.Data !== undefined) {
            setIsValidReferralCode(true);
          } else {
            setIsValidReferralCode(false);
          }
          setReferralMessage(res.Data.referral_message);
          if (
            promotionCode !== '' &&
            isValidReferralCode !== null &&
            isValidReferralCode &&
            isValidPromotionCode !== null &&
            isValidPromotionCode &&
            isPackagePromotion !== null &&
            isPackagePromotion &&
            isReferralDisabled !== null &&
            isReferralDisabled
          ) {
            toast.error(t('termsAndConditionListing.errors.referralNotAllowed'));
            setReferralCode('');
            setIsValidReferralCode(null);
          }
        })
        .catch((m) => {
          if (m.response && m.response.data.message) {
            setIsValidReferralCode(false);
          } else {
            toast.error(m.toString());
          }
        });
    } else {
      setIsValidReferralCode(null);
    }
  };

  const checkPromotionCodeValid = async () => {
    if (promotionCode !== '') {
      await checkPromotionCode(promotionCode)
        .then(async (res: StandardResponse<IPromotionDetail>) => {
          if (res.Data && res.Data !== undefined) {
            setIsValidPromotionCode(true);
          } else {
            setIsValidPromotionCode(false);
          }

          setIsPackagePromotion(res.Data.is_package_promotion);
          if (
            referralCode !== '' &&
            isValidReferralCode !== null &&
            isValidReferralCode &&
            isValidPromotionCode !== null &&
            isValidPromotionCode &&
            isPackagePromotion !== null &&
            isPackagePromotion &&
            isReferralDisabled !== null &&
            isReferralDisabled
          ) {
            toast.error(t('termsAndConditionListing.errors.referralNotAllowed'));
            setReferralCode('');
            setIsValidPromotionCode(null);
          }
        })
        .catch((m) => {
          if (m.response && m.response.data.message) {
            if (!m.response.data.message.includes(promotionCode)) {
              setIsValidPromotionCode(false);
              setReferralMessage('');
              setIsUsedPromotionCode(true);
            } else {
              setIsValidPromotionCode(false);
              setReferralMessage('');
              setIsUsedPromotionCode(false);
            }
            toast.error(m.response.data.message);
          } else {
            toast.error(m.toString());
          }
        });
    } else {
      setIsValidPromotionCode(null);
      setIsUsedPromotionCode(false);
    }
  };

  const handleSubmit = async () => {
    if (isValidReferralCode !== null && !isValidReferralCode) {
      const message = () => {
        return (
          <>
            <span>
              {t('termsAndConditionListing.message.refferalCode.first')}{' '}
              <span className="themeTextColor">{referralCode}</span>
              {t('termsAndConditionListing.message.refferalCode.second')}
            </span>
          </>
        );
      };
      toast.error(message);
    } else if (isValidPromotionCode !== null && !isValidPromotionCode) {
      const message = () => {
        return (
          <>
            <span>
              {t('termsAndConditionListing.message.promotionCode.first')}{' '}
              <span className="themeTextColor">{promotionCode}</span>{' '}
              {t('termsAndConditionListing.message.promotionCode.second')}
            </span>
          </>
        );
      };
      toast.error(message);
    } else {
      let acceptedAllTermsCondition = true;
      for (const data of requiredTermsAndCondition) {
        if (!selectedTermsAndCondition.includes(data)) {
          acceptedAllTermsCondition = false;
        }
      }
      if (acceptedAllTermsCondition) {
        const terms_conditions: ITermsAndConditionsList[] = [];
        for (const data of termsAndConditionList) {
          terms_conditions.push({
            id: data.id,
            accepted: selectedTermsAndCondition.includes(data.id),
          });
        }
        const input = { terms_conditions };
        if (isValidPromotionCode) {
          Object.assign(input, {
            promotion_code: _.trimEnd(promotionCode),
          });
        }
        if (isValidReferralCode !== null && isValidReferralCode) {
          Object.assign(input, {
            referral_code: _.trimEnd(referralCode),
          });
        }
        await saveTermsAndCondition(input)
          .then(async () => {
            if (referralMessage !== '' && isValidReferralCode !== null && isValidReferralCode) {
              toast.success(referralMessage);
            }

            getProfile()
              .then((userDetail: StandardResponse<IUserProfileData>) => {
                if (
                  userDetail &&
                  userDetail.Data &&
                  userDetail.Data.status &&
                  userDetail.Data.status.code === Common.UserStatus.CANCELLED
                ) {
                  dispatch(logout()).then(() => {
                    toast.error(t('login.toast.cancelled'), {
                      autoClose: 15000,
                    });
                  });
                } else {
                  dispatch(setCurrencyDetails(userDetail.Data?.default_currency));
                  dispatch({
                    type: ActionType.FETCH_USER_DETAILS,
                    payload: userDetail.Data,
                  });
                  if (userDetail && userDetail.Data.terms_and_condition_required) {
                    navigate('/TermsAndCondition');
                  } else if (userDetail && userDetail.Data.is_profile_verified === false) {
                    navigate('/profile?redirect=true');
                  } else if (userDetail && userDetail.Data.is_profile_verified === true) {
                    if (!localStorage.getItem(`${Common.MARKETING_CONSENT}-${userDetail.Data.id}`)) {
                      navigate('/profile');
                    } else if (userDetail && userDetail.Data.payment_type === null) {
                      navigate('/payment');
                    } else {
                      navigate('/');
                    }
                  }
                  if (isValidPromotionCode) {
                    toast.info(t('login.toast.reminderMessage'), {
                      autoClose: 15000,
                    });
                  }
                }
              })
              .catch(() => {
                dispatch(logout());
                toast.error(t('login.toast.error'));
              });
          })
          .catch((m) => {
            if (m.response && m.response.message) {
              toast.error(m.response.message);
            } else {
              toast.error(m.toString());
            }
          });
      } else {
        toast.error(t('termsAndConditionListing.errors.acceptTermCondition'));
      }
    }
  };

  const acceptTermsCondition = (id: number) => {
    const newSelectedTermsAndCondition = selectedTermsAndCondition;
    newSelectedTermsAndCondition.push(id);
    setSelectedTermsAndCondition(newSelectedTermsAndCondition);

    setModel({
      body: '',
      header: '',
      id: null,
      showModal: false,
    });
  };

  const handleModel = (body: string, header: string, showModal: boolean | null, id: number | null) => {
    setModel({
      body: body,
      header: header,
      showModal: Boolean(showModal),
      id: id,
    });
  };

  const handleCheckChange = (data: ITermsConditionData) => {
    if (selectedTermsAndCondition.includes(data.id)) {
      const array = selectedTermsAndCondition;
      const index = array.indexOf(data.id);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedTermsAndCondition(array);
      }
    } else {
      handleModel(data.content, data.name, true, data.id);
    }
  };

  const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const promotionCodeValidity = isValidPromotionCode ? isValidPromotionCode : null;
    const referralCodeValidity = isValidReferralCode ? isValidReferralCode : null;
    if (e.target.name === 'promotionCode') {
      setPromotionCodeClass('value-exists');
      setIsValidPromotionCode(promotionCodeValidity);
    } else if (e.target.name === 'referralCode') {
      setReferralCodeClass('value-exists');
      setIsValidReferralCode(referralCodeValidity);
    }
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'promotionCode' && e.target.value === '') {
      setPromotionCodeClass('');
    } else if (e.target.name === 'referralCode' && e.target.value === '') {
      setReferralCodeClass('');
    }
  };

  return {
    fetchData,
    handleFocus,
    handleBlur,
    checkReferralCodeValid,
    handleCheckChange,
    handleModel,
    handleSubmit,
    checkPromotionCodeValid,
    getTermsConditionListing,
    termsAndConditionList,
    promotionCode,
    referralCode,
    isValidPromotionCode,
    isValidReferralCode,
    requiredTermsAndCondition,
    selectedTermsAndCondition,
    isReferralDisabled,
    isPackagePromotion,
    model,
    promotionCodeClass,
    referralCodeClass,
    referralMessage,
    isReferralBonusOff,
    isUsedPromotionCode,
    acceptTermsCondition,
    setPromotionCode,
    setReferralCode,
    setIsValidPromotionCode,
    setIsValidReferralCode,
    setIsPackagePromotion,
    setIsReferralDisabled,
    setReferralMessage,
    setSelectedTermsAndCondition,
    setRequiredTermsAndCondition,
  };
};
