import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useReferral = () => {
  const params = useParams();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    let myParam = params?.id;

    if (myParam == null) {
      myParam = '';
    }
    let baseURL = `${window.location.origin}/sign-up?code=${myParam}`;
    let url = baseURL;

    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod')) {
      url = `instavolt-dev://referral?code=${myParam}`;
      baseURL = 'https://apps.apple.com/gb/app/instavolt/id1516767939';
    } else if (userAgent.includes('android')) {
      if (myParam !== '') {
        url = `instavolt-dev://referral?code=${myParam}`;
        baseURL = `https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id%3dcom.app.instavolt%26referrer%3d${myParam}`;
      } else {
        url = `instavolt-dev://referral?code=${myParam}`;
        baseURL = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id%3dcom.app.instavolt';
      }
    }

    share(url, baseURL);
  };

  const share = (url: string, baseURL: string) => {
    window.location.href = url;
    setTimeout(() => {
      if (!document.hidden) {
        window.location.href = baseURL;
      }
    }, 10000);
  };
  return {
    fetchData,
    share,
  };
};
