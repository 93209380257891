import React from 'react';
import { useEffect } from 'react';
import { getDNAPaymentScript } from '../../utils/Payment';
import Spinner from '../../layout/spinner';
import { companyNameReplace } from '../../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { DNAPaymentsType } from '../../models/payment';
declare global {
  interface Window {
    DNAPayments: DNAPaymentsType;
  }
}

function getPaymentData(
  access_token: string,
  accountId: string,
  invoiceId: string,
  terminal: string,
  amount: number,
  currency: string,
  language: string,
  transactionType: string,
  donationAmount: number,
) {
  return {
    paymentSettings: {
      terminalId: terminal,
      returnUrl: `${window.location.origin}${window.location.pathname}?success=true&iid=${invoiceId}`,
      failureReturnUrl: `${window.location.origin}${window.location.pathname}?success=false&iid=${invoiceId}`,
      callbackUrl: `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/driver/payment/dna-webhook/${invoiceId}`,
      failureCallbackUrl: `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/driver/payment/dna-webhook/${invoiceId}`,
      cscMatrix: 22,
      paMatrix: 3,
    },
    customerDetails: {
      accountDetails: {
        accountId: accountId,
      },
    },
    periodic: {
      periodicType: 'ucof',
    },
    invoiceId,
    amount,
    auth: { access_token: access_token },
    description: companyNameReplace(t('Payment.description')),
    language: language || 'en',
    currency,
    transactionType,
    donationAmount,
  };
}

const PaymentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location && location.search.includes('success=false&redirect=true')) {
      navigate(`/payment${location.search}`);
    } else if (location && location.search.includes('success=false')) {
      navigate(`/payment${location.search}`);
    }

    const params = new URLSearchParams(window.location.search);
    const isTestMode =
      params.get('isTestMode') === undefined ||
      params.get('isTestMode') === null ||
      params.get('isTestMode') === 'true';

    document.getElementById('loading')?.classList.remove('spinner-disable');
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = getDNAPaymentScript(isTestMode);
    script.nonce = 'ils+vsV+OAN1z2QUGBzoyQ==';
    script.async = true;
    script.onload = () => openPaymentPage(params, isTestMode);
    script.onerror = () => {
      window.location.href = `${window.location.origin}${window.location.pathname}?success=false&errorcode=payment_script_load_fail&message=unable%20to%20load%20payment%20script`;
    };
    if ((params.get('success') || '').length === 0) {
      document.body.appendChild(script);
    }
  }, [location, navigate]);

  return (
    <>
      <div>
        <Spinner />
      </div>
    </>
  );
};

const openPaymentPage = (params, isTestMode: boolean) => {
  const isEnableDonation = params.isEnableDonation === 'true';
  const donationAmount = params.donationAmount ? Number(params.donationAmount) : 0;
  const config = {
    autoRedirectDelayInMs: 10000,
    scopes: {
      allowHosted: true,
    },
    paymentMethods: [
      {
        name: window.DNAPayments.paymentMethods.BankCard,
      },
    ],
    isTestMode: isTestMode,
    paymentTimeoutInSeconds: 900,
    isEnableDonation,
    locale: {
      targetLocale: params.targetLocale || 'en_GB',
    },
  };

  window.DNAPayments.configure(config);

  window.DNAPayments.openPaymentPage(
    getPaymentData(
      params.accessToken,
      params.accountId,
      params.invoiceId,
      params.terminal,
      +params.amount,
      params.currency,
      params.language,
      params.transactionType,
      donationAmount,
    ),
  );
};

export default PaymentPage;
