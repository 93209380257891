export interface StandardResponse<T> {
  Status: number;
  Data: T;
  Messages: string;
  Exception: string;
}

export function createStandardResponse<T>(
  status: number,
  data: T,
  mess: string,
  exception: string,
): StandardResponse<T> {
  return {
    Status: status,
    Data: data,
    Messages: mess,
    Exception: exception,
  };
}
