export default {
  route: {
    dashboard: 'Arbela',
    article: 'Artikuluak',
    stationTitle: 'Geltokiaren xehetasunak: ##COMPANY_BRAND_NAME## - IE karga azkarra (EB)',
    or: 'EDO',
  },
  month: {
    jan: 'Urt',
    feb: 'Ots',
    mar: 'Mar',
    apr: 'Apr',
    may: 'Mai',
    jun: 'Eka',
    jul: 'Uzt',
    aug: 'Abu',
    sep: 'Ira',
    oct: 'Urr',
    nov: 'Aza',
    dec: 'Abe',
  },
  error: {
    accessDenied: 'Sarbidea ukatu da',
    somethingWrong: 'Zerbait ez da ongi atera!',
    notFound: 'Ez da orrialdea aurkitu',
  },
  navbar: {
    title: '##COMPANY_BRAND_NAME##',
    logOut: 'Saioa itxi',
    myProfile: 'Nire profila',
    termsConditions: 'Terminoak eta baldintzak',
    menu: {
      dashboard: 'Arbela',
      paymentConfiguration: 'Ordainketa konfigurazioa',
      chargingPaymentHistory: 'Kobrantza eta ordainketen historia',
      rewards: 'Sariak',
      rfidCards: 'RFID txartelak',
    },
  },
  login: {
    title: 'Saioa hasi - ##COMPANY_BRAND_NAME## : IE karga azkarra (EB)',
    header: 'Ongi etorri',
    form: {
      email: 'Helbide elektronikoaren identifikazioa',
      password: 'Pasahitza',
    },
    placeholder: {
      region: 'Hautatu eskualdea',
      language: 'Aukeratu hizkuntza',
    },
    button: {
      submit: 'Saioa hasi',
      forgotPassword: 'Pasahitza ahaztu duzu?',
      signup: 'Erregistratu',
      signin: 'Hasi saioa',
    },
    label: {
      logo: '##COMPANY_BRAND_NAME##',
      thirdPartyLogin: 'Edo hasi saioa honekin:',
      rememberMe: 'Nire kontua gogoratu',
      newAccount: 'Ez duzu konturik?',
      facebook: 'Hasi saioa Facebook-ekin',
      google: 'Hasi saioa Google-rekin',
      apple: 'Hasi saioa Apple-ekin',
      copyright: 'Copyright ##COMPANY_BRAND_NAME## Ltd',
    },
    toast: {
      success: 'Gidariaren saioa ongi hasi da.',
      inValid: 'Sartu zure e-posta eta pasahitz zuzena.',
      error: 'Errore bat egon da zure eskaera prozesatzean, saiatu geroago.',
      cancelled: 'Zure kontua baliogabetzen ari da, beraz, ezingo duzu ekintzarik gauzatu',
      maximumAttempt: 'Saialdi-kopuru maximora iritsi zara. Berriz saiatu geroago.',
      reminderMessage:
        'Mesedez, gehitu ordaintzeko txartel bat zure kreditu-kontuan edo gehitu dirua aurreordainketa-kontuko txartelean sustapen-kode honi lotutako ordainketa aktibatzeko.',
    },
    errors: {
      inValidEmail: 'Helbide elektronikoa ez da baliozkoa.',
    },
  },
  forgot: {
    title: 'Pasahitza ahaztu zait - ##COMPANY_BRAND_NAME## : IE karga azkarra (EB)',
    resetTitle: 'Berrezarri pasahitza - ##COMPANY_BRAND_NAME## : IE karga azkarra (EB)',
    header: 'Pasahitza ahaztu duzu?',
    subHeader: 'Sartu behean zure helbide elektronikoa eta pasahitza berrezartzeko mezua bidaliko dizugu',
    resetHeader: 'Berrezarri pasahitza.',
    resetSubHeader: 'Sartu postaz bidali dizugun kodea eta berrezarri zure pasahitza.',
    supportNumber: 'Gure laguntza-zenbakia',
    button: {
      back: 'Atzera',
      submit: 'Berrezarri nire pasahitza',
      save: 'Pasahitz berria gorde',
    },
    form: {
      email: 'Helbide elektronikoa',
      code: 'Kodea',
      password: 'Pasahitza',
      confirmPassword: 'Berretsi pasahitza',
      message:
        'Baliozko kontu bat baduzu, kode bat jaso beharko zenuke posta elektronikoz. Kontuan izan kontu federatu bat duen ##COMPANY_BRAND_NAME## enpresaren kontu batentzat egin baduzu erregistroa, esaterako Apple, Google edo Facebook, ezingo duzula berrezarri pasahitza hirugarren baten zerbitzuak kudeatzen duelako hori.  Laguntza gehiago behar izanez gero, jarri harremanetan gurekin.',
    },
    toast: {
      success: 'Zure pasahitza arazorik gabe aldatu da.',
      error: 'Errore bat egon da zure eskaera prozesatzean, saiatu geroago.',
      inValidEmailAddress: 'Helbide elektronikoa ez da baliozkoa.',
      invalidCode: 'Hara! Kode baliogabea duzu.',
    },
    errors: {
      passwordFormat:
        'Pasahitzak 10 karaktere edo gehiago izan behar ditu, letra maiuskula, letra minuskula, karaktere berezi eta zenbaki banarekin.',
      confirmPasswordMatch: 'Berresteko pasahitzak bat etorri behar du sartu duzun pasahitz berriarekin.',
      verificationCodeFormat: 'Sartu 6 digituko egiaztatze-kodea.',
    },
  },
  dashboard: {
    title: 'Arbela - ##COMPANY_BRAND_NAME## : IE karga azkarra (EB)',
    header: 'Arbela',
    premiumSubscription:
      '##COMPANY_BRAND_NAME## premium zerbitzua du. Hautatu harpidetzea 3 hilabeteko doako zerbitzuaren abantailez gozatzeko.',
  },
  permission: {
    createArticle: 'Artikulu bat gehitu',
    editArticle: 'Artikulua editatu',
    save: 'Gorde',
    confirm: 'Ziur zaude?',
    cancel: 'Ezeztatu',
    update: 'Eguneratu',
  },
  profile: {
    title: 'Profila - ##COMPANY_BRAND_NAME## : IE karga azkarra (EB)',
    header: 'Profila',
    addresses: 'Helbideak',
    personalDetails: {
      header: 'Datu pertsonalak',
      label: {
        firstName: 'Izena',
        lastName: 'Abizena',
        email: 'Helbide elektronikoaren identifikazioa',
        address: 'Helbidea',
        billingAddress: 'Fakturazio helbidea',
        postcode: 'Posta kodea',
        samePersonalAddress: 'Helbide pertsonala bezala',
      },
      placeholder: {
        firstName: 'Izena',
        lastName: 'Abizena',
        email: 'Helbide elektronikoaren identifikazioa',
        address: 'Sartu zure helbidea',
        billingAddress: 'Fakturazio helbidea',
        postcode: 'Posta kodea',
        searchAddress: 'Hasi zure helbidea idazten bilatzeko',
      },
      button: {
        save: 'Gorde',
        cancel: 'Ezeztatu',
        yes: 'Bai',
        no: 'Ez',
      },
      errors: {
        email: {
          email: 'Helbide elektronikoa ez da baliozkoa.',
        },
        first_name: {
          matches: 'Izena ez da baliozkoa.',
        },
        last_name: {
          matches: 'Abizena ez da baliozkoa.',
        },
        address: {
          address: '##MIN_LENGTH## karaktere behar dira gutxienez',
          invalid: 'Helbidea ez da baliozkoa',
          minLine: 'Sartu duzun helbideak ez ditu baliozkoak izateko gutxieneko helbide-lerroak betetzen.',
        },
        billing_Address: {
          billing_Address: '##MIN_LENGTH## karaktere behar dira gutxienez',
          invalid: 'Fakturazio helbidea ez da baliozkoa.',
          minLine: 'Sartu duzun helbideak ez ditu baliozkoak izateko gutxieneko helbide-lerroak betetzen.',
        },
      },
      model: {
        header: 'Bilatu helbidea',
        address: 'Idatzi helbidea',
        suggested_address: 'Iradokitako helbideak',
        no_data_found: 'Ez da aurkitu helbiderik',
        no_address_found: 'Ez da aurkitu helbiderik.',
        button: {
          ok: 'Ados',
          cancel: 'Ezeztatu',
          close: 'Itxi',
        },
        toast: {
          invalidAddress: 'Posta-kode edo helbide baliogabe bat sartu da.',
        },
      },
    },
    addressDetails: {
      header: 'Helbidea',
      label: {
        address1: 'Helbidea',
        address2: 'Helbidearen 2. lerroa',
        city: 'Hiria',
        county: 'Konderria',
        paymentPostcode: 'Posta kodea',
      },
      placeholder: {
        address1: 'Helbidea',
        address2: 'Helbidearen 2. lerroa',
        city: 'Hiria',
        county: 'Konderria',
      },
    },
    billingAddressDetails: {
      header: 'Fakturazio helbidea',
      label: {
        billingaddress1: 'Helbidea',
        billingaddress2: 'Helbidearen 2. lerroa',
        billingcity: 'Hiria',
        billingcounty: 'Konderria',
        samePersonalAddress: 'Helbide pertsonal bezala',
      },
      placeholder: {
        billingaddress1: 'Helbidea',
        billingaddress2: 'Helbidearen 2. lerroa',
        billingcity: 'Hiria',
        billingcounty: 'Konderria',
      },
    },
    marketingPreferenceDetails: {
      header: 'Marketineko lehentasunak',
      label: {
        receivePreference: 'Jaso honen marketineko jakinarazpenak: ##COMPANY_BRAND_NAME##',
        yes: 'Bai',
        no: 'Ez',
      },
      message: 'Nahi al duzu jasotzea ##COMPANY_BRAND_NAME##-ren marketineko jakinarazpenak?',
    },
    langPreference: 'Hizkuntza lehenetsia',
    changePassword: {
      header: 'Pasahitza',
      toast: {
        success: 'Zure pasahitza arazorik gabe aldatu da.',
        error: 'Hara! Oraingo pasahitza ez da zuzena.',
        commonError: 'Errore bat gertatu da zure eskaera prozesatzean, saiatu geroago.',
      },
      button: {
        update: 'Eguneratu',
      },
      label: {
        current_password: 'Oraingo pasahitza',
        new_password: 'Pasahitz berria',
        confirm_password: 'Berretsi pasahitza',
      },
      placeholder: {
        current_password: 'Oraingo pasahitza',
        new_password: 'Pasahitz berria',
        confirm_password: 'Berretsi pasahitza',
      },
      errors: {
        passwordFormat:
          'Pasahitzak 10 karaktere edo gehiago izan behar ditu, letra maiuskula, letra minuskula, karaktere berezi eta zenbaki banarekin.',
        confirmPasswordMatch: 'Berretsitako pasahitzak bat etorri behar du sartu duzun pasahitz berriarekin.',
      },
    },
    cancelAccount: {
      header: 'Kontua ezabatu',
      note1:
        'Nahi duzunean ezabatu dezakezu zure kontua. ##COMPANY_BRAND_NAME##k zure datu guztiak ezabatuko ditu gure sistematik.',
      note2: 'Zure kontua ezabatzeko.',
      clickHere: 'Sakatu hemen',
      toast: {
        success:
          'Sentitzen dugu zu joaten ikustea. Zure kontua ezabatzeko prozesua hasi dugu, eta posta elektronikoz jakinaraziko dizugu prozesua amaitutakoan.',
        failed: 'Errore bat gertatu da zure eskaera prozesatzean, saiatu geroago.',
      },
      model: {
        title: 'Kontua ezabatu',
        body: 'Zure kontua ezabatzeak zure datu guztiak ezabatuko ditu gure sisteman. Hala ere jarraitu nahi al duzu?',
        button: {
          yes: 'Bai',
          no: 'Ez',
        },
      },
    },
    limitExceedAddressError: 'Ezin da aurkitu helbidea une honetan',
  },
  signup: {
    header: 'Sortu zure kontua honekin',
    title: 'Izena eman - ##COMPANY_BRAND_NAME## : IE karga azkarra (EB)',
    form: {
      firstName: 'Izena',
      lastName: 'Abizena',
      email: 'Helbide elektronikoa',
      password: 'Pasahitza',
      referralCode: 'Erreferentzia-kodea',
      code: 'Kodea',
      terms: 'Onartzen dut',
      condition: 'Terminoak eta baldintzak.',
    },
    button: {
      submit: 'Erregistratu',
      signin: 'Hasi saioa',
      login: 'Saioa hasi',
      back: 'Atzera',
      submitOtp: 'Bidali',
      resend: 'Kodea berriro bidali?',
    },
    label: {
      continueWith: 'Edo jarraitu honekin',
      accountNew: 'Ba al duzu dagoeneko kontu bat?',
      otpConfirmationTitle1: 'E-posta bat bidali berri dizugu',
      otpConfirmationTitle2: 'Saioa hasteko, sakatu hona bidalitako kodea',
      otpNonVerifiedEmailNote1: 'Zure kontua oraindik ez da egiaztatu. Sakatu hona bidalitako kodea',
      otpNonVerifiedEmailNote2: 'aurrera jarraitzeko.',
      resendTitle: 'Ez duzu jaso e-posta?',
      resend: 'Berriro bidali',
      email: 'Helbide elektronikoa',
      instavolt: '##COMPANY_BRAND_NAME##',
      modalMessage:
        '##EMAIL## dagoeneko ##COMPANY_BRAND_NAME## enpresa erregistratuta dago ##FEDERATEDACCOUNT## bitartez. Jarraitu nahi al duzu?',
      instavoltUserAccount: '##COMPANY_BRAND_NAME## Erabiltzaile kontua',
      alreadyRegisteredWithNormalAcc:
        '##EMAIL## dagoeneko ##COMPANY_BRAND_NAME## enpresa erregistratuta dago ##COMPANY_BRAND_NAME## erabiltzailearen kontuaren bitartez. Jarraitu nahi al duzu?',
    },
    toast: {
      success: 'Gidariaren kontua ondo sortu da.',
      alreadyExist: 'Sartu duzun helbide elektronikoa jada existitzen da.',
      error: 'Errore bat gertatu da zure eskaera prozesatzean, saiatu geroago.',
      confirmationSuccess: 'Kontua ondo egiaztatu da.',
      invalidCode: 'Hara! Kode baliogabea sartu duzu.',
      resendSentOTP: 'OTP kodea ondo bidali da.',
      resendOTPErr: 'OTP kodea ez da ondo bidali. Berriz saiatu geroago.',
      referralCodeInvalid: 'Bidaltze-kodea ez da baliozkoa.',
      resendSignupCode: 'Hara, beste kode bat bidali berri dizugu, begiratu zure e-postan.',
      resendLimitExceeded: 'Gehienezko saialdietara iritsi zara. Berriz saiatu pixka bat geroago.',
    },
    errors: {
      recaptchaCode: 'Egiaztatu recaptcha kodearekin.',
      passwordFormat:
        'Pasahitzak 10 karaktere edo gehiago izan behar ditu, letra maiuskula, letra minuskula, karaktere berezi eta zenbaki banarekin.',
      securityCode: 'Segurtasun-kodeak 6 digitu izan behar ditu gutxienez',
      firstName: 'Izena ez da baliozkoa.',
      lastName: 'Abizena ez da baliozkoa.',
      termsAndCondition: 'Terminoak eta baldintzak irakurri eta onartu behar dituzu aurrera egin ahal izateko.',
    },
  },
  Payment: {
    Title: 'Ordainketa konfigurazioa - ##COMPANY_BRAND_NAME## : IE karga azkarra (EB)',
    ChargeUnit: '(kWh)',
    description: 'Ordainketa honi ##COMPANY_BRAND_NAME##',
    PaymentType: {
      Title: 'Ordainketa konfigurazioa',
      SubTitle: 'Mesedez, aukeratu ordainketa-aukera',
      Message: {
        selectPaymentOption: 'Mesedez, aukeratu ordainketa-aukera.',
      },
      Inputs: {
        creditAccount: {
          label: 'Kreditu-kontua',
          label1: 'Ordaindu kargatu ahala',
        },
        paymentAccount: {
          label: 'Aurreordainketa-kontua',
          label1: 'Aldez aurretik ordaindu eta erabili zure kontuko kreditua kargatzeko',
        },
      },
      Buttons: {
        Next: {
          text: 'Hurrengoa',
        },
      },
    },
    CreditPayment: {
      Title: 'Ordainketa-konfigurazioa',
      SubTitle: 'Kreditu-kontua',
      note: 'Kontuan izan ordaintzeko txartel bat gehitzen duzunean, zure kontuan ##CURRENCY## kargatuko zaizula, zeina zure ##COMPANY_BRAND_NAME## zorroko saldotik kenduko den.',
      donateMessage: {
        first: 'Bai, eman',
        second: 'Biribildu ordainketa honi',
      },
      donationNote:
        "Dohaintza 'biribildu' hurrengo 50 xentimoetara edo 'osatu' zehaztutako kantitate batekin ordainketa-transakzio bakoitzarentzat.",
      Inputs: {
        CardTitle: {
          label: 'Txarteleko izena',
          placeHolder: 'Txarteleko izena',
          required: 'Txartelaren titularra beharrezkoa da',
        },
        CardNumber: {
          label: 'Txartel-zenbakia',
          placeHolder: '1234  1234  1234  1234',
          invalid: 'Txartel-zenbaki baliogabea',
          required: 'Txartel-zenbakia beharrezkoa da',
          maxlimit: 'Txartel-zenbakiak 20 digitu izan behar ditu gehienez',
        },
        Expiry: {
          label: 'Iraungitzea',
          required: 'Iraungitze-hilabetea beharrezkoa da',
        },
        Year: {
          label: 'Urtea',
          required: 'Iraungitze-urtea beharrezkoa da',
        },
        CSC: {
          label: 'CSC',
          label1: '(Txartelaren segurtasun-kodea)',
          required: 'Txartelaren segurtasun-kodea beharrezkoa da',
          placeHolder: 'CSC',
          maxlimit: 'Mesedez, sartu gutxienez 3 digituko kodea.',
        },
        Donation: {
          label: 'Bai, dohaintza egin nahi dut!',
        },
        giftaid: {
          label: 'Gaitu Laguntzarako dohaintza opari?',
        },
        RoundUp: {
          label: 'Gorantz biribildua',
        },
        TopUp: {
          label: 'Areagotu',
        },
      },
      Buttons: {
        Save: {
          text: 'Gorde',
        },
        Back: {
          text: 'Aldatu ordainketa kontura',
        },
        editBack: {
          text: 'Atzera',
        },
      },
      Card: {
        expire: 'Iraungiko du',
        add: 'Gehitu txartela',
        charity: 'Ongizateko dohaintza',
        edit: 'Dohaintza ezarpenak editatu',
        editCard: 'Txartela editatu',
        delete: 'Ezabatu',
        replace: 'Ordezkatu',
        balance: 'Uneko saldoa:',
        Outbalance: 'Saldoa ordaintzeke:',
        mmYY: 'HH/UU',
        mm: 'HH',
        yyyy: 'UUUU',
        Pay: {
          text: 'Orain ordaindu',
        },
        Withdraw: {
          text: 'Funtsak atera',
        },
        toast: {
          delete: {
            success: 'Txartela ongi ezabatu da.',
            error: 'Errore bat gertatu da zure eskaera prozesatzean. Berriz saiatu geroago.',
          },
          save: {
            success: 'Txartelaren xehetasunak ongi gorde dira.',
            error: 'Errore bat gertatu da zure eskaera prozesatzean. Berriz saiatu geroago.',
          },
          paynow: {
            success: 'Ordainketa ondo egin da.',
            error: 'Errore bat gertatu da zure eskaera prozesatzean. Berriz saiatu geroago.',
          },
          edit: {
            success: 'Txartelaren xehetasunak ongi eguneratu dira.',
            error: 'Errore bat gertatu da zure eskaera prozesatzean. Berriz saiatu geroago.',
          },
          replace: {
            success: 'Txartelaren xehetasunak ongi ordezkatu dira.',
            error: 'Errore bat gertatu da zure eskaera prozesatzean. Berriz saiatu geroago.',
          },
          common: {
            error: 'Errore bat gertatu da zure eskaera prozesatzean. Berriz saiatu geroago.',
          },
          amount: 'Ez dago kobratzeko saldo nabarmenik.',
          noSwitch: 'Ordaintzeke dagoen zenbatekoa garbitu aurreordainketa-kontu batera aldatu aurretik',
        },
      },
      label: {
        accountFunds: 'Kontuko funtsak',
        rewards: 'Sariak',
        referralBonus: 'Erreferentziako hobaria',
        currentBalance: 'Uneko saldoa',
        seeMore: 'Ikus gehiago',
        message: 'Mezua',
        charityDonation: 'Ongizateko dohaintza',
        topupAmount: 'Bai, eman',
        note: 'Oharra:',
        charity: 'Dohaintza',
        card: 'txartela',
        donation: 'dohaintza',
        giftaid: 'oparia',
        noThanks: 'Ez, eskerrik asko',
        cancel: 'Ezeztatu',
      },
      error: 'Zure ordainketa-transakzioa transakzio-balioaren azpitik dago ##ZENBATEKOA',
    },
    PrePaymentDetail: {
      Title: 'Ordainketa-konfigurazioa',
      SubTitle: 'Aurreordainketa-kontua',
      Inputs: {
        Amount: {
          label: 'Kantitatea',
          placeHolder: 'Kantitatea',
          required: 'Kantitatea beharrezkoa da',
          invalid: 'Kantitateak X edo handiagoa izan behar du',
          maxLimit: '',
          number: 'Kantitatea zenbaki bat izan behar da',
        },
        CardNumber: {
          label: 'Txartel-zenbakia',
          placeHolder: '1234  1234  1234  1234',
          required: 'Txartel-zenbakia beharrezkoa da',
          invalid: 'Txartel-zenbaki baliogabea',
        },
        Expiry: {
          label: 'Iraungitzea',
          required: 'Iraungitze-hilabetea beharrezkoa da',
        },
        Year: {
          label: 'Urtea',
          required: 'Iraungitze-urtea beharrezkoa da',
        },
        CSC: {
          label: 'CSC',
          label1: '(Txartelaren segurtasun-kodea)',
          required: 'Txartelaren segurtasun-kodea beharrezkoa da',
          placeHolder: 'CSC',
          maxlimit: 'Mesedez, sartu gutxienez 3 digituko kodea.',
        },
        Donation: {
          label: 'Bai, dohaintza egin nahi dut!',
        },
        RoundUp: {
          label: 'Gorantz biribildua',
        },
        TopUp: {
          label: 'Areagotu',
        },
      },
      Buttons: {
        Save: {
          text: 'Gehitu dirua',
        },
        Back: {
          text: 'Kreditu kontura aldatu',
          text1: 'Atzera',
        },
        FailBack: {
          text: 'Kontura itzuli',
        },
      },
      PrePayemnt: {
        text: {
          currentBalance: 'Uneko saldoa',
          OutBalance: 'Saldoa ordaintzeke',
        },
        button: {
          add: 'Gehitu dirua',
          Withdraw: 'Funtsak atera',
          withdrawAccountFunds: 'Atera kontuko funtsak',
          viewYourRefundRequests: 'Ikusi dirua ateratzeko eskakizuna',
        },
      },
      toast: {
        canNotAddMoney:
          'Ezin duzu funts gehiago gehitu ##COMPANY_BRAND_NAME## zorroan batek izan ditzakeen funtsen gehienezko zenbatekora iritsi zarelako.',
        common: {
          error: 'Errore bat gertatu da zure eskaera prozesatzean. Berriz saiatu geroago.',
        },
        maxLimitForPrePayment: 'Hara! zure zorroaren saldoa  ##MAXAMOUNT-ra arte bakarrik igo dezakegu.',
        maxLimitReached:
          'Hara! zure zorroaren saldoa zenbateko honetara arte bakarrik igo dezakegu##MAXAMOUNT. Gehienez  ##ALLOWEDAMOUNT gehitu dezakezu orain.',
      },
      label: {
        accountFunds: 'Kontuko funtsak',
        rewards: 'Sariak',
        referralBonus: 'Igorpen-hobaria',
        currentBalance: 'Uneko saldoa',
        seeMore: 'Ikus gehiago',
        billingAddress: 'Fakturazio-helbidea',
        charityDonation: 'Ongizateko dohaintza',
        note: 'Oharra:',
      },
      donationNote: "Dohaintza 'osatu' zehaztutako kantitate batekin ordainketa-transakzio bakoitzarentzat.",
    },
    PrePaymentWithdraw: {
      Title: 'Ordainketa-konfigurazioa',
      SubTitle: 'Aurreordainketa-kontua',
      Inputs: {
        Amount: {
          label: 'Kantitatea',
          placeHolder: 'Kantitatea',
          required: 'Kantitatea beharrezkoa da',
          invalid: 'Kantitatea egungo saldoa baino gehiago da',
        },
        CardNumber: {
          label: 'Txartel-zenbakia',
          placeHolder: '1234  1234  1234  1234',
          required: 'Txartel-zenbakia beharrezkoa da',
          invalid: 'Txartel-zenbaki baliogabea',
          maxlimit: 'Txartel-zenbakiak 20 digitu izan behar ditu gehienez',
        },
        Expiry: {
          label: 'Iraungitzea',
          required: 'Iraungitze-hilabetea beharrezkoa da',
        },
        Year: {
          label: 'Urtea',
          required: 'Iraungitze-urtea beharrezkoa da',
        },
        CSC: {
          label: 'CSC',
          label1: '(Txartelaren segurtasun-kodea)',
          required: 'Txartelaren segurtasun-kodea beharrezkoa da',
          placeHolder: 'CSC',
          maxlimit: 'Mesedez, sartu gutxienez 3 digituko kodea.',
        },
      },
      Buttons: {
        Withdraw: {
          text: 'Funtsak atera',
        },
        Back: {
          text: 'Kreditu-kontura aldatu',
          text1: 'Atzera',
        },
        FailBack: {
          text: 'Kontura itzuli',
        },
      },
      PrePayemnt: {
        text: {
          currentBalance: 'Uneko saldoa',
          OutBalance: 'Saldoa ordaintzeke',
        },
        button: {
          add: 'Gehitu dirua',
          Withdraw: 'Funtsak atera',
        },
      },
    },
    PaymentSuccess: {
      label: 'Eginda!',
      Successlabel: 'Transakzioa ongi burutu da',
      transactionId: 'Transakzioaren Id-a:',
      amount: 'Zenbatekoa:',
      warningLabel: 'Abisua',
      common: {
        error: 'Errore bat gertatu da zure eskaera prozesatzean. Berriz saiatu geroago.',
      },
    },
    PaymentFail: {
      label: 'Transakzioak huts egin du',
      failureLabel: 'Trantsakzioa egitean errorea egon da. Berriz saiatu.',
    },
    PaymentProcess: {
      label: 'Itxaron transakzioa prozesatzen ari den bitartean',
    },
    Model: {
      header: 'Kontua aldatu',
      DeleteBoxHeader: 'Ezabatu txartela',
      WithdrawBoxHeader: 'Funtsak atera',
      button: {
        yes: 'Bai',
        no: 'Ez',
        existingCard: 'Jadanik erregistratutako txartela',
        newCard: 'Txartel berria',
      },
      messagePrePayment: 'Ziur zaude aurreordainketa-kontura aldatu nahi duzula?',
      messageCreditPayment: 'Ziur kontua aldatu nahi duzula?',
      msgPrePaymentSaveCard:
        'Aurreordainketa-kontura aldatuz, zure txartelaren datuak ezabatuko dira. Ziur zaude aurreordainketa-kontura aldatu nahi duzula?',
      DeleteCardMsg: 'Ziur zaude txartelaren datuak ezabatu nahi dituzula?',
      WithdrawMoneyMsg: 'Hautatu txartelaren aukera dirua ateratzeko.',
    },
    charityModal: {
      yesDonate: 'Bai, eman',
    },
    Withdraw: {
      CanNotWithdrawMoneyWhileSessionIsActive:
        'Momentu honetan ezin duzu dirua atera. Mesedez, itxaron martxan kargatzeko saioa amaitu arte',
    },
  },
  termsAndCondition: {
    title: 'Terminoak eta baldintzak',
    note: 'Orria sortzen ari gara.',
  },
  table: {
    data: {
      noData: 'Ez da aurkitu daturik.',
    },
    pagination: {
      prev: 'Aurrekoa',
      nrev: 'Hurrengoa',
      showing: 'Bistaratzen',
      pageSize: {
        ten: '10',
        fifty: '50',
        hundred: '100',
      },
    },
  },
  history: {
    headerTitle: 'Karga eta ordainketa historia - ##COMPANY_BRAND_NAME## : IE karga azkarra (EB)',
    title: 'Kobrantza eta ordainketen historia',
    columns: {
      chargingStation: 'Kargatzeko geltokia',
      date: 'Data',
      address: 'Helbidea',
      milesAdded: 'Gehitutako miliak',
      time: 'Denbora',
      cost: 'Kostua',
      energy: 'Energia',
      action: 'Ekintza',
      remark: 'Iruzkinak',
      credit: 'Kreditua',
      debit: 'Zordunketa',
      closing: 'Ixten',
      transaction: 'Transakzioaren ID-a',
      credit_debit: 'Kreditua / Zordunketa',
    },
    form: {
      charge: 'Karga',
      payment: 'Ordainketa',
      month: 'Hilabetea',
    },
    label: {
      exportAll: 'Sortu ordainagiria',
    },
    button: {
      search: 'Bilatu',
      reset: 'Berrezarri',
    },
    message: {
      selectRecordToDownload: 'Mesedez, aukeratu edozein erregistro ordainagiri bat sortzeko.',
      noChargingHistoryMessage: 'Zure IE-a kargatzen duzunean, zure kargen historia azalduko da hemen.',
      noPaymentHistoryMessage: 'Ordainketa burutzen duzunean, hemen bistaratuko da.',
    },
    receipt: {
      title: 'ORDAINAGIRIA',
      header: {
        date: 'Data',
        billing_addresss: 'Fakturazio-helbidea',
        note: 'Zure ##COMPANY_BRAND_NAME## IE kargaren ordainagiria',
        vat_number: 'VAT zenbakia',
      },
      content: {
        summary: 'Hemen ikus ditzakezu zure karga-transakzioen laburpena',
        table: {
          header: {
            station_name: 'Geltokiaren izena',
            start: 'Hasiera',
            end: 'Bukaera',
            usage: 'Erabilera',
            unit: 'Unitate',
            value: 'Balioa',
            transaction_id: 'Transakzioaren ID-a',
            vatRate: 'BEZ tasa',
            vat: 'BEZ',
          },
        },
        total_before_vat: 'Guztizkoa BEZ gabe',
        total_vat: 'BEZ guztira',
        vat: 'BEZ-a',
        total_fee: 'Zergen guztizkoa',
        thanks_message:
          'Eskerrik asko ##COMPANY_BRAND_NAME##ren ibilgailu elektrikoak kargatzeko sarea erabiltzeagatik',
        vatReceiptNotGenerated:
          'Oraindik ez da sortu zure BEZ ordainagiria. Saiatu berriz karga-saioa bukatu denetik 24 ordura. Oraindik ere lortzen ez baduzu, jarri harremanetan bezeroentzako arreta zerbitzuarekin.',
        vatReceiptNotAssociatedWithAcc:
          'Oraindik ez da lotu BEZ ordainagiria zure kontura. Egiaztatu datu zuzenak ari zarela erabiltzen BEZ ordainagiria ikusteko.',
      },
      footer: {
        title:
          '##COMPANY_BRAND_NAME## Limited Ingalaterran eta Galesen erregistratutako enpresa da, 10484882 zenbakiarekin',
        address: '6 Cedarwood, Crockford Lane, Chineham Park, Basingstoke, Hampshire, RG24 8WD helbidean.',
        telephone: 'T',
        email: 'E',
        note: 'Adierazi transakzioaren data eta identifikazio-zenbakia posta guztietan.',
      },
    },
  },
  loyaltyPoint: {
    header: 'Sariak - ##COMPANY_BRAND_NAME## : IE karga azkarra (EB)',
    title: 'Sariak',
    note: '*Voltak gure aplikazioaren bitartez bakarrik trukatu ahal izango dituzu.',
    availableVolts: 'Voltak erabilgarri:',
    noDataMessage: 'Karga-saioa bukatzen duzunean, zure volten transakzioak hemen bistaratuko dira.',
    label: {
      chargingStation: 'Kargatzeko geltokia',
      date: 'Data',
      address: 'Helbidea',
      credit: 'Kreditua',
      debit: 'Zordunketa',
      closing: 'Ixten',
      redeemed: 'Trukatuta',
      charged: 'Kargatuta',
      remark: 'Iruzkinak',
      credit_debit: 'Kreditua / Zordunketa',
      voltsRedeemed: 'Trukatutako voltak',
    },
  },
  withdraw: {
    subTitle: 'Aurreordainketa-kontua',
    label: {
      maxWithdrawalBalance: 'Atera daitekeen saldo maximoa',
      note: 'Oharra:',
      enterAmount: 'Sartu zenbateko bat',
      withdrawAmount: 'Ateratako zenbatekoa',
      expires: 'Iraungiko du',
      rewardHeading: 'Sartu zure kontutik atera nahi duzun balioa',
      selectCardToWithdrawAmount: 'Hautatu kantitatea ateratzeko txartela',
      remainingAmount: 'Gelditzen den kantitatea',
      accountName: 'Kontuaren izena',
      sortCode: 'Sailkapen-kodea',
      accountNumber: 'Kontuaren zenbakia',
      bankDetail: 'Bankuko datuak',
      withdrawAmountNote:
        'Zure kontutik funtsak kontua kargatzeko erabilitako ordaintzeko txartelera bakarrik transferitu ahal izango dituzu.',
      withdrawAmountNote1: 'Kontuan izan diru-ateratzea prozesatzeak 14 lanegun behar dituela.',
      withdrawAmountNote2: 'Kontuan izan diru-ateratzea prozesatzeak 3 eta 5 lanegun bitartean behar dituela.',
      accountNameNote:
        ' Ziurtatu bankuko kontuaren izena bat datorrela zure ##COMPANY_BRAND_NAME## kontuaren izenarekin. Bestela, dirua ateratzea baztertuko da.',
    },
    placeholder: {
      amount: 'Kantitatea',
      accountName: 'Kontuaren izena',
      sortCode: 'Sailkapen-kodea',
      accountNumber: 'Kontuaren zenbakia',
    },
    button: {
      back: 'Atzera',
      next: 'Hurrengoa',
      raisedRequest: 'Igo funtsak ateratzea eskakizuna',
      withdrawMoney: 'Funtsak atera',
      proceed: 'Ekin',
    },
    errors: {
      amount: {
        typeError: 'Sartu baliozko kantitatea',
        moreThan: 'Kantitateak 0 baino handiagoa izan behar du',
      },
      AmountIsGreaterThanwithdrawableAmount: 'Sartu duzun kantitatea atera dezakezuna baino gehiago da',
      selectCardToWithdrawMoney: 'Hautatu txarteletako bat dirua ateratzeko.',
      canNotSelectCard:
        'Ateratzeko diruaren balioak hautatutako ordainketa-txartelarentzat erabilgarri dagoen gehienez atera ahal den funtsaren balioa gainditzen du. Hautatu beste txartel bat.',
    },
  },
  termsAndConditionListing: {
    title: 'Terminoak eta baldintzak- ##COMPANY_BRAND_NAME## : IE karga azkarra (EB)',
    noteDetails: '*Nahitaezkoa onartu behar da kontua erabiltzeko edo erregistratzeko.',
    label: {
      referralCode: 'Erreferentzia-kodea (baldin eta badaukazu)',
      promotioncode: 'Sustapen-kodea (baldin eta badaukazu)',
      termsAndConditions: 'Terminoak eta baldintzak.',
      welcome: 'Ongi etorri',
      apply: 'Aplikatu',
      note: 'Oharra:',
      invalidCode: 'Hara! Kode baliogabea!',
      usedCode: 'Kodea dagoeneko erabiltzen ari da',
      codeApplied: 'Aplikatutako kodea',
      iAggreeToTermsCondition: 'Onartzen dut',
      reviewUpdatedCondition: 'Berrikusi eta onetsi beheko zerrendako termino eta baldintza eguneratuak.',
      name: 'Izena',
      accepted: 'Onartua',
    },
    button: {
      next: 'Hurrengoa',
      cancel: 'Ezeztatu',
      accept: 'Onartu',
    },
    errors: {
      acceptTermCondition: 'Onartu * nahitaezko Terminoak eta baldintzak jarraitzeko.',
      invalidReferralCode:
        'Sartutako ##REFERRAL_CODE## igorpen-kodea ez da baliozkoa. Sartu testu zuzena (letra larriak eta xeheak bereizten ditu) edo kendu igorle-kodea jarraitzeko.',
      invalidPromotionCode:
        'Sartutako ##PROMO_CODE## sustapen-kodea ez da zuzena. Sartu testu zuzena (letra larriak eta xeheak bereizten ditu) edo kendu sustapen-kodea jarraitzeko.',
      referralNotAllowed: 'Igorle-kodeak ezin dira erabili sustapen honen aurka',
    },
    message: {
      refferalCode: {
        first: 'Igorle-kodea',
        second:
          'ez da zuzena. Sartu testu zuzena (letra larriak eta xeheak bereizten ditu) edo kendu igorle-kodea jarraitzeko.',
      },
      promotionCode: {
        first: 'Sustapen-kodea',
        second:
          'ez da zuzena. Sartu testu zuzena (letra larriak eta xeheak bereizten ditu) edo kendu sustapen-kodea jarraitzeko.',
      },
    },
  },
  cookiePolicy: {
    text: 'Webgune honek cookieak erabiltzen ditu erabiltzailearen esperientzia hobetzeko. Gure webgunea erabiltzean, onartzen duzu irakurri eta ulertu duzula gure',
    action: 'Cookien politika',
    iAccept: 'Onartzen dut',
  },
  openSource: {
    title: 'Kredituak',
  },
  refundRequest: {
    title: 'Dirua ateratzeko eskaerak - ##COMPANY_BRAND_NAME## : IE karga azkarra (EB)',
    header: 'Dirua ateratzeko eskaerak',
    label: {
      name: 'Izena',
      requestedDate: 'Eskatutako data',
      refundRequested: 'Eskatutako diru-ateratzea',
      account: 'Kontua (Kreditua ateratzeko)',
      remarks: 'Iruzkinak',
      transactionID: 'Eskakizunaren ID',
      processing: 'Prozesatzen',
      cancelled: 'Ezeztatuta',
      declined: 'Baztertua',
      complete: 'Osatu',
      date: 'Data',
      status: 'Egoera',
      cancelRequest: 'Eskakizuna bertan behera utzi',
      action: 'Ekintza',
    },
    button: {
      search: 'Bilatu',
      reset: 'Berrezarri',
      yes: 'Bai',
      no: 'Ez',
    },
    placeholder: {
      selectStatus: 'Hautatu egoera',
    },
    message: {
      confirmCancelRequest: 'Ziur dirua ateratzeko eskaera bertan behera utzi nahi duzula?',
    },
    errors: {
      sortCode: {
        min: 'Mesedez, sartu gutxienez 6 digituko kodea.',
        valid: 'Sartu baliozko kode bat.',
      },
      accountNumber: {
        min: 'Mesedez, sartu gutxienez 8 digituko kontu-zenbaki bat.',
      },
    },
  },
  footer: {
    version: 'Bertsioa',
    copyRightText: 'Eskubide guztiak erreserbatuta',
  },
  rfidCards: {
    headerTitle: 'RFID txartelak - ##COMPANY_BRAND_NAME## : IE karga azkarra (EB)',
    title: 'RFID txartelak',
    label: {
      public_card_id: 'Txartelaren zenbakia(k)',
      quantity: 'Idatzi kantitate bat',
      action: 'Ekintza',
      delete: 'Ezabatu',
      cardNumber: 'Idatzi txartelaren zenbakia',
      rfid_card_unit_price: 'RFID txartel baten unitatearen prezioa da',
      rfid_card_unit_price_label: 'RFID txartel bakoitzeko',
    },
    placeholder: {
      quantity: 'Idatzi kantitate bat',
      public_card_id: 'Idatzi txartelaren zenbakia',
    },
    noDataMessage: 'Une honetan ez dago RFID txartelik aktibo',
    requestProcessDaysMessage: 'Zure RFID txartelaren eskaera ##DAYS## lanegunetan prozesatuko da.',
    modal: {
      header: 'RFID txartel-eskaera',
      body: 'Sartu behar diren RFID txartel kopurua. Txartelak zure kontua erregistratzean eman zenuen helbidera bidaliko dira. Txartelak jasotzean, zure kontuan aktibatu beharko dituzu.',
    },
    addCardModal: {
      header: 'Gehitu RFID txartela',
    },
    button: {
      proceed: 'Ekin',
      cancel: 'Ezeztatu',
      addNewCard: 'Gehitu RFID txartela',
      requestCard: 'Eskatu RFID txartel berria',
      delete: 'Ezabatu',
      action: 'Ekintza',
    },
    errors: {
      quantity: {
        min: 'Kantitateak zero baino handiagoa izan behar du',
      },
      public_card_id: {
        valid: 'Sartutako txartelaren ID-a ez da ezagutu. Ziurtatu ez direla sartu hutsuneak/karaktere bereziak ID-an.',
      },
    },
    message: {
      here: 'hemen',
      deleteConfirmMessage:
        'Txartel hori ezabatzen, behin betiko desgaituko duzu eta ezingo da berriz erregistratu. Ziur jarraitu nahi duzula?',
    },
  },
  giftAidmessage: 'laguntza opariaren mezua',
  creditAccountDonationNote:
    'Bai aukeratzen baduzu, ##AMOUNT##-(e)ko dohaintza aplikatuko zai(zki)e aurrerantzean egingo dituzun ordainketa guztiei. Nahi duzunean aldatu ahal izango dituzu zure dohaintza lehenetsiak â€œEditatu dohaintzen ezarpenakâ€ botoian.',
  PrepaymentAccountDonationNote:
    'Bai aukeratzen baduzu, ##AMOUNT##-(e)ko dohaintza aplikatuko zai(zki)o ordainketa-transakzio honi. Nahi duzunean aldatu ahal izango dituzu zure dohaintza lehenetsiak â€œEditatu dohaintzen ezarpenakâ€ botoian.',
  aboutUs: {
    listTitle: {
      openSourcelicenses: 'Kode irekiko lizentziak',
    },
  },
  price_breakdown_details: {
    detailed_breakup: 'Banaketa xehatua',
    total_cost: 'Kostuak guztira',
    conversion_rate: 'Bihurketa-tasa',
  },
};
