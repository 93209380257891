import { ActionType } from '../actions/Helpers';
import { Configurations } from '../state/configuration.state';

export const ConfigurationsReducer = (state: Configurations = null, action): Configurations => {
  switch (action.type) {
    case ActionType.DATE_TIME_FORMAT:
      return {
        ...state,
        date_time_format: action.payload,
      };
    case ActionType.NUMBER_FORMAT:
      return {
        ...state,
        number_format: action.payload,
      };
    case ActionType.CURRENCY_DETAILS:
      return {
        ...state,
        default_currency: action.payload,
      };
    case ActionType.CURRENCY_FORMAT:
      return {
        ...state,
        currency_format: action.payload,
      };
    case ActionType.COMPANY_NAME:
      return {
        ...state,
        company_name: action.payload,
      };
    default:
      return state;
  }
};
