import React, { useState } from 'react';
import { store } from '../../store';
import { Common } from '../../utils/constants';
import { IConfiguration } from '../../models/profile';
import { IFAQPageProps, customToggleProps } from '../../types/propTypes';
import { getFormattedCurrencyWithSymbol } from '../../components/common/LocalNumberFormat';
import moment from 'moment';
import { getRegionConfigurations } from '../../services/login/loginApi-service';
import _ from 'lodash';
import { getConfigurationDetail } from '../../services/user/user-service';
import { useAccordionButton } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IFAQConfig } from '../../models/faq';
import { ISupportedLanguage } from '../../store/state/language.state';
export const FAQPageHook = (props: IFAQPageProps) => {
  const [activeKey, setActiveKey] = useState('0');
  const [faqArray, setFaqArray] = useState([]);

  let defaultCurrency = '';
  const fetchData = async () => {
    await getConfiguration();

    const queryStringLang = new URLSearchParams(window.location.search);
    const languageInfo = store?.getState()?.LanguageInfo?.supportedLanguages;

    const changelanguage = languageInfo
      ? languageInfo?.filter(
          (item: ISupportedLanguage) =>
            item?.code === queryStringLang.get('langCode') || item?.code === queryStringLang.get('language'),
        )[0]
      : {
          code: queryStringLang.get('langCode') || queryStringLang.get('language'),
        };

    const regionCode = process.env.REACT_APP_PORTAL_REGION?.toLocaleLowerCase();
    let languageCode: string | undefined;

    if (queryStringLang.get('langCode') || queryStringLang.get('language')) {
      languageCode = changelanguage?.code;
    } else {
      if (store.getState()?.UserData) {
        languageCode = store.getState()?.UserData?.language?.code;
      } else if (store.getState()?.LanguageInfo) {
        languageCode = store.getState()?.LanguageInfo?.defaultLanguage?.code;
      }
    }

    let jsonFile = require(`../../assets/faq/uk/en.json`);
    if (regionCode && languageCode) {
      jsonFile = require(`../../assets/faq/${regionCode}/${languageCode}.json`);
    }
    const config = await getConfigData(Common.ConfigurationKey.FAQ_PRICE_PER_KWH);

    const brandConfig: IFAQConfig[] = await getConfigDataBrandName('COMPANY_BRAND_NAME');

    let pricePerKWHValue = '';
    if (config.length > 0) {
      const pricePerKWhConfig = config.find(
        (x: IConfiguration) => x.reference_code === Common.ConfigurationKey.FAQ_PRICE_PER_KWH,
      );

      pricePerKWHValue =
        pricePerKWhConfig && (pricePerKWhConfig as IFAQConfig).value ? (pricePerKWhConfig as IFAQConfig).value : '';
    }

    let brandname = '';
    if (brandConfig.length > 0) {
      brandname = brandConfig[0].value ? brandConfig[0].value : '';
    }
    let faqs = JSON.stringify(jsonFile?.faq);
    const currencySymbol = defaultCurrency ? defaultCurrency : '';
    const changeValue = store?.getState()?.configuration?.company_name || 'Instavolt';
    faqs = faqs.split('##COMPANY_BRAND_NAME##').join(brandname || changeValue);

    faqs = faqs
      .replace(
        '##PRICE_PER_KWH##',
        getFormattedCurrencyWithSymbol(pricePerKWHValue.toString(), 'string', undefined, currencySymbol) as string,
      )
      .replace(
        '##PRICE_PER_KWH##',
        getFormattedCurrencyWithSymbol(pricePerKWHValue.toString(), 'string', undefined, currencySymbol) as string,
      )
      .replace(
        '##START_TIME##',
        `${moment('8:30', 'HH:mm').format(
          store?.getState()?.configuration?.date_time_format?.APP_GENERAL_TIME_FORMAT
            ? store?.getState()?.configuration?.date_time_format?.APP_GENERAL_TIME_FORMAT
            : 'HH:mm',
        )}`,
      )
      .replace(
        '##END_TIME##',
        `${moment(languageCode === 'is' ? '17:00' : '5:00', 'HH:mm').format(
          store?.getState()?.configuration?.date_time_format?.APP_GENERAL_TIME_FORMAT
            ? store?.getState()?.configuration?.date_time_format?.APP_GENERAL_TIME_FORMAT
            : 'HH:mm',
        )}`,
      )
      .replace('##DRIVER_PORTAL_URL##', `${process.env.REACT_APP_DRIVER_PORTAL_URL}`)
      .replace('##DRIVER_PORTAL_LOGIN_URL##', `${process.env.REACT_APP_DRIVER_PORTAL_URL}/login`);

    const faqArray = JSON.parse(faqs);
    setFaqArray(faqArray);
  };

  const getConfiguration = async () => {
    try {
      await getRegionConfigurations().then((res) => {
        if (res?.data) {
          props.setCurrencyFormat(res?.data?.currency_formats);
          defaultCurrency = res?.data?.currency_formats?.DEFAULT_CURRENCY;
          if (_.isEmpty(res?.data?.number_formats)) {
            props.setNumberFormat({
              GENERAL_NUMBER_FORMAT: '###.###.###,###',
              FINANCIAL_NUMBER_FORMAT: '###.###.###,####',
              NUMBER_DECIMAL_SEPARATOR: ',',
            });
          } else {
            props.setNumberFormat(res?.data?.number_formats);
          }
        }
      });
    } catch (e) {
      props.setNumberFormat({
        GENERAL_NUMBER_FORMAT: '###.###.###,##',
        FINANCIAL_NUMBER_FORMAT: '###.###.###,####',
        NUMBER_DECIMAL_SEPARATOR: ',',
      });
      throw e;
    }
  };

  const getConfigData = async (code: string) => {
    let configData = [];
    await getConfigurationDetail({ code })
      .then(async (res) => {
        configData = res.data;
      })
      .catch((m) => {
        toast.error(m.toString());
      });
    return configData;
  };

  const getConfigDataBrandName = async (code: string) => {
    let configData = [];
    await getConfigurationDetail({ code })
      .then(async (res) => {
        configData = res.data;
      })
      .catch((m) => {
        toast.error(m.toString());
      });
    return configData;
  };

  const handleActiveKey = (key: number) => {
    const newActiveKey = activeKey == key.toString() ? '' : key.toString();
    setActiveKey(newActiveKey);
  };

  const CustomToggle = ({ children, eventKey }: customToggleProps) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => handleActiveKey(parseInt(eventKey)));
    return (
      <span
        className={activeKey === eventKey.toString() ? 'faq-card-title' : 'faq-card-title collapsed'}
        onClick={decoratedOnClick}
      >
        {children}
      </span>
    );
  };

  return {
    activeKey,
    faqArray,
    fetchData,
    getConfiguration,
    getConfigDataBrandName,
    handleActiveKey,
    CustomToggle,
    getConfigData,
  };
};
