import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

const ReactstrapSelectInput = ({ field, form: { touched, errors }, ...props }) => {
  const error = errors[field.name];
  const touch = touched[field.name];
  return (
    <FormGroup className="form-group">
      <Label for={props.inputprops.id} className={'label-color'}>
        {props.label}
      </Label>
      <Input
        id={props.inputprops.id}
        disabled={props.inputprops.disabled}
        {...field}
        {...props}
        type="select"
        invalid={Boolean(touched[field.name] && errors[field.name])}
        placeholder="Test"
        onChange={(value) => {
          props.onChange && props.onChange(value);
        }}
        className="form-control"
      >
        <option value="">{props.inputprops.defaultOption}</option>
        {props.inputprops.options.map((option, index: number) => {
          if (option.name) {
            return (
              <option value={option.id} key={index}>
                {option.name}
              </option>
            );
          }

          return (
            <option value={option} key={index}>
              {option}
            </option>
          );
        })}
      </Input>
      {touch && error && error !== 'required' && (
        <FormFeedback className="pb-0 error invalid-feedback d-block" type="invalid">
          {error}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

export default ReactstrapSelectInput;
