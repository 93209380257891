import { JWT } from 'aws-amplify/auth';
import { ICurrencyDetails, ICurrencyFormat, INumberFormat, IsupportedDateAndTimeFormat } from 'models/login';
import { IUserStats } from 'models/profile';
import { IErrorLog } from 'store/state/errorlog.state';
import { ISupportedLanguage } from 'store/state/language.state';

export enum ActionType {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  ERROR_LOG,
  CLEAR_ERROR_LOG,
  TOGGLE_SIDEBAR,
  CHANGE_LANGUAGE,
  FETCH_USER_DETAILS,
  USER_DATA,
  SUPPORTED_LANGUAGES,
  DEFAULT_LANGUAGE,
  DATE_TIME_FORMAT,
  NUMBER_FORMAT,
  CURRENCY_DETAILS,
  CURRENCY_FORMAT,
  COMPANY_NAME,
}

export interface LoginRequestAction {
  type: ActionType.LOGIN_REQUEST;
  payload: {
    tokens?: {
      idToken?: {
        payload: {
          sub?: string;
          identities?: string;
        };
      };
      accessToken?: JWT;
    };
    username: string;
  };
}

export interface FetchUserDetailsAction {
  type: ActionType.FETCH_USER_DETAILS;
  payload: {
    first_name?: string;
    last_name?: string;
    email?: string;
    is_profile_verified?: boolean;
    has_saved_terms?: boolean;
    terms_and_condition_required?: boolean;
    status?: IUserStats;
    payment_type?: IUserStats;
    copyright_text_driver?: string;
    version_number_driver?: string;
    id?: string;
    language_locale?: string;
  };
}
export interface UserDataAction {
  type: ActionType.USER_DATA;
  payload: {
    authSession: {
      tokens?: {
        idToken?: {
          payload: {
            sub?: string;
            identities?: string;
          };
        };
        accessToken?: JWT;
      };
    };
    first_name?: string;
    last_name?: string;
    email?: string;
    is_profile_verified?: boolean;
    has_saved_terms?: boolean;
    terms_and_condition_required?: boolean;
    status?: IUserStats;
    payment_type?: IUserStats;
    copyright_text_driver?: string;
    version_number_driver?: string;
    id?: string;
    language_locale?: string;
  };
}

export interface LogoutRequestAction {
  type: ActionType.LOGOUT_REQUEST;
}
export interface ChangeLanguageAction {
  type: ActionType.CHANGE_LANGUAGE;
  payload: ISupportedLanguage;
}

export interface DateTimeFormatAction {
  type: ActionType.DATE_TIME_FORMAT;
  payload: IsupportedDateAndTimeFormat;
}

export interface NumberFormatAction {
  type: ActionType.NUMBER_FORMAT;
  payload: INumberFormat;
}

export interface CurrencyDetailsAction {
  type: ActionType.CURRENCY_DETAILS;
  payload: ICurrencyDetails;
}

export interface CurrencyFormatAction {
  type: ActionType.CURRENCY_FORMAT;
  payload: ICurrencyFormat;
}

export interface CompanyNameAction {
  type: ActionType.COMPANY_NAME;
  payload: string;
}
export interface ErrorLogAction {
  type: ActionType.ERROR_LOG;
  payload: IErrorLog;
}

export interface ClearErrorLogAction {
  type: ActionType.CLEAR_ERROR_LOG;
  payload?: never;
}

export interface SupportedLanguagesAction {
  type: ActionType.SUPPORTED_LANGUAGES;
  payload: ISupportedLanguage[];
}

export interface DefaultLanguageAction {
  type: ActionType.DEFAULT_LANGUAGE;
  payload: ISupportedLanguage;
}

export type IAppAction =
  | LoginRequestAction
  | FetchUserDetailsAction
  | UserDataAction
  | LogoutRequestAction
  | ChangeLanguageAction
  | DateTimeFormatAction
  | NumberFormatAction
  | CurrencyDetailsAction
  | CurrencyFormatAction
  | CompanyNameAction
  | ErrorLogAction
  | ClearErrorLogAction
  | SupportedLanguagesAction
  | DefaultLanguageAction;
