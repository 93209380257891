import { Footer } from '../layout';
import React, { Suspense, useEffect } from 'react';
import Header from './header';
import { Outlet } from 'react-router';
import PageSpinner from '../components/shared/PageSpinner';
import { IMainLayoutProps } from '../types/propTypes';

const MainLayout = ({ logout }: IMainLayoutProps) => {
  useEffect(() => {
    if (document.getElementById(window.location.pathname.trim())) {
      const oldActiveElements = document.querySelectorAll('.nav-item.active');
      if (oldActiveElements.length) {
        for (const element of oldActiveElements) {
          element.classList.remove('active');
        }
      }

      document.getElementById(window.location.pathname.trim())?.classList.add('active');
    } else {
      const oldActiveElement = document.querySelector('.active');
      if (oldActiveElement) {
        oldActiveElement.classList.remove('active');
      }
    }
  }, []);

  return (
    <div className="wrapper">
      <Header logout={logout} />
      <main className="main-content">
        <div className="container-xl">
          <div className="card mb-4">
            <Suspense fallback={<PageSpinner />}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;
