import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getSubscriptionImage } from '../../services/user/user-service';
import { toast } from 'react-toastify';
import { companyNameReplace } from '../../utils';

const PremiumSubscriptionPage = () => {
  const [searchParams] = useSearchParams();
  const subscriptionID = searchParams.get('premium_subscription_id') || '';
  const languageID = searchParams.get('language_id') || '';

  const { t } = useTranslation();

  const [image, setImage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getPremiumSubscriptionImage();
  });

  const getPremiumSubscriptionImage = async () => {
    if (subscriptionID) {
      await getSubscriptionImage({
        premium_subscription_id: subscriptionID,
        language_id: languageID ? languageID : undefined,
      })
        .then((res) => {
          const Image = res.Data.image_path;
          if (Image) {
            setImage(`${process.env.REACT_APP_DRIVER_PORTAL_URL}/${Image}`);
            setLoading(false);
          }
        })
        .catch(() => {
          toast.error(t('Payment.PaymentSuccess.common.error'));
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <></>
      ) : image ? (
        <img src={image} style={{ width: '100vw', height: '100vh', display: 'block' }}></img>
      ) : (
        <div className="container-fluid subscription-wrapper" id="subscription-wrapper">
          <div className="subscription-header-div">
            <div>
              <h1 className="subscription-header">{companyNameReplace(t('dashboard.premiumSubscription'))}</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default PremiumSubscriptionPage;
