import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../store/state/app.state';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const userData = useSelector((state: AppState) => state.UserData);
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <p className="copyright">
        {`${t('footer.version')}: ${
          userData?.version_number_driver !== null ? userData?.version_number_driver : ''
        } | ${t('footer.copyRightText')}`}
      </p>
    </footer>
  );
};

export default Footer;
