import * as Yup from 'yup';
import { TFunction } from 'i18next';
import {
  ISignUpFormField,
  ISignUpOTPFormField,
  ILoginForm,
  IForgotPasswordFormField,
  IForgotPasswordChangeField,
} from 'models/login';
import { Common } from 'utils/constants';

export const LoginValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .matches(Common.RegularExpression.EmailRegularExpression, t('login.errors.inValidEmail'))
      .required('required'),
    password: Yup.string().required('required'),
  }) as unknown as Yup.SchemaOf<ILoginForm>;
};

export const ForgotPasswordSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .matches(Common.RegularExpression.EmailRegularExpression, t('login.errors.inValidEmail'))
      .required('required'),
  }) as Yup.SchemaOf<IForgotPasswordFormField>;
};

export const SignUpOTPSchema = (t: TFunction) => {
  return Yup.object().shape({
    code: Yup.string()
      .required('required')
      .trim()
      .matches(Common.RegularExpression.CodeRegularExpression, t('forgot.errors.verificationCodeFormat')),
  }) as unknown as Yup.SchemaOf<ISignUpOTPFormField>;
};

export const ResetPassworValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    password: Yup.string()
      .required('required')
      .matches(Common.RegularExpression.PasswordRegularExpression, t('forgot.errors.passwordFormat')),
    confirmPassword: Yup.string()
      .required('required')
      .oneOf([Yup.ref('password'), null], t('forgot.errors.confirmPasswordMatch')),
    code: Yup.string()
      .required('required')
      .trim()
      .matches(Common.RegularExpression.CodeRegularExpression, t('forgot.errors.verificationCodeFormat')),
    email: Yup.string(),
  }) as Yup.SchemaOf<IForgotPasswordChangeField>;
};

export const SignUpValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .matches(Common.RegularExpression.EmailRegularExpression, t('login.errors.inValidEmail'))
      .required('required')
      .max(50),
    password: Yup.string()
      .required('required')
      .matches(Common.RegularExpression.PasswordRegularExpression, t('signup.errors.passwordFormat')),
    firstName: Yup.string()
      .required('required')
      .max(20)
      .trim()
      .matches(Common.RegularExpression.GeneralNameRegularExpression, t('signup.errors.firstName')),
    lastName: Yup.string()
      .required('required')
      .max(20)
      .trim()
      .matches(Common.RegularExpression.GeneralNameRegularExpression, t('signup.errors.lastName')),
    recaptchaCode: Yup.string().required(t('signup.errors.recaptchaCode')).trim().nullable(),
  }) as unknown as Yup.SchemaOf<ISignUpFormField>;
};
