import React from 'react';
import { Spinner } from 'reactstrap';
import { IPageSpinnerProps } from '../../types/propTypes';

const PageSpinner = ({ color = 'primary' }: IPageSpinnerProps) => {
  return (
    <div className="cr-page-spinner">
      <Spinner color={color}></Spinner>
    </div>
  );
};

export default PageSpinner;
