import React from 'react';
import moment from 'moment';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import VATReceipt from '../history/VatReceipt';
import NoDataReceipt from './NoDataReceipt';
import { store } from '../../store';
import { useDownloadVATReceipt } from './DownloadVATReceiptHook';

const DownloadVATReceipt = () => {
  const { billingInfo, isAPICalled, isUnauthorisedUser } = useDownloadVATReceipt();

  return (
    <>
      {isAPICalled && Object.keys(billingInfo.receipt).length > 0 && (
        <>
          <div className="displayNone">
            <PDFDownloadLink
              document={
                <VATReceipt
                  data={billingInfo.receipt}
                  address={billingInfo.address}
                  companyStatement={billingInfo.companyStatement}
                />
              }
              fileName={`${store.getState().configuration?.company_name}_Rapid_EV_Charging_${moment(new Date()).format(
                'DD_MM_YY',
              )}.pdf`}
              className="btn icon-btn pr-0 btn-outline-primary"
            >
              {(() => <div id="instaVoltReceiptDownload"></div>) as unknown as React.JSX.Element}
            </PDFDownloadLink>
          </div>
          <PDFViewer className="receiptItem">
            <VATReceipt
              data={billingInfo.receipt}
              isDisplay={true}
              address={billingInfo.address}
              companyStatement={billingInfo.companyStatement}
            />
          </PDFViewer>
        </>
      )}
      {isAPICalled && Object.keys(billingInfo.receipt).length <= 0 && (
        <PDFViewer className="receiptItem">
          <NoDataReceipt isUnauthorisedUser={isUnauthorisedUser} />
        </PDFViewer>
      )}
    </>
  );
};

export default DownloadVATReceipt;
