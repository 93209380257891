import React from 'react';
import Select from 'react-select';

const SelectBox = (props) => {
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <Select
        {...props}
        name={props.name}
        value={props.value}
        options={props.options}
        isMulti={props.isMulti}
        onChange={(value) => props.onChange(value)}
        placeholder={props.placeholder}
      />
      {props.touched && props.errors && (
        <div className="pb-0 invalid-feedback d-block invalid-feedback">{props.touched && props.errors} </div>
      )}
    </>
  );
};
export default SelectBox;
