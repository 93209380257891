import React from 'react';
import images from '../../assets/images';
import { useReferral } from './ReferralHook';

const Referral = () => {
  useReferral();
  return (
    <>
      <div className="card-body text-center loaderBody">
        <div className="row min-vh-50 align-items-center">
          <div className="col">
            <div className="mesg-box process-block text-center">
              <img src={images.processGIF} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referral;
