import React from 'react';
import { ILoginProps } from '../../types/propTypes';
import { Helmet } from 'react-helmet';
import { ILoginForm } from '../../models/login';
import { Formik, Field } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { ReactstrapInput, ReactstrapFormikPasswordInput } from '../../components/reactstrap-formik';
import { LoginValidationSchema } from '../../validation/login/login-validation';
import { Common } from '../../utils/constants';
import SocialMediaLoginForm from './SocialMediaLoginForm';
import { useCookies } from 'react-cookie';
import images from '../../assets/images';
import { companyNameReplace } from '../../utils';
import { useTranslation } from 'react-i18next';

const LoginForm = ({ onChangeStage, handleSubmit, handleChangeFormValues }: ILoginProps) => {
  const [cookies, setCookie] = useCookies(['driver_remember', 'driver_email', 'driver_password']);

  const rememberValue = cookies.driver_remember === 'true';
  const parameters = new URLSearchParams(window.location.search);
  const initialState: ILoginForm = {
    email: rememberValue
      ? cookies.driver_email
      : parameters?.get('email') && parameters?.get('email') !== 'undefined'
        ? `${parameters.get('email')}`
        : '',
    password: rememberValue ? cookies.driver_password : '',
    rememberMe: rememberValue,
  };

  const handleLoginSubmit = (value: ILoginForm) => {
    const date = new Date();
    date.setDate(date.getDate() + 30);

    setCookie('driver_remember', value.rememberMe, { path: '/', expires: date });

    if (value.rememberMe) {
      setCookie('driver_email', value.email.toLowerCase(), { path: '/', expires: date });
      setCookie('driver_password', value.password, { path: '/', expires: date });
    }
    handleSubmit(value);
  };

  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{companyNameReplace(t('login.title'))}</title>
      </Helmet>
      <h1 className="title">
        <img src={images.logoIconPNG} alt={t<string>('login.label.logo')} />
        {t('login.header')}
      </h1>
      <Formik initialValues={initialState} onSubmit={handleLoginSubmit} validationSchema={LoginValidationSchema(t)}>
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <form className="form-secondary" onSubmit={handleSubmit} autoComplete="off" noValidate>
              <Field
                type="text"
                name="email"
                id="Email"
                label={t('login.form.email')}
                component={ReactstrapInput}
                maxLength={50}
                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  handleChangeFormValues(e);
                }}
                autoComplete="username"
              />
              <Field
                type="password"
                name="password"
                id="Password"
                label={t('login.form.password')}
                autoComplete="off"
                component={ReactstrapFormikPasswordInput}
              />
              <Form.Group className="form-group">
                <Button type="submit" className="btn btn-primary w-100">
                  {t('login.button.signin')}
                </Button>
              </Form.Group>
              <div className="form-group pb-2">
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="remember-me"
                    name="rememberMe"
                    checked={values.rememberMe}
                    onChange={() => setFieldValue('rememberMe', !values.rememberMe)}
                  />
                  <label className="custom-control-label pb-0" htmlFor="remember-me">
                    {t('login.label.rememberMe')}
                  </label>
                </div>
                <a
                  href="#"
                  className="forgot-password float-right"
                  title={t<string>('login.button.forgotPassword')}
                  onClick={(e) => {
                    e.preventDefault();
                    onChangeStage(Common.AuthStage.FORGOT_PASSWORD);
                  }}
                >
                  {t('login.button.forgotPassword')}
                </a>
              </div>
              <SocialMediaLoginForm />
              <div className="clearfix border-top-1 pt-4">
                <p className="sign-up">
                  {t('login.label.newAccount')}{' '}
                  <a
                    href="#"
                    title={t<string>('login.button.signup')}
                    onClick={(e) => {
                      e.preventDefault();
                      onChangeStage(Common.AuthStage.SIGNUP);
                    }}
                    className="signup-btn"
                  >
                    {t('login.button.signup')}
                  </a>
                </p>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default LoginForm;
