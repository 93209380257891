import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAboutUsHook } from './AboutUsHook';

const AboutUs = () => {
  const { t } = useTranslation();
  const { handleClick, aboutUsArray } = useAboutUsHook();

  return (
    <>
      <div className="open-source">
        <div className="wrapper">
          <main>
            <div className="detail-wrapper">
              {aboutUsArray.length > 0 &&
                aboutUsArray.map((data, index) => {
                  return (
                    <div key={index}>
                      {data.name === t('aboutUs.listTitle.openSourcelicenses') ? (
                        <a style={{ width: '5%' }} onClick={handleClick}>
                          <div className="detail-block-open-source">
                            <p style={{ paddingBottom: '0px', fontWeight: '900' }}>{data.name}</p>
                            <img style={{ paddingLeft: '30px' }} className="fit-img" src={data.url} alt={data.name} />
                          </div>
                        </a>
                      ) : (
                        <div className="detail-block-about-us">
                          <p>{data.name}</p>
                          <img className="fit-img" src={data.url} alt={data.name} />
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default AboutUs;
