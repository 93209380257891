import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';
import i18next from 'i18next';
import { Common } from '../../utils/constants';
import { defaultNumberFormat } from '../../components/common/LocalNumberFormat';
import { login, resendOtp, getAPIKey } from '../../services/login/login-service';
import { getRegionConfigurations, getRegions, getSupportedLanguages } from '../../services/login/loginApi-service';
import { getProfile } from '../../services/user/user-service';
import { AppDispatch, store } from '../../store';
import { ISupportedLanguage } from '../../store/state/language.state';
import {
  changeLanguage,
  logout,
  saveUserData,
  setCurrencyDetails,
  setCurrencyFormat,
  setDateTimeFormat,
  setDefaultLanguage,
  setNumberFormat,
  setSupportedLanguages as setSupportedLanguagesFromStore,
} from '../../store/actions/app.Actions';
import { IConfiguration, IRegionData, IUserData as IUserProfileData } from '../../models/profile';
import { ILoginForm, IPrivacyPolicy } from '../../models/login';
import { StandardResponse } from '../../models/standard-response';
import { ILoginFormState } from '../../types/stateTypes';
import { ILoginPageProps } from '../../types/propTypes';

const browserLang = {
  name: navigator?.language,
  id: 0,
  is_default: false,
  code: navigator?.language,
};
let defaultSelectedLang = store.getState().UserData?.language || browserLang;

export const LoginPageHook = (props: ILoginPageProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const parameters = new URLSearchParams(window.location.search);
  const { t } = useTranslation();

  const [stage, setStage] = useState<number>(
    parameters.get('stage') && parameters.get('stage') !== 'undefined'
      ? Number(parameters.get('stage'))
      : Common.AuthStage.SIGNIN,
  );
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailPattern, setEmailPattern] = useState<string>('');
  const [isLoginVerified, setIsLoginVerified] = useState<boolean>(false);
  const [referralCode, setReferralCode] = useState<string>('');
  const [isDriverVerified, _setIsDriverVerified] = useState<boolean | null>(null);
  const [language, setLanguage] = useState<ISupportedLanguage | null>(defaultSelectedLang);
  const [regions, setRegions] = useState<Array<IRegionData>>([]);
  const [supportedLanguages, setSupportedLanguages] = useState<Array<ISupportedLanguage>>([]);
  const [selectedRegion, setSelectedRegion] = useState<IRegionData | null>(null);
  const [formValues, setFormValues] = useState<ILoginFormState>({
    email: parameters.get('email') && parameters.get('email') !== 'undefined' ? `${parameters.get('email')}` : '',
    firstName:
      parameters.get('firstName') && parameters.get('firstName') !== 'undefined'
        ? `${parameters.get('firstName')}`
        : '',
    lastName:
      parameters.get('lastName') && parameters.get('lastName') !== 'undefined' ? `${parameters.get('lastName')}` : '',
  });
  const [apiError, setApiError] = useState<boolean>(false);
  const [privacyPolicyConfig, setPrivacyPolicyConfig] = useState<IPrivacyPolicy[] | null>(null);
  const [privacyPolicy, setPrivacyPolicy] = useState<IPrivacyPolicy | null>(null);
  const [regionDefaultLanguage, setRegionDefaultLanguage] = useState<ISupportedLanguage | null>(null);

  const colorStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? 'var(--themeColor)' : null,
      color: isFocused ? 'white' : '#333333',
      ':active': {
        ...styles[':active'],
        backgroundColor: 'var(--themeColor)',
      },
    }),
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getPrivacyPolicy();
  }, [regionDefaultLanguage]);

  const fetchData = async () => {
    try {
      document.getElementById('loading')?.classList.remove('spinner-disable');
      if (sessionStorage.getItem('error_message')) {
        toast.error(sessionStorage.getItem('error_message'));
        sessionStorage.removeItem('error_message');
      }
      await getSupportedLanguage();
      await getRegion();
      await getConfiguration();
    } finally {
      document.getElementById('loading')?.classList.add('spinner-disable');
    }
  };

  const handleChangeStage = (stage: number) => {
    setStage(stage);
    if (stage === Common.AuthStage.SIGNIN) {
      navigate('/login');
    } else if (stage === Common.AuthStage.SIGNUP) {
      navigate('/login?stage=3');
    }
  };

  const onSignupLogin = async () => {
    await handleDriverLogin({ email: email, password: password }, true);
  };

  const handleDriverLogin = async (value: ILoginForm, isSignUpVal) => {
    const downloadVATRedirect = sessionStorage.getItem('vat_receipt_path') || '';

    return login(value)
      .then(async (res) => {
        getProfile()
          .then((userDetail: StandardResponse<IUserProfileData>) => {
            if (
              userDetail &&
              userDetail.Data &&
              userDetail.Data.status &&
              userDetail.Data.status.code === Common.UserStatus.CANCELLED
            ) {
              dispatch(logout()).then(() => {
                toast.error(t('login.toast.cancelled'), { autoClose: 15000 });
              });
            } else {
              dispatch(saveUserData({ ...res, ...userDetail.Data }));
              dispatch(changeLanguage(userDetail?.Data?.language_preference));
              dispatch(setCurrencyDetails(userDetail?.Data?.default_currency));
              if (downloadVATRedirect && !isSignUpVal) {
                sessionStorage.removeItem('vat_receipt_path');
                navigate(downloadVATRedirect);
              } else if (userDetail && userDetail.Data.terms_and_condition_required) {
                navigate('/TermsAndCondition', {
                  state: { referralCode: referralCode },
                });
              } else if (userDetail && userDetail.Data.is_profile_verified === false) {
                navigate('/profile?redirect=true');
              } else if (userDetail && userDetail.Data.is_profile_verified === true) {
                const marketingConsent = localStorage.getItem(`${Common.MARKETING_CONSENT}-${userDetail.Data.id}`);
                if (!marketingConsent) {
                  navigate('/profile');
                } else if (userDetail && userDetail.Data.payment_type === null) {
                  navigate('/payment');
                } else {
                  navigate('/');
                }
              }
            }
            moment.locale(store.getState().UserData?.language?.code);
          })
          .catch(() => {
            dispatch(logout());
            toast.error(t('login.toast.error'));
          });
      })
      .catch((err) => {
        if (err && err.name === Common.Authentication.NotAuthorizedException) {
          toast.error(t('login.toast.inValid'));
        } else if (err && err.name === Common.Authentication.UserNotConfirmedException) {
          resendOtp(value.email)
            .then(() => {
              setEmail(value.email);
              setEmailPattern(value.email);
              setPassword(value.password);
              setIsLoginVerified(true);
              setStage(Common.AuthStage.SIGNUP_OTP);
            })
            .catch((er) => {
              if (er && er.name === Common.Authentication.LimitExceededException) {
                toast.error(t('login.toast.maximumAttempt'));
              }
            });
        } else {
          toast.error(t('login.toast.error'));
        }
      })
      .finally();
  };

  const handleLanguageDropdown = async (values: ISupportedLanguage) => {
    defaultSelectedLang = values;
    let selectedLanguagePolicy: IPrivacyPolicy | null = null;
    if (privacyPolicyConfig) {
      const getPolicyByLanguage = (languageCode) =>
        privacyPolicyConfig.find((policy) => policy.code === languageCode && policy.title && policy.privacy_policy_url);

      const regionDefaultLanguageName =
        regionDefaultLanguage && regionDefaultLanguage.code ? regionDefaultLanguage.code : '';

      selectedLanguagePolicy =
        getPolicyByLanguage(values.code) || (getPolicyByLanguage(regionDefaultLanguageName) as IPrivacyPolicy);
    }
    setLanguage(values);
    setPrivacyPolicy(selectedLanguagePolicy);
    dispatch(changeLanguage(values));
    await getRegion(values.code);
  };

  const getPrivacyPolicy = async () => {
    try {
      const res = await getAPIKey({
        code: Common.ConfigurationKey.PRIVACY_POLICY,
      });
      if (res?.data) {
        const privacyPolicyConfig = res.data.find(
          (config: IConfiguration) => config.reference_code === Common.ConfigurationKey.PRIVACY_POLICY,
        );
        if (privacyPolicyConfig?.value) {
          try {
            const policies = JSON.parse(privacyPolicyConfig.value);
            const getPolicyByLanguage = (languageCode) =>
              policies.find(
                (policy: IPrivacyPolicy) => policy.code === languageCode && policy.title && policy.privacy_policy_url,
              );
            const regionDefaultLanguageName =
              regionDefaultLanguage && regionDefaultLanguage.code ? regionDefaultLanguage.code : '';
            const selectedLanguagePolicy =
              getPolicyByLanguage(language?.code) || getPolicyByLanguage(regionDefaultLanguageName);
            if (selectedLanguagePolicy?.privacy_policy_url && selectedLanguagePolicy.title) {
              setPrivacyPolicyConfig(policies);
              setPrivacyPolicy(selectedLanguagePolicy);
            } else {
              setPrivacyPolicyConfig(policies);
              setPrivacyPolicy(null);
            }
          } catch (error) {
            console.error('Error parsing privacy policy JSON:', error);
          }
        } else {
          console.error('Privacy Policy Not Found');
        }
      }
    } catch (e) {
      console.error('Error fetching privacy policy:', e);
    }
  };

  const getConfiguration = async () => {
    try {
      await getRegionConfigurations().then((res) => {
        if (res?.data) {
          dispatch(setDateTimeFormat(res?.data?.date_time_formats));
          dispatch(setCurrencyFormat(res?.data?.currency_formats));
          if (_.isEmpty(res?.data?.number_formats)) {
            dispatch(setNumberFormat(defaultNumberFormat));
          } else {
            dispatch(setNumberFormat(res?.data?.number_formats));
          }
        }
      });
    } catch (e) {
      dispatch(setDateTimeFormat({}));
      dispatch(
        setNumberFormat({
          GENERAL_NUMBER_FORMAT: '###.###.###,##',
          FINANCIAL_NUMBER_FORMAT: '###.###.###,####',
          NUMBER_DECIMAL_SEPARATOR: ',',
        }),
      );
      throw e;
    }
  };

  const getRegion = async (language?: string) => {
    try {
      await getRegions(language).then((res) => {
        if (res && res.data && res.data.rows) {
          setRegions(res.data.rows);
          setSelectedRegion(
            res.data.rows && res.data.rows.filter((item) => item.code === process.env.REACT_APP_PORTAL_REGION)[0],
          );
        } else {
          setRegions([]);
          setSelectedRegion(null);
        }
      });
    } catch (_e) {
      setRegions([]);
      setSelectedRegion(null);
    }
  };

  const getSupportedLanguage = async () => {
    try {
      await getSupportedLanguages().then((res) => {
        if (res && res.data && res.data.rows) {
          const defaultLang = res.data.rows && res.data.rows.filter((item: ISupportedLanguage) => item.is_default)[0];
          const validBrowserLang =
            res.data.rows &&
            res.data.rows.filter((item: ISupportedLanguage) => browserLang?.code?.includes(String(item.code)));
          let language;
          if (defaultSelectedLang === browserLang) {
            if (store.getState()?.LanguageInfo?.defaultLanguage?.code !== store.getState()?.UserData?.language?.code) {
              const defaultLanguage = store.getState()?.LanguageInfo?.defaultLanguage || defaultLang;
              setLanguage(defaultLanguage);
              setSupportedLanguages(res.data.rows);
              setRegionDefaultLanguage(defaultLang);
            } else {
              //Default language = browser lang is in supported list otherwise  is_default lang from supportedLanguages
              // Update Default language if user change language from login/signup
              // Lang = user selected lang otherwise Default language
              const defaultLanguage =
                validBrowserLang && validBrowserLang.length > 0 ? validBrowserLang[0] : defaultLang;
              setLanguage(defaultLanguage);
              setSupportedLanguages(res.data.rows);
              setRegionDefaultLanguage(defaultLang);
              dispatch(setDefaultLanguage(defaultLanguage));
              language = defaultLanguage;
            }
          } else {
            setLanguage(defaultSelectedLang);
            setSupportedLanguages(res.data.rows);
            setRegionDefaultLanguage(defaultLang);
            dispatch(setDefaultLanguage(defaultSelectedLang));
            language = defaultSelectedLang;
          }
          dispatch(setSupportedLanguagesFromStore(res.data.rows));
          if (language && i18next?.language !== language.code) {
            dispatch(changeLanguage(language));
          }
          props.handleLanguageAndError();
        } else {
          setLanguage(null);
          setSupportedLanguages([]);
          props.handleLanguageAndError();
        }
      });
    } catch (_e) {
      props.handleLanguageAndError();
      setSupportedLanguages([]);
      setLanguage(null);
      setApiError(true);
    }
  };

  const handleChangeFormValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevVal) => ({
      ...prevVal,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRegionChange = (selectedRegion: IRegionData) => {
    setSelectedRegion(selectedRegion);
    if (stage === Common.AuthStage.SIGNUP) {
      window.location.href = `${selectedRegion.driver_portal_host_url}/login?stage=3&email=${formValues?.email}&firstName=${formValues?.firstName}&lastName=${formValues?.lastName}`;
    } else if (stage === Common.AuthStage.SIGNIN) {
      window.location.href = `${selectedRegion.driver_portal_host_url}/login?stage=0&email=${formValues?.email}`;
    }
  };

  return {
    stage,
    email,
    password,
    emailPattern,
    isLoginVerified,
    referralCode,
    isDriverVerified,
    language,
    regions,
    supportedLanguages,
    selectedRegion,
    formValues,
    apiError,
    colorStyles,
    privacyPolicy,
    setStage,
    setReferralCode,
    setRegionDefaultLanguage,
    setEmail,
    setPassword,
    setEmailPattern,
    setPrivacyPolicyConfig,
    fetchData,
    handleLanguageDropdown,
    handleChangeStage,
    handleDriverLogin,
    handleChangeFormValues,
    onSignupLogin,
    handleRegionChange,
    getPrivacyPolicy,
    getConfiguration,
    getRegion,
    getSupportedLanguage,
  };
};
