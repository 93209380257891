import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ISignUpOTPProps } from '../../types/propTypes';
import { Formik, Field } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { ReactstrapInput } from '../../components/reactstrap-formik';
import { SignUpOTPSchema } from '../../validation/login/login-validation';
import { ISignUpOTPFormField } from '../../models/login';
import { Common } from '../../utils/constants';
import { signupConfirm, resendOtp } from '../../services/login/login-service';
import { toast } from 'react-toastify';
import images from '../../assets/images';
import { companyNameReplace } from '../../utils';
import { useTranslation } from 'react-i18next';

const SignUpOTPForm = (props: ISignUpOTPProps) => {
  const { t } = useTranslation();
  const initialState: ISignUpOTPFormField = {
    code: '',
  };

  useEffect(() => {
    fetchData();
  });

  const fetchData = () => {
    if (props.isDriverVerified !== undefined && props.isDriverVerified !== null && !props.isDriverVerified) {
      resendOtpSubmit();
    }
  };
  const handleSubmit = async (value: ISignUpOTPFormField) => {
    value.username = props.email.toLowerCase();
    return signupConfirm(value)
      .then(async (res) => {
        if (
          res.isSignUpComplete === true &&
          props.isDriverVerified !== undefined &&
          props.isDriverVerified !== null &&
          !props.isDriverVerified
        ) {
          return props.onChangeStage(Common.AuthStage.FORGOT_PASSWORD);
        }
        if (res.isSignUpComplete === true && !props.isLoginVerified) {
          props.onSignupLogin();
        }
        if (res.isSignUpComplete === true && props.isLoginVerified) {
          props.onDriverReLogin();
        }
      })
      .catch((err) => {
        if (err && err.name === Common.Authentication.CodeMismatchException) {
          toast.error(t('signup.toast.invalidCode'));
        } else {
          toast.error(t('signup.toast.error'));
        }
      })
      .finally();
  };

  const resendOtpSubmit = async () => {
    await resendOtp(props.email)
      .then(() => {
        toast.success(t('signup.toast.resendSignupCode'));
      })
      .catch((error) => {
        if (error && error.name === 'LimitExceededException') {
          toast.error(t('signup.toast.resendLimitExceeded'));
        } else {
          toast.error(t('signup.toast.error'));
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>{companyNameReplace(t('signup.title'))} </title>
      </Helmet>
      <button
        type="button"
        className="btn btn-icon btn-link pr-0 btn-back"
        onClick={() => props.onChangeStage(Common.AuthStage.SIGNIN)}
        title={t<string>('signup.button.back')}
      >
        <img className="icon" src={images.backArrowRSVG} alt={t<string>('signup.button.back')} />
      </button>
      <div className="title-outer">
        <h1 className="title">
          <span className="icon">
            <img src={images.emailSVG} alt={t<string>('navbar.title')} />
          </span>
          {t('signup.label.otpConfirmationTitle1')}
        </h1>
        {props.isDriverVerified !== undefined && props.isDriverVerified !== null && !props.isDriverVerified ? (
          <p>
            {t('signup.label.otpNonVerifiedEmailNote1')}
            <span className="text-primary"> {props.email}</span> {t('signup.label.otpNonVerifiedEmailNote2')}
          </p>
        ) : (
          <p>
            {t('signup.label.otpConfirmationTitle2')}
            <span className="text-primary d-block pt-2">{props.email}</span>
          </p>
        )}
      </div>
      <Formik initialValues={initialState} onSubmit={handleSubmit} validationSchema={SignUpOTPSchema(t)}>
        {(props) => {
          const { handleSubmit } = props;
          return (
            <form className="form-secondary" onSubmit={handleSubmit} autoComplete="off" noValidate>
              <Field type="text" name="code" id="code" label={t('signup.form.code')} component={ReactstrapInput} />
              <Form.Group className="form-group">
                <Button type="submit" className="btn btn-primary w-100">
                  {t('signup.button.submitOtp')}
                </Button>
              </Form.Group>
              <div className="clearfix pt-4">
                <p className="sign-up">
                  {t('signup.label.resendTitle')}{' '}
                  <a href="#" title={t<string>('signup.label.resend')} onClick={resendOtpSubmit}>
                    {t('signup.button.resend')}
                  </a>
                </p>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default SignUpOTPForm;
