import { useState } from 'react';
import { toast } from 'react-toastify';
import { forgotPassword, getAPIKey } from '../../services/login/login-service';
import { checkDriverIsVerified } from '../../services/login/loginApi-service';
import { Common } from '../../utils/constants';
import { IConfiguration } from '../../models/profile';
import { IDriverVerified, IForgotPasswordFormField } from '../../models/login';
import { StandardResponse } from '../../models/standard-response';
import { useTranslation } from 'react-i18next';

export const ForgotPasswordFormHook = ({ onChangeStage, setEmail }) => {
  const { t } = useTranslation();
  const [supportContact, setSupportContact] = useState<string>('');

  const fetchSupportContact = async () => {
    const contactUsConfig: IConfiguration[] = await getConfigData(Common.ConfigurationKey.CONTACT_US_PHONE);
    if (contactUsConfig.length > 0) {
      const contact = contactUsConfig[0].value || t('forgot.supportNumber');
      setSupportContact(contact);
    }
  };

  const getConfigData = async (code: string) => {
    let configData = [];
    await getAPIKey({ code })
      .then(async (res) => {
        configData = res.data;
      })
      .catch((m) => {
        if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
      });
    return configData;
  };
  const checkDriver = async (email: string) => {
    let isVerified = true;
    await checkDriverIsVerified({ email })
      .then(async (res: StandardResponse<IDriverVerified>) => {
        isVerified = res.Data.is_verified;
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally();
    return isVerified;
  };
  const handleSubmit = async (value: IForgotPasswordFormField) => {
    const isVerified = await checkDriver(value.email.toLowerCase());
    setEmail(value.email.toLowerCase());
    if (isVerified) {
      return forgotPassword(value)
        .then(async () => {
          onChangeStage(Common.AuthStage.CHANGE_PASSWORD_WITH_VERIFICATION_CODE);
        })
        .catch(() => {
          toast.error(t('forgot.toast.error'));
        })
        .finally();
    } else {
      onChangeStage(Common.AuthStage.SIGNUP_OTP, false);
    }
  };

  return {
    supportContact,
    setSupportContact,
    fetchSupportContact,
    handleSubmit,
    getConfigData,
    checkDriver,
  };
};
