import React from 'react';
import { Helmet } from 'react-helmet';
import { IResetPasswordProps } from '../../types/propTypes';
import { forgotPasswordChange } from '../../services/login/login-service';
import { IForgotPasswordChangeField } from '../../models/login';
import { Formik, Field } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { ReactstrapInput, ReactstrapFormikPasswordInput } from '../../components/reactstrap-formik';
import { ResetPassworValidationSchema } from '../../validation/login/login-validation';
import { Common } from '../../utils/constants';
import { toast } from 'react-toastify';
import { emailNotification } from '../../services/login/loginApi-service';
import images from '../../assets/images';
import { companyNameReplace } from '../../utils';
import { useTranslation } from 'react-i18next';

const ResetPasswordForm = ({ email, onChangeStage }: IResetPasswordProps) => {
  const { t } = useTranslation();
  const initialState: IForgotPasswordChangeField = {
    code: '',
    password: '',
    email: '',
    confirmPassword: '',
  };

  const handleSubmit = async (value: IForgotPasswordChangeField) => {
    value.email = String(email);
    return forgotPasswordChange(value)
      .then(() => {
        toast.success(t('forgot.toast.success'));
        emailNotification(String(email));
        onChangeStage(Common.AuthStage.SIGNIN);
      })
      .catch((err) => {
        if (err && err.name === Common.Authentication.CodeMismatchException) {
          toast.error(t('forgot.toast.invalidCode'));
        } else {
          toast.error(t('forgot.toast.error'));
        }
      })
      .finally();
  };

  return (
    <>
      <Helmet>
        <title>{companyNameReplace(t('forgot.resetTitle'))}</title>
      </Helmet>
      <button
        type="button"
        className="btn btn-icon btn-link pr-0 btn-back"
        onClick={() => onChangeStage(Common.AuthStage.FORGOT_PASSWORD)}
        title={t<string>('forgot.button.back')}
      >
        <img className="icon" src={images.backArrowRSVG} alt={t<string>('forgot.button.back')} />
      </button>
      <div className="title-outer">
        <h1 className="title">
          <img src={images.logoIconPNG} alt={t<string>('login.label.logo')} />
          {t('forgot.resetHeader')}
        </h1>
        <p>{t('forgot.resetSubHeader')}</p>
      </div>
      <Formik initialValues={initialState} onSubmit={handleSubmit} validationSchema={ResetPassworValidationSchema(t)}>
        {(props) => {
          const { handleSubmit } = props;
          return (
            <form className="form-secondary" onSubmit={handleSubmit} autoComplete="off" noValidate>
              <Field name="code" id="Code" label={t('forgot.form.code')} component={ReactstrapInput} />
              <Field
                type="password"
                name="password"
                id="Password"
                label={t('forgot.form.password')}
                component={ReactstrapFormikPasswordInput}
                autoComplete="off"
              />
              <Field
                type="password"
                name="confirmPassword"
                id="ConfirmPassword"
                label={t('forgot.form.confirmPassword')}
                autoComplete="off"
                component={ReactstrapFormikPasswordInput}
              />
              <Form.Group className="form-group">
                <Button type="submit" className="btn btn-primary w-100">
                  {t('forgot.button.save')}
                </Button>
              </Form.Group>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
export default ResetPasswordForm;
