import React from 'react';
import { IOpenSourceDetails } from '../../types/stateTypes';
import { openSourceList } from './OpenSourceList';
import { useTranslation } from 'react-i18next';

const OpenSource = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="open-source">
        <div className="wrapper">
          <main>
            <h1>{t('openSource.title')}</h1>
            <div className="detail-wrapper">
              {openSourceList &&
                openSourceList.length > 0 &&
                openSourceList.map((data: IOpenSourceDetails, index: number) => {
                  return (
                    <div className="detail-block" key={index}>
                      <p>
                        {data.name} <br /> <br />
                        {data.description}
                      </p>
                      <div className="links-wrapper">
                        <a href={data.url} target="_blank" rel="noopener noreferrer" className="link">
                          {data.license}
                        </a>
                      </div>
                    </div>
                  );
                })}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default OpenSource;
