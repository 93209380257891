import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import { IForgotPasswordProps } from '../../types/propTypes';
import { IForgotPasswordFormField } from '../../models/login';
import { ReactstrapInput } from '../../components/reactstrap-formik';
import { ForgotPasswordSchema } from '../../validation/login/login-validation';
import { Common } from '../../utils/constants';
import images from '../../assets/images';
import { companyNameReplace } from '../../utils';
import { ForgotPasswordFormHook } from './ForgotPasswordFormHook';

const ForgotPasswordForm = ({ onChangeStage, setEmail, handleChangeFormValues }: IForgotPasswordProps) => {
  const { t } = useTranslation();
  const { supportContact, fetchSupportContact, handleSubmit } = ForgotPasswordFormHook({ onChangeStage, setEmail });

  const parameters = new URLSearchParams(window.location.search);
  const initialState: IForgotPasswordFormField = {
    email: parameters?.get('email') && parameters?.get('email') !== 'undefined' ? `${parameters?.get('email')}` : '',
  };

  useEffect(() => {
    fetchSupportContact();
  }, []);

  return (
    <>
      <Helmet>
        <title>{companyNameReplace(t('forgot.title'))}</title>
      </Helmet>
      <button
        type="button"
        className="btn btn-icon btn-link pr-0 btn-back"
        onClick={() => onChangeStage(Common.AuthStage.SIGNIN)}
        title={t<string>('forgot.button.back')}
        data-testid="backButton"
      >
        <img className="icon" src={images.backArrowRSVG} alt={t<string>('forgot.button.back')} />
      </button>
      <div className="title-outer">
        <h1 className="title">
          <img src={images.logoIconPNG} alt={companyNameReplace(t('login.label.logo'))} />
          {t('forgot.header')}
        </h1>
        <p>{t('forgot.subHeader')}</p>
      </div>
      <Formik initialValues={initialState} onSubmit={handleSubmit} validationSchema={ForgotPasswordSchema(t)}>
        {(props) => {
          const { handleSubmit } = props;
          return (
            <form className="form-secondary" onSubmit={handleSubmit} autoComplete="off" noValidate>
              <Field
                type="text"
                name="email"
                id="Email"
                label={t('forgot.form.email')}
                component={ReactstrapInput}
                maxLength={50}
                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  handleChangeFormValues(e);
                }}
                autoComplete="off"
              />
              <div className="alert-primary mb-4">
                <span>{companyNameReplace(t('forgot.form.message'))}</span>
                <span>{supportContact}</span>
              </div>
              <Form.Group className="form-group">
                <Button type="submit" className="btn btn-primary w-100">
                  {t('forgot.button.submit')}
                </Button>
              </Form.Group>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
export default ForgotPasswordForm;
