import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import SignUpForm from './SignUpForm';
import ResetPasswordForm from './ResetPasswordForm';
import SignUpOTPForm from './SignUpOTPForm';
import { ILoginPageProps } from '../../types/propTypes';
import { Common } from '../../utils/constants';
import { IRegionData } from '../../models/profile';
import SelectBox from '../../components/reactstrap-formik/SelectBox';
import images from '../../assets/images';
import { companyNameReplace } from '../../utils';
import 'moment/locale/es';
import 'moment/locale/is';
import { ISupportedLanguage } from '../../store/state/language.state';
import { LoginPageHook } from './LoginPageHook';

const LoginPage = (props: ILoginPageProps) => {
  const parameters = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const {
    supportedLanguages,
    apiError,
    stage,
    colorStyles,
    language,
    email,
    referralCode,
    emailPattern,
    selectedRegion,
    regions,
    privacyPolicy,
    isLoginVerified,
    isDriverVerified,
    setEmail,
    setPassword,
    onSignupLogin,
    setStage,
    setReferralCode,
    handleLanguageDropdown,
    handleRegionChange,
    handleChangeStage,
    handleChangeFormValues,
    setEmailPattern,
    handleDriverLogin,
  } = LoginPageHook(props);

  useEffect(() => {
    if (parameters?.get('stage') && parameters?.get('stage') !== 'undefined' && parameters?.get('stage') !== '3') {
      navigate('/login');
    }
    const urlParams = new URLSearchParams(window.location.search);
    const myReferralCode = urlParams.get('code');
    if (props.isSignUp) {
      setStage(Common.AuthStage.SIGNUP);
    }
    setReferralCode(String(myReferralCode));
  }, []);

  const { t } = useTranslation();
  return (
    <>
      <div className="login-page container-fluid p-0">
        <div className="row h-100 no-gutters">
          <div className="col-md login-background-block">
            <a href="#" title={t<string>('login.label.logo')} className="instavolt-logo">
              <img src={images.logoPNG} alt={t<string>('login.label.logo')} />
            </a>
          </div>
          <div className="col-md-auto login-section">
            <div className="login-outer">
              {(supportedLanguages?.length > 0 || apiError) && (
                <div className="login-contain">
                  {(stage === Common.AuthStage.SIGNIN || stage === Common.AuthStage.SIGNUP) && (
                    <div className="language-dropdown">
                      <SelectBox
                        styles={colorStyles}
                        name="language"
                        menuPlacement="top"
                        value={language && supportedLanguages.length > 0 ? language : ''}
                        options={supportedLanguages}
                        onChange={(e: ISupportedLanguage) => {
                          handleLanguageDropdown(e);
                        }}
                        getOptionLabel={(opt: ISupportedLanguage) => opt.name as string}
                        getOptionValue={(opt: ISupportedLanguage) => opt.id as number}
                        isClearable={false}
                        placeholder={t('login.placeholder.language')}
                      />
                    </div>
                  )}
                  {stage === Common.AuthStage.SIGNIN && (
                    <LoginForm
                      onChangeStage={handleChangeStage}
                      handleSubmit={handleDriverLogin}
                      handleChangeFormValues={handleChangeFormValues}
                    />
                  )}
                  {stage === Common.AuthStage.FORGOT_PASSWORD && (
                    <ForgotPasswordForm
                      onChangeStage={handleChangeStage}
                      setEmail={(data: string) => setEmail(data)}
                      handleChangeFormValues={handleChangeFormValues}
                    />
                  )}
                  {stage === Common.AuthStage.CHANGE_PASSWORD_WITH_VERIFICATION_CODE && (
                    <ResetPasswordForm email={email} onChangeStage={handleChangeStage} />
                  )}
                  {stage === Common.AuthStage.SIGNUP && (
                    <SignUpForm
                      onChangeStage={handleChangeStage}
                      setEmail={(data: string) => setEmail(data)}
                      setPassword={(data: string) => setPassword(data)}
                      setEmailPattern={(data: string) => setEmailPattern(data)}
                      referaalCode={referralCode}
                      handleChangeFormValues={handleChangeFormValues}
                    />
                  )}
                  {stage === Common.AuthStage.SIGNUP_OTP && (
                    <SignUpOTPForm
                      onChangeStage={handleChangeStage}
                      onDriverReLogin={onSignupLogin}
                      email={email}
                      emailPattern={emailPattern}
                      isLoginVerified={isLoginVerified}
                      onSignupLogin={onSignupLogin}
                      isDriverVerified={isDriverVerified}
                    />
                  )}
                  {(stage === Common.AuthStage.SIGNIN || stage === Common.AuthStage.SIGNUP) && (
                    <div
                      className="form-group"
                      style={{
                        width: '104%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img width="25px" src={images.regionSVG} />
                      <SelectBox
                        styles={colorStyles}
                        name="region"
                        menuPlacement="top"
                        value={selectedRegion || ''}
                        options={regions}
                        onChange={(e: IRegionData) => {
                          handleRegionChange(e);
                        }}
                        getOptionLabel={(opt: IRegionData) => opt.name as string}
                        getOptionValue={(opt: IRegionData) => opt.id as number}
                        isClearable={false}
                        placeholder={t('login.placeholder.region')}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {privacyPolicy && privacyPolicy.privacy_policy_url ? (
              <p className="copyright">
                {`${companyNameReplace(t('login.label.copyright'))} | `}
                <a
                  href={privacyPolicy.privacy_policy_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="privacy-policy-link"
                >
                  {privacyPolicy.title}
                </a>
              </p>
            ) : (
              <p className="copyright"> {companyNameReplace(t('login.label.copyright'))}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
