import React, { useState } from 'react';
import { getConfigurationDetail } from '../../services/user/user-service';
import { store } from '../../store';
import { toast } from 'react-toastify';
import { useAccordionButton } from 'react-bootstrap';
import { customToggleProps } from '../../types/propTypes';
import { ISupportedLanguage } from '../../store/state/language.state';
import { IFAQConfig } from '../../models/faq';
export const FleetFAQPageHook = () => {
  const [activeKey, setActiveKey] = useState('0');
  const [faqArray, setFaqArray] = useState([]);

  const fetchData = async () => {
    const queryStringLang = new URLSearchParams(window.location.search);
    const languageInfo = store?.getState()?.LanguageInfo?.supportedLanguages;
    const changelanguage = languageInfo
      ? languageInfo?.filter(
          (item: ISupportedLanguage) =>
            item?.code === queryStringLang.get('langCode') || item?.code === queryStringLang.get('language'),
        )[0]
      : {
          code: queryStringLang.get('langCode') || queryStringLang.get('language'),
        };
    const regionCode = process.env.REACT_APP_PORTAL_REGION?.toLocaleLowerCase();
    let languageCode;
    if (queryStringLang.get('langCode') || queryStringLang.get('language')) {
      languageCode = changelanguage?.code;
    } else {
      if (store.getState()?.UserData) {
        languageCode = store.getState()?.UserData?.language?.code;
      } else if (store.getState()?.LanguageInfo) {
        languageCode = store.getState()?.LanguageInfo?.defaultLanguage?.code;
      }
    }
    let jsonFile = require(`../../assets/fleetFaq/uk/en.json`);
    if (regionCode && languageCode) {
      jsonFile = require(`../../assets/fleetFaq/${regionCode}/${languageCode}.json`);
    }
    const brandConfig: IFAQConfig[] = await getConfigDataBrandName('COMPANY_BRAND_NAME');
    let brandname = '';
    if (brandConfig.length > 0) {
      brandname = brandConfig[0].value ? brandConfig[0].value : '';
    }
    let faqs = JSON.stringify(jsonFile?.faq);
    const changeValue = store?.getState()?.configuration?.company_name || 'Instavolt';
    faqs = faqs.split('##COMPANY_BRAND_NAME##').join(brandname || changeValue);
    const faqArray = JSON.parse(faqs);

    setFaqArray(faqArray);
  };

  const handleActiveKey = (key: number) => {
    const newActiveKey = activeKey == key.toString() ? '' : key.toString();
    setActiveKey(newActiveKey);
  };

  const getConfigDataBrandName = async (code: string) => {
    let configData = [];
    await getConfigurationDetail({ code })
      .then(async (res) => {
        configData = res.data;
      })
      .catch((m) => {
        toast.error(m.toString());
      });
    return configData;
  };

  const CustomToggle = ({ children, eventKey }: customToggleProps) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => handleActiveKey(parseInt(eventKey)));

    return (
      <span
        className={activeKey === eventKey.toString() ? 'faq-card-title' : 'faq-card-title collapsed'}
        onClick={decoratedOnClick}
      >
        {children}
      </span>
    );
  };

  return {
    fetchData,
    faqArray,
    CustomToggle,
    activeKey,
    setActiveKey,
    getConfigDataBrandName,
    handleActiveKey,
  };
};
