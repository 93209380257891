import React, { useEffect, useState } from 'react';
import { Dropdown, Navbar } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { IAppHeaderProps } from '../types/propTypes';
import { AppState } from '../store/state/app.state';
import images from '../assets/images';
import { getAPIKey } from '../services/login/login-service';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Header = ({ logout }: IAppHeaderProps) => {
  const userData = useSelector((state: AppState) => state.UserData);

  const [showNavBar, setShowNavBar] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>('');
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const minSwipeDistance = 50;

  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const config = await getAPIKey({ code: 'COMPANY_NAME' });
    setCompanyName(config.data[0]?.value);
  };

  const getUserName = (): string => {
    if (userData && userData.firstName && userData.lastName) {
      if (userData.firstName.toString().trim() === '' && userData.lastName.toString().trim() === '') {
        return `${userData.email}`;
      }
      return `${userData.firstName} ${userData.lastName}`;
    }
    return '';
  };
  const toggleNavBar = () => {
    setShowNavBar((prevShowNavBar) => !prevShowNavBar);
  };

  const getNavLinkClass = (path: string) => {
    return window.location.pathname === path ? 'nav-item active' : 'nav-item';
  };

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!(touchStart && touchEnd)) {
      return;
    }
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    if (isLeftSwipe) {
      setShowNavBar(false);
    }
  };

  return (
    <header className="header">
      <div className="container-xl">
        <div className="row no-gutters align-items-center">
          <div className="col">
            <Navbar expand="lg" onToggle={toggleNavBar} expanded={showNavBar}>
              <Navbar.Brand as={Link} to="/" title={companyName}>
                <img src={images.logoPNG} alt={t<string>('navbar.title')} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav">
                <span className="one"></span>
                <span className="two"></span>
                <span className="three"></span>
              </Navbar.Toggle>
              <Navbar.Collapse
                id="basic-navbar-nav"
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                <ul className="navbar-nav m-auto" id="navbar-nav">
                  {showNavBar && (
                    <Navbar.Brand
                      as={Link}
                      to="/"
                      title={companyName}
                      className="showLogo m-2 p-1"
                      onClick={toggleNavBar}
                    >
                      <img
                        src={images.darkLogo}
                        alt={t<string>('navbar.title')}
                        style={{ height: '25px', width: '225px' }}
                      />
                    </Navbar.Brand>
                  )}
                  <li className={getNavLinkClass('/')} id="/" onClick={toggleNavBar}>
                    <NavLink to="/" className="nav-link d-flex" title={t<string>('navbar.menu.chargingPaymentHistory')}>
                      <img
                        src={getNavLinkClass('/') === 'nav-item' ? images.historyBlackSVG : images.historyWhiteSVG}
                        className="navbarIcon showLogo"
                        alt={t<string>('navbar.menu.chargingPaymentHistory')}
                      />
                      {t('navbar.menu.chargingPaymentHistory')}
                    </NavLink>
                  </li>
                  <li className={getNavLinkClass('/payment')} id="/payment" onClick={toggleNavBar}>
                    <NavLink
                      to="/payment"
                      className="nav-link d-flex"
                      title={t<string>('navbar.menu.paymentConfiguration')}
                    >
                      <img
                        src={
                          getNavLinkClass('/payment') === 'nav-item' ? images.paymentBlackSVG : images.paymentWhiteSVG
                        }
                        className="navbarIcon showLogo"
                        alt={t<string>('navbar.menu.paymentConfiguration')}
                      />
                      {t('navbar.menu.paymentConfiguration')}
                    </NavLink>
                  </li>
                  <li className={getNavLinkClass('/rewards')} id="/rewards" onClick={toggleNavBar}>
                    <NavLink to="/rewards" className="nav-link d-flex" title={t<string>('navbar.menu.rewards')}>
                      <img
                        src={getNavLinkClass('/rewards') === 'nav-item' ? images.rewardsBlackSVG : images.rewardsWhite}
                        className="navbarIcon showLogo"
                        alt={t<string>('navbar.menu.rewards')}
                      />
                      {t('navbar.menu.rewards')}
                    </NavLink>
                  </li>
                  <li className={getNavLinkClass('/rfid-cards')} id="/rfid-cards" onClick={toggleNavBar}>
                    <NavLink to="/rfid-cards" className="nav-link d-flex" title={t<string>('navbar.menu.rfidCards')}>
                      <img
                        src={
                          getNavLinkClass('/rfid-cards') === 'nav-item'
                            ? images.rfidCardsBlackSVG
                            : images.rfidCardsWhiteSVG
                        }
                        className="navbarIcon showLogo"
                        alt={t<string>('navbar.menu.rfidCards')}
                      />
                      {t('navbar.menu.rfidCards')}
                    </NavLink>
                  </li>
                </ul>
              </Navbar.Collapse>
              <div className={showNavBar ? 'menu-overlay' : 'menu-overlay collapsed'} onClick={toggleNavBar}></div>
            </Navbar>
          </div>
          <div className="col-auto">
            <div className="header-right-bar">
              <Dropdown className="user-block">
                <Dropdown.Toggle as="a" id="dropdownMenuLink" href="#" role="button">
                  <img className="user-icon" src={images.avatarSVG} alt="" />
                  <span className="name text-truncate capitalize avatarIcon" title={getUserName()}>
                    {getUserName()}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/Profile">
                    <img src={images.profileSVG} alt={t<string>('navbar.myProfile')} />
                    {t('navbar.myProfile')}
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                    <img src={images.icTermsSVG} alt={t<string>('navbar.termsConditions')} className="menuItemIcon" />
                    {t('navbar.termsConditions')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logout}>
                    <img src={images.logoutSVG} alt={t<string>('navbar.logOut')} />
                    {t('navbar.logOut')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
