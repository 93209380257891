import axios, { InternalAxiosRequestConfig } from 'axios';
import { fetchAuthSession, AuthSession } from 'aws-amplify/auth';
import { store } from '../store';

const BaseService = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}`,
});
const isRefreshing = false;
const jwtToken = '';
let showLoader = true;
// Request interceptors Customize based on your need
BaseService.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    // Add X-Access-Token header to every request, you can add other custom headers here
    const byPassAuth = config.data && config.data.byPassAuth === true;
    if (config.data?.loader !== undefined) {
      showLoader = config.data.loader;
      delete config.data.loader;
    }
    if (!byPassAuth) {
      try {
        const currentUser: AuthSession = await fetchAuthSession();
        if (currentUser) {
          const accessToken: string = currentUser.tokens.accessToken.toString();
          config.headers.Authorization = !isRefreshing ? accessToken : jwtToken;
          config.headers.language = store.getState()?.UserData?.language
            ? store.getState()?.UserData.language?.code
            : 'en';
        }
      } catch (_error) {
        window.location.reload();
      }
    }
    if (config && config.data && config.data.byPassAuth) {
      delete config.data.byPassAuth;
    }
    if (showLoader) {
      document.getElementById('loading').classList.remove('spinner-disable');
    }
    return config;
  },
  (error) => {
    if (showLoader) {
      document.getElementById('loading').classList.remove('spinner-disable');
    }
    Promise.reject(error);
  },
);

// Response interceptors Customize based on your need
BaseService.interceptors.response.use(
  (response) => {
    if (showLoader) {
      document.getElementById('loading').classList.add('spinner-disable');
    }
    showLoader = true;
    return response;
  },
  async (error) => {
    switch (error.response && error.response.status) {
      case 401:
        // Authorization Failed Response can add other status codes here to manage error Logging
        break;
      case 403:
        break;
      default:
        break;
    }
    if (showLoader) {
      document.getElementById('loading').classList.add('spinner-disable');
    }
    return Promise.reject(error);
  },
);

export default BaseService;
