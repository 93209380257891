import React, { SyntheticEvent, useEffect, useState } from 'react';
import _ from 'lodash';
import QRCode from 'react-qr-code';
import ReactDOM from 'react-dom/client';
import { IPreviewPromotionProps } from '../../types/propTypes';
import DOMPurify from 'isomorphic-dompurify';

const IMAGE_TAG = '##image##';
const QRCODE_TAG = '##qrcode##';
const ADDITIONAL_IMAGES = {
  image01: '##image01##',
  image02: '##image02##',
  image03: '##image03##',
  image04: '##image04##',
  image05: '##image05##',
};

const PreviewPromotion = ({ layout, image, qrCode, init, additionalImages }: IPreviewPromotionProps) => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    generateHTML();
  }, []);

  useEffect(() => {
    if (init) {
      generateHTML();
    }
  }, [init]);

  const errorImage = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    (e.target as HTMLImageElement).onerror = null;
    (e.target as HTMLImageElement).src = '/images/no-image-found.jpg';
  };

  const getImage = () => {
    if (_.isObject(image)) {
      return URL.createObjectURL(image as Blob);
    }
    return `${process.env.REACT_APP_DRIVER_PORTAL_URL}/${image}`;
  };

  const getAdditionalImage = (imgNumber: number) => {
    const additionalImage = additionalImages.find((image) => image.number === imgNumber);
    const image = additionalImage ? additionalImage.image_url : '';
    if (_.isObject(image)) {
      return URL.createObjectURL(image as Blob);
    }
    return `${process.env.REACT_APP_DRIVER_PORTAL_URL}/${image}`;
  };

  const generateHTML = () => {
    if (layout) {
      layout = layout.replace(IMAGE_TAG, `<div id="imageElement"></div>`);
      for (let i = 1; i <= 5; i++) {
        const key = `image0${i}`;
        if (layout.includes(ADDITIONAL_IMAGES[key])) {
          const imgID = `imageElement${i}`;
          layout = layout.replace(ADDITIONAL_IMAGES[key], `<div id="${imgID}"></div>`);
        }
      }
      if (qrCode) {
        layout = layout.replace(QRCODE_TAG, `<div id="qrcodeElement"></div>`);
      }
      setHtml(layout);

      setTimeout(() => {
        const element = document.getElementById('qrcodeElement');
        const imageElement = document.getElementById('imageElement');
        if (imageElement) {
          ReactDOM.createRoot(imageElement).render(
            <img className="h100 w100" src={getImage()} onError={errorImage} alt="" />,
          );
        }
        if (element && qrCode) {
          ReactDOM.createRoot(element).render(<QRCode value={qrCode} />);
        }

        for (let i = 1; i <= 5; i++) {
          const elementID = `imageElement${i}`;
          const element = document.getElementById(elementID);
          if (element) {
            ReactDOM.createRoot(element).render(
              <img className="h100 w100" src={getAdditionalImage(i)} onError={errorImage} alt="" />,
            );
          }
        }
      }, 500);
    }
  };

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></div>
    </>
  );
};
export default PreviewPromotion;
