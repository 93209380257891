const awsmobile = {
  aws_project_region: process.env.REACT_APP_REGION,
  aws_cognito_region: process.env.REACT_APP_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
    redirectSignOut: process.env.REACT_APP_REDIRECT_URL,
    responseType: 'code',
  },
};
export default awsmobile;
