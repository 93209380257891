import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import Spinner from './layout/spinner';
import './lang';
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/dev-style.css';
import(`./assets/css/regionVariables/${process.env.REACT_APP_PORTAL_REGION}.css` as string);
import(`./assets/css/region/${process.env.REACT_APP_PORTAL_REGION}-style.css` as string);
import { ImagesVariable } from './Images';

const routerConfig = {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
  },
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <CookiesProvider>
      <Router {...routerConfig}>
        <App />
        <ImagesVariable />
        <Spinner />
      </Router>
    </CookiesProvider>
  </Provider>,
);

serviceWorker.unregister();
