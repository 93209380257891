import { Form } from 'react-bootstrap';

const FormikInput = ({ field: { ...fields }, form: { touched, errors }, ...props }) => {
  const { id, formGroupClass, ...rest } = props;
  return (
    <Form.Group className={`${formGroupClass} form-group`} controlId={id}>
      {props.label && <Form.Label className={'label-color'}>{props.label}</Form.Label>}
      <Form.Control {...rest} {...fields} isInvalid={Boolean(touched[fields.name] && errors[fields.name])} />
      {touched[fields.name] && errors[fields.name] && errors[fields.name] !== 'required' && (
        <Form.Control.Feedback className="pb-0 error invalid-feedback d-block" type="invalid">
          {errors[fields.name]}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};
export default FormikInput;
