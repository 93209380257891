import _ from 'lodash';
import { store } from 'store';

export const regionDateAndTime = () => {
  if (!_.isEmpty(store?.getState()?.configuration?.date_time_format)) {
    return store.getState().configuration.date_time_format;
  } else {
    return {
      GENERAL_DATE_FORMAT: 'DD/MM/YYYY',
      GENERAL_DATE_TIME_FORMAT: 'DD/MM/YYYY HH:mm',
      DATE_TIME_FORMAT_WITH_SECONDS: 'DD/MM/YYYY HH:mm:ss',
      GENERAL_EXPORT_DATE_FORMAT: 'DD/MM/YYYY',
      GENERAL_EXPORT_DATE_TIME_FORMAT: 'DD/MM/YYYY HH:mm:ss',
    };
  }
};

export const companyNameReplace = (fullString: string) => {
  const changeValue = store?.getState()?.configuration?.company_name || 'Instavolt';
  const originalString = fullString;
  const replacedString = originalString.replace(/##COMPANY_BRAND_NAME##/g, changeValue);
  return replacedString;
};
