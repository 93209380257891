import { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { IBillingInfoState } from '../../types/stateTypes';
import { getDownloadReceiptData } from '../../services/history/history-service';
import { IVatReceipt } from '../../models/history';
import { StandardResponse } from '../../models/standard-response';
import { IConfiguration } from '../../models/profile';
import { regionDateAndTime } from '../../utils/index';
import { getFormattedCurrencyWithSymbol, localFormat } from '../../components/common/LocalNumberFormat';
import { store } from '../../store';
import { getAPIKey } from '../../services/login/login-service';
import _ from 'lodash';

export const useDownloadVATReceipt = () => {
  const [isAPICalled, setIsAPICalled] = useState<boolean>(false);
  const [isUnauthorisedUser, setIsUnauthorisedUser] = useState<boolean>(false);

  const [billingInfo, setBillingInfo] = useState<IBillingInfoState>({
    receipt: {},
    address: '',
    companyStatement: '',
  });

  const params = useParams();

  useEffect(() => {
    fetchReceiptData();
  }, []);

  const fetchReceiptData = async () => {
    const encryptedSessionId = params?.session_id;
    const encryptedSessionPrimaryId = params?.session_primary_id;

    if (encryptedSessionId !== null && encryptedSessionId !== undefined) {
      await getDownloadReceiptData({
        sessionId: encryptedSessionId,
        sessionPrimaryId: encryptedSessionPrimaryId ? encryptedSessionPrimaryId : null,
      })
        .then(async (res: StandardResponse<IVatReceipt>) => {
          const vatReceiptData: IVatReceipt = res.Data;
          _.isArray(vatReceiptData.charge_details) &&
            vatReceiptData.charge_details.length > 0 &&
            vatReceiptData.charge_details.map((item) => {
              item.usage = localFormat(item?.usage?.toString(), 'string') as string;
              item.value = getFormattedCurrencyWithSymbol(
                item?.value?.toString(),
                'string',
                undefined,
                item?.currency_details?.symbol,
              );
              item.vat_amount = getFormattedCurrencyWithSymbol(
                item?.vat_amount?.toString(),
                'string',
                undefined,
                item?.currency_details?.symbol,
              );
              item.vat_rate = localFormat(item?.vat_rate?.toString(), 'string') as string;
              item.start = moment(item.start)?.format(regionDateAndTime().GENERAL_DATE_TIME_FORMAT);
              item.end = moment(item.end)?.format(regionDateAndTime().GENERAL_DATE_TIME_FORMAT);
            });
          _.isArray(vatReceiptData.vat_details) &&
            vatReceiptData.vat_details.length > 0 &&
            vatReceiptData.vat_details.map((item) => {
              item.total_amount = getFormattedCurrencyWithSymbol(
                item?.total_amount?.toString(),
                'string',
                undefined,
                store.getState()?.configuration?.default_currency?.symbol,
              );
              item.total_vat_amount = getFormattedCurrencyWithSymbol(
                item?.total_vat_amount?.toString(),
                'string',
                undefined,
                store.getState()?.configuration?.default_currency?.symbol,
              );
            });
          vatReceiptData.date = moment(new Date()).format(regionDateAndTime().GENERAL_DATE_FORMAT);
          vatReceiptData.net_amount = getFormattedCurrencyWithSymbol(
            vatReceiptData?.net_amount?.toString(),
            'string',
            undefined,
            store.getState()?.configuration?.default_currency?.symbol,
          );
          vatReceiptData.total_fee = getFormattedCurrencyWithSymbol(
            vatReceiptData?.total_fee?.toString(),
            'string',
            undefined,
            store.getState()?.configuration?.default_currency?.symbol,
          );
          vatReceiptData.total_vat = getFormattedCurrencyWithSymbol(
            vatReceiptData?.total_vat?.toString(),
            'string',
            undefined,
            store.getState()?.configuration?.default_currency?.symbol,
          );
          setBillingInfo((prevState) => {
            return {
              ...prevState,
              receipt: vatReceiptData,
            };
          });
          setIsAPICalled(true);
          setTimeout(() => {
            document.getElementById('instaVoltReceiptDownload')?.click();
          }, 2000);
        })
        .catch((m) => {
          if (m && m.response && m.response.status && m.response.status === 401) {
            setIsUnauthorisedUser(true);
          }
          setIsAPICalled(true);
        });
    } else {
      setIsAPICalled(true);
    }

    const config = await getAPIKey({});
    setBillingInfo((prevState) => {
      return {
        ...prevState,
        address: config.data.filter((res: IConfiguration) => res.reference_code === 'CONTACT_US_ADDRESS')[0].value,
        companyStatement: config.data.filter((res: IConfiguration) => res.reference_code === 'COMPANY_STATEMENT')[0]
          .value,
      };
    });
  };

  return {
    fetchReceiptData,
    billingInfo,
    setBillingInfo,
    isAPICalled,
    isUnauthorisedUser,
  };
};
