import { IAppAction, ActionType } from './Helpers';
import { IErrorLog } from '../state/errorlog.state';
import i18next from 'i18next';
import * as loginService from './../../services/login/login-service';
import {
  IsupportedDateAndTimeFormat,
  ICurrencyDetails,
  ICurrencyFormat,
  INumberFormat,
} from './../../models/login/index';
import { ILanguagePreference, IUserData as IUserProfileData } from 'models/profile';
import { toast } from 'react-toastify';
import { ISupportedLanguage } from 'store/state/language.state';
import { store } from '../index';

export const login = (data): IAppAction => {
  return { type: ActionType.LOGIN_REQUEST, payload: data };
};

export const userDetail = (data: IUserProfileData) => {
  return { type: ActionType.FETCH_USER_DETAILS, payload: data };
};

export const addError = (data: IErrorLog): IAppAction => {
  return { type: ActionType.ERROR_LOG, payload: data };
};

export const removeError = (): IAppAction => {
  return { type: ActionType.CLEAR_ERROR_LOG };
};

export const changeLanguage = (language: ISupportedLanguage | ILanguagePreference): IAppAction => {
  i18next.changeLanguage(language?.code);
  return { type: ActionType.CHANGE_LANGUAGE, payload: language };
};

export const logout = () => async (dispatch) => {
  const queryStringLang = new URLSearchParams(window.location.search);
  const languageInfo = store?.getState()?.LanguageInfo?.supportedLanguages;
  const changelanguage = languageInfo
    ? languageInfo?.filter(
        (item) => item?.code === queryStringLang.get('langCode') || item?.code === queryStringLang.get('language'),
      )[0]
    : {
        code: queryStringLang.get('langCode') || queryStringLang.get('language'),
      };
  changeLanguage(changelanguage);

  await loginService
    .logout()
    .then(async () => {
      dispatch(removeError());
      await dispatch({ type: ActionType.LOGOUT_REQUEST });
    })
    .catch((m) => {
      toast.error(m.toString());
    });
};

export const saveUserData = (data) => {
  return { type: ActionType.USER_DATA, payload: data };
};

export const setSupportedLanguages = (data: Array<ISupportedLanguage>) => {
  return { type: ActionType.SUPPORTED_LANGUAGES, payload: data };
};

export const setDefaultLanguage = (data: ISupportedLanguage) => {
  return { type: ActionType.DEFAULT_LANGUAGE, payload: data };
};

export const setDateTimeFormat = (dateAndTime: IsupportedDateAndTimeFormat): IAppAction => {
  return { type: ActionType.DATE_TIME_FORMAT, payload: dateAndTime };
};

export const setCurrencyFormat = (currencyFormat: ICurrencyFormat): IAppAction => {
  return { type: ActionType.CURRENCY_FORMAT, payload: currencyFormat };
};

export const setNumberFormat = (number_format: INumberFormat) => {
  return { type: ActionType.NUMBER_FORMAT, payload: number_format };
};

export const setCurrencyDetails = (currency: ICurrencyDetails) => {
  return { type: ActionType.CURRENCY_DETAILS, payload: currency };
};

export const setCompanyName = (name: string) => {
  return { type: ActionType.COMPANY_NAME, payload: name };
};
