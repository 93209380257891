import addSVG from './add.svg';
import addressSVG from './address.svg';
import amexSVG from './amex.svg'; //no use
import appliedSVG from './applied.svg';
import avatarSVG from './avatar.svg';
import backArrowRSVG from './back-arrow-r.svg';
import backArrowSVG from './back-arrow.svg';
import balanceSVG from './balance.svg';
import barcodeJPG from './barcode.jpg'; //no use
import cancelRequestSVG from './cancel-request.svg';
import cartebleueSVG from './cartebleue.svg'; //no use
import checkSVG from './check.svg'; //style file
import closeSVG from './close.svg';
import creditAccountSVG from './credit-account.svg';
import creditCardBgPNG from './credit-card-bg.png'; //style
import currentBalanceBgPNG from './current-balance-bg.png'; //style
import customSelectArrowSVG from './custom-select-arrow.svg'; //style
import dankortSVG from './dankort.svg'; //no use
import dashboardBtnSVG from './dashboard-btn.svg';
import deleteSVG from './delete.svg';
import dinersClubCarteBlancheSVG from './diners_club_carte_blanche.svg'; //no use
import dinersClubInternationalSVG from './diners_club_international.svg'; //no use
import disvcoverSVG from './discover.svg'; //no use
import downSVG from './down.svg'; //style
import downloadSVG from './download.svg'; //no use
import dropdownMenuSVG from './dropdown-menu.svg';
import darkLogo from './instavoltLogoUk.png';
import editSVG from './edit.svg';
import emailSVG from './email.svg';
import errorIconSVG from './error-icon.svg'; //style //dev style // inline
import errorSVG from './error.svg';
import facebookLogoSVG from './facebook-logo.svg';
import facebookSVG from './facebook.svg'; //no use
import flashSVG from './flash.svg';
import googleLogoPNG from './google-logo.png';
import googlePlusLogoSVG from './google-plus-logo.svg'; //no use
import googleSVG from './google.svg'; //no use
import historyWhiteSVG from './historyWhite.svg';
import historyBlackSVG from './historyBlack.svg';
import icTermsSVG from './ic-terms.svg';
import infoIconSVG from './info-icon.svg'; //style //dev style
import innerPageBgPNG from './inner-page-bg.png'; //style
import iosSVG from './ios.svg';
import jcbSVG from './jcb.svg'; //no use
import laserSVG from './laser.svg'; //no use
import lockSVG from './lock.svg'; //style
import loginBackgroundJPG from './login-background.jpg'; //style //dev style
import logoIconPNG from './logo-icon.png';
import logoInstavoltPNG from './logo-instavolt.png';
import logoPNG from './logo.png';
import logoutSVG from './logout.svg';
import maestroSVG from './maestro.svg'; //no use
import maskPNG from './mask.png'; //no use
import mastercardSVG from './mastercard.svg'; ///no use
import mdLoginBackgroundJPG from './md-login-background.jpg'; //style
import nextSVG from './next.svg';
import paymentConfigSVG from './Payment-config.svg';
import prePaymentAccountSVG from './prepayment-account.svg';
import processGIF from './process.gif';
import profileImgSVG from './profile-img.svg';
import profileSVG from './profile.svg';
import paymentWhiteSVG from './paymentWhite.svg';
import paymentBlackSVG from './paymentBlack.svg';
import reactLogoPNG from './reactlogo.png'; //no use
import refundSVG from './refund.svg';
import regionSVG from './region.svg';
import replaceSVG from './replace.svg';
import rewardsBlackSVG from './rewardsBlack.svg';
import rewardsWhite from './rewardsWhite.png';
import rfidCardsBlackSVG from './rfidCardBlack.svg';
import rfidCardsWhiteSVG from './rfidCardWhite.svg';
import securityCodeSVG from './security-code.svg'; //no use
import sendSVG from './send.svg'; //no use
import sortingSVG from './sorting.svg'; //style
import srotingAscSVG from './sorting_asc.svg'; //style
import sortingDescSVG from './sorting_desc.svg'; //style
import SuccessIconSVG from './success-icon.svg'; //style //dev style
import SuccessSVG from './success.svg';
import uatpSVG from './uatp.svg'; //no use
import unionpaySVG from './unionpay.svg'; //no use
import unlockSVG from './unlock.svg'; //style
import visaLogoSVG from './visa-logo.svg'; //no use
import visaSVG from './visa.svg'; //no use
import visaElectronSVG from './visa_electron.svg'; //no use
import warningIconSVG from './warning-icon.svg'; //style //dev style
import withdrawSVG from './withdraw.svg';
import rightArrowSVG from './right_arrow.svg';
import verifiedSVG from './Verified.svg';
import rightArrowDarkSVG from './right_arrow-dark.svg';
import currencyConversionInfoPNG from './currencyConversionInfo.png';

//IS region
import IS_avatarSVG from './regions/IS/avatar.svg';
import IS_backArrowRSVG from './regions/IS/back-arrow-r.svg';
import IS_backArrowSVG from './regions/IS/back-arrow.svg';
import IS_balanceSVG from './regions/IS/balance.svg';
import IS_creditAccountSVG from './regions/IS/credit-account.svg';
import IS_currencyConversionInfoPNG from './regions/IS/currencyConversionInfo.png';
import IS_dankortSVG from './regions/IS/dankort.svg'; //no use
import IS_dashboardBtnSVG from './regions/IS/dashboard-btn.svg';
import IS_darkLogo from './regions/IS/instavoltLogoIs.png';
import IS_emailSVG from './regions/IS/email.svg';
import IS_flashSVG from './regions/IS/flash.svg';
import IS_innerPageBgPNG from './regions/IS/inner-page-bg.png';
import IS_logoPNG from './regions/IS/logo.png';
import IS_logoIconPNG from './regions/IS/logo-icon.png';
import IS_logoInstavoltPNG from './regions/IS/logo-instavolt.png';
import IS_paymentConfigSVG from './regions/IS/Payment-config.svg';
import IS_prePaymentAccountSVG from './regions/IS/prepayment-account.svg';
import IS_profileImgSVG from './regions/IS/profile-img.svg';

//ES region
import ES_darkLogo from './regions/ES/instavoltLogoES.png';

//IE region
import IE_darkLogo from './regions/IE/instavoltLogoIE.png';

//PT region
import PT_darkLogo from './regions/PT/instavoltLogoPT.png';

let images = {
  addSVG,
  addressSVG,
  amexSVG,
  appliedSVG,
  avatarSVG,
  backArrowRSVG,
  backArrowSVG,
  balanceSVG,
  barcodeJPG,
  cancelRequestSVG,
  cartebleueSVG,
  checkSVG,
  closeSVG,
  creditAccountSVG,
  creditCardBgPNG,
  currentBalanceBgPNG,
  customSelectArrowSVG,
  dankortSVG,
  dashboardBtnSVG,
  deleteSVG,
  dinersClubCarteBlancheSVG,
  dinersClubInternationalSVG,
  disvcoverSVG,
  downSVG,
  downloadSVG,
  dropdownMenuSVG,
  darkLogo,
  editSVG,
  emailSVG,
  errorIconSVG,
  errorSVG,
  facebookLogoSVG,
  facebookSVG,
  flashSVG,
  googleLogoPNG,
  googlePlusLogoSVG,
  googleSVG,
  historyWhiteSVG,
  historyBlackSVG,
  icTermsSVG,
  infoIconSVG,
  innerPageBgPNG,
  iosSVG,
  jcbSVG,
  laserSVG,
  lockSVG,
  loginBackgroundJPG,
  logoIconPNG,
  logoInstavoltPNG,
  logoPNG,
  logoutSVG,
  maestroSVG,
  maskPNG,
  mastercardSVG,
  mdLoginBackgroundJPG,
  nextSVG,
  paymentConfigSVG,
  prePaymentAccountSVG,
  processGIF,
  profileImgSVG,
  profileSVG,
  paymentWhiteSVG,
  paymentBlackSVG,
  reactLogoPNG,
  regionSVG,
  refundSVG,
  replaceSVG,
  rewardsBlackSVG,
  rewardsWhite,
  rfidCardsBlackSVG,
  rfidCardsWhiteSVG,
  securityCodeSVG,
  sendSVG,
  sortingSVG,
  srotingAscSVG,
  sortingDescSVG,
  SuccessIconSVG,
  SuccessSVG,
  uatpSVG,
  unionpaySVG,
  unlockSVG,
  visaLogoSVG,
  visaSVG,
  visaElectronSVG,
  warningIconSVG,
  withdrawSVG,
  rightArrowSVG,
  verifiedSVG,
  rightArrowDarkSVG,
  currencyConversionInfoPNG,
};

const defaultImages = {
  ...images,
};

const imagesFromES = {
  ...images,
  darkLogo: ES_darkLogo,
};

const imagesFromIE = {
  ...images,
  darkLogo: IE_darkLogo,
};

const imagesFromPT = {
  ...images,
  darkLogo: PT_darkLogo,
};

// const imagesFromIS = {
//   ...images,
//   avatarSVG: IS_avatarSVG,
//   backArrowSVG: IS_backArrowSVG,
//   backArrowRSVG: IS_backArrowRSVG,
//   balanceSVG: IS_balanceSVG,
//   creditAccountSVG: IS_creditAccountSVG,
//   dankortSVG: IS_dankortSVG,
//   dashboardBtnSVG: IS_dashboardBtnSVG,
//   darkLogo: IS_darkLogo,
//   emailSVG: IS_emailSVG,
//   flashSVG: IS_flashSVG,
//   paymentConfigSVG: IS_paymentConfigSVG,
//   prePaymentAccountSVG: IS_prePaymentAccountSVG,
//   profileImgSVG: IS_profileImgSVG,
//   logoPNG: IS_logoPNG,
//   logoIconPNG: IS_logoIconPNG,
//   innerPageBgPNG: IS_innerPageBgPNG,
//   logoInstavoltPNG: IS_logoInstavoltPNG,
//   currencyConversionInfoPNG: IS_currencyConversionInfoPNG,
// };

if (process.env.REACT_APP_PORTAL_REGION === 'IS') {
  images = { ...defaultImages, darkLogo: IS_darkLogo };
} else if (process.env.REACT_APP_PORTAL_REGION === 'UK') {
  images = defaultImages;
} else if (process.env.REACT_APP_PORTAL_REGION === 'ES') {
  images = imagesFromES;
} else if (process.env.REACT_APP_PORTAL_REGION === 'IE') {
  images = imagesFromIE;
} else if (process.env.REACT_APP_PORTAL_REGION === 'PT') {
  images = imagesFromPT;
}

export default images;
