import BaseService from 'services/base-service';
import { createStandardResponse, StandardResponse } from 'models/standard-response';
import { IDownloadReceiptInput, IFilterInput, IHistoryList, IReceiptInput, IVatReceipt } from 'models/history';

export const getHistoryData = async (data: IFilterInput): Promise<StandardResponse<IHistoryList>> => {
  try {
    const res = await BaseService({
      url: `/driver/charge-payment-history/search`,
      method: 'POST',
      data,
    });
    return createStandardResponse<IHistoryList>(res.status, res.data.data, res.data.message, null);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getReceiptData = async (data: IReceiptInput): Promise<StandardResponse<IVatReceipt>> => {
  try {
    const res = await BaseService({
      url: `/driver/charge-payment-history/export`,
      method: 'POST',
      data,
    });
    return createStandardResponse<IVatReceipt>(res.status, res.data.data, res.data.message, null);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getDownloadReceiptData = async (data: IDownloadReceiptInput): Promise<StandardResponse<IVatReceipt>> => {
  let apiUrl = '';
  if (data.sessionPrimaryId) {
    apiUrl = `/driver/charge-payment-history/export/${data.sessionId}/${data.sessionPrimaryId}`;
  } else {
    apiUrl = `/driver/charge-payment-history/export/${data.sessionId}`;
  }
  try {
    const res = await BaseService({
      url: apiUrl,
      method: 'get',
      data,
    });
    return createStandardResponse<IVatReceipt>(res.status, res.data.data, res.data.message, null);
  } catch (err) {
    return Promise.reject(err);
  }
};
